import { Theme, StylesConfig } from "react-select";
import { SelectOption } from "@util/interfaces";

export const reactSelectTheme = (theme: Theme): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    text: "#212529",
    primary25: "#f2e3ff",
    primary: "#9841e2",
    neutral50: "#b4b4b4",
  },
});

type IsMulti = boolean;

export const reactSelectStyles: StylesConfig<SelectOption, IsMulti> = {
  control: (base, state) => ({
    ...base,
    minHeight: 43,
    height: "auto",
    padding: 0,
    border: `1px solid ${state.isFocused ? "#9841e2" : "#ced4da"}`,
    "&:hover": {
      border: `1px solid ${state.isFocused ? "#9841e2" : "#ced4da"}`,
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0 5px",
    height: "auto",
  }),
  input: (base) => ({
    ...base,
    height: 43,
    padding: "0px",
    margin: "0px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
