import * as React from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";
import { Hotel } from "@util/interfaces";
import { toastError } from "@util/functions";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Api from "@services/Api";

const columns: TableColumn<Hotel>[] = [
  {
    name: "Nome",
    selector: (row: Hotel) => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row: Hotel) => `${row.email ?? "-"}`,
    sortable: true,
  },
  {
    name: "Telefone (1)",
    selector: (row: Hotel) => row.phone,
  },
  {
    name: "Telefone (2)",
    selector: (row: Hotel) => row.phone_2 ?? "-",
  },
];

const HotelsListScreen: React.FC = () => {
  const [hotels, setHotels] = React.useState<Hotel[]>([]);
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  React.useEffect(() => {
    fetchHotels();
  }, []);

  const fetchHotels = async () => {
    try {
      const { data } = await Api.get(`/hotel`);
      setHotels(data.hotels);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  if (loading)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Hotéis</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Hotéis</PageTitle>
        <Link to="/hoteis/cadastro" className="btn btn-primary">
          cadastrar hotel
        </Link>
      </TitleContainer>
      <ContentContainer>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loading}
          columns={columns}
          data={hotels}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          highlightOnHover
          pointerOnHover
          onRowClicked={(row: Hotel) => {
            history.push(`/hoteis/${row.id}/editar`);
          }}
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default HotelsListScreen;
