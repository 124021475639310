import FakeLink from "@components/ui/FakeLink";
import Api from "@services/Api";
import { getBookletSaleStatus, normalizeString, toastError } from "@util/functions";
import { Plan, SalePeriod, SaleType, User } from "@util/interfaces";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@redux/index";
import { setFilteredSales } from "@redux/AppData.store";
import { FilterSelect } from "@components/ui/FilterSelect";
import FilterInput from "@components/ui/FilterInput";
import { bookletSaleMonthsSelectOptions, yearsSelectOptions } from "@util/variables";

const saleTypeOptions = [
  { value: SaleType.FIRST_TIME, label: "Primeira vez" },
  { value: SaleType.RENEWAL, label: "Renovação" },
  { value: SaleType.UPGRADE, label: "Atualização" },
];

const salePeriodOptions = [
  { value: SalePeriod.MONTHLY, label: "Mensal" },
  { value: SalePeriod.SEMESTERLY, label: "Semestral" },
  { value: SalePeriod.YEARLY, label: "Anual" },
];

const statusOptions = [
  { value: "active", label: "Em dias" },
  { value: "pastDue", label: "Inadimplente" },
  { value: "expired", label: "Desativado" },
];

const BookletSalesFilters: React.FC = () => {
  // Filters state
  const [selectedSeller, setSelectedSeller] = React.useState(0);
  const [selectedSaleType, setSelectedSaleType] = React.useState("");
  const [selectedPlan, setSelectedPlan] = React.useState(0);
  const [selectedPeriod, setSelectedPeriod] = React.useState("");
  const [saleStoreQuery, setSaleStoreQuery] = React.useState("");
  const [salePatrimonyCodeQuery, setSalePatrimonyCodeQuery] = React.useState("");
  const [selectedMonth, setSelectedMonth] = React.useState("13");
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear().toString());
  const [selectedStatus, setSelectedStatus] = React.useState("");

  // Other state
  const [sellers, setSellers] = React.useState<User[]>();
  const [plans, setPlans] = React.useState<Plan[]>();
  const [loadingPlans, setLoadingPlans] = React.useState(true);
  const [loadingSellers, setLoadingSellers] = React.useState(true);
  const { sales, loadingSales } = useSelector((state: RootState) => state.appSettings);
  const userRole = localStorage.getItem("user_role");
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (userRole === "admin") {
      fetchSellers();
    } else {
      setLoadingSellers(false);
    }
    fetchPlans();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    let filteredItems = sales;

    if (selectedStatus) {
      filteredItems = filteredItems.filter((sale) => {
        if (sale.expires_at) {
          const expiresAt = new Date(sale.expires_at);
          const today = new Date();
          const diffTime = Math.max(today.getTime() - expiresAt.getTime(), 0);
          const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
          const status = getBookletSaleStatus(expiresAt, today, diffDays);
          return status === selectedStatus;
        }
        return false;
      });
    }

    if (saleStoreQuery.length > 0) {
      filteredItems = filteredItems.filter((sale) =>
        normalizeString(sale.store?.name ?? "").includes(normalizeString(saleStoreQuery))
      );
    }
    if (salePatrimonyCodeQuery.length > 0) {
      filteredItems = filteredItems.filter((sale) =>
        normalizeString(sale.store?.patrimony_code ?? "").includes(normalizeString(salePatrimonyCodeQuery))
      );
    }
    if (selectedPeriod) {
      filteredItems = filteredItems.filter((sale) => sale.period === selectedPeriod);
    }
    if (selectedPlan) {
      filteredItems = filteredItems.filter((sale) => sale.plan.id === selectedPlan);
    }

    if (selectedSaleType) {
      filteredItems = filteredItems.filter((sale) => sale.type === selectedSaleType);
    }
    if (selectedSeller) {
      filteredItems = filteredItems.filter((sales) => sales.seller.id === selectedSeller);
    }
    if (selectedMonth) {
      if (selectedMonth !== "13") {
        filteredItems = filteredItems.filter(
          (sale) => (new Date(sale.created_at).getMonth() + 1).toString() === selectedMonth
        );
      }
    }
    if (selectedYear) {
      filteredItems = filteredItems.filter(
        (sale) => new Date(sale.created_at).getFullYear().toString() === selectedYear
      );
    }
    dispatch(setFilteredSales(filteredItems));
    // eslint-disable-next-line
  }, [
    selectedSeller,
    selectedSaleType,
    selectedPlan,
    selectedPeriod,
    saleStoreQuery,
    salePatrimonyCodeQuery,
    selectedYear,
    selectedMonth,
    selectedStatus,
  ]);

  const fetchPlans = async () => {
    try {
      const { data } = await Api.get(`/plan`);
      setPlans(data.plans);
      setLoadingPlans(false);
    } catch (error) {
      setLoadingPlans(false);
      toastError(error);
    }
  };

  const fetchSellers = async () => {
    try {
      const { data } = await Api.get(`/user/sellers`);
      setSellers(data.sellers);
      setLoadingSellers(false);
    } catch (error) {
      setLoadingSellers(false);
      toastError(error);
    }
  };

  const handleClearFilter = () => {
    setSaleStoreQuery("");
    setSalePatrimonyCodeQuery("");
    setSelectedSeller(0);
    setSelectedSaleType("");
    setSelectedPlan(0);
    setSelectedPeriod("");
    setSelectedMonth("13");
    setSelectedYear(new Date().getFullYear().toString());
    setSelectedStatus("");
  };

  return (
    <div className="row py-1 border-bottom pb-4 g-2">
      <div className={"col-auto"}>
        <FilterInput
          label={"Marca"}
          disabled={loadingSales}
          placeholder={"Todas"}
          value={saleStoreQuery}
          onChange={(e) => {
            setSaleStoreQuery(e.currentTarget.value);
          }}
        />
      </div>

      <div className={"col-auto"}>
        <FilterInput
          label={"Código do patrimônio"}
          disabled={loadingSales}
          placeholder={"Todos"}
          value={salePatrimonyCodeQuery}
          onChange={(e) => {
            setSalePatrimonyCodeQuery(e.currentTarget.value);
            console.log(salePatrimonyCodeQuery, "salePatrimonyCodeQuery");
          }}
        />
      </div>

      {userRole === "admin" && (
        <div className={"col-auto"}>
          <FilterSelect
            label={"Vendedor"}
            isDisabled={loadingPlans || loadingSellers || loadingSales}
            value={
              sellers
                ?.map((seller) => ({ value: `${seller.id}`, label: seller.name }))
                .find((seller) => seller.value === `${selectedSeller}`) ?? null
            }
            options={sellers?.map((seller) => ({
              value: `${seller.id}`,
              label: seller.name,
            }))}
            onChange={(option) => {
              setSelectedSeller(Number(option?.value));
            }}
          />
        </div>
      )}

      <div className={"col-auto"}>
        <FilterSelect
          label={"Situação"}
          isDisabled={loadingPlans || loadingSellers || loadingSales}
          value={statusOptions.find((option) => option.value === selectedStatus) ?? null}
          options={statusOptions}
          onChange={(option) => {
            setSelectedStatus(option?.value ?? "");
          }}
        />
      </div>

      <div className={"col-auto"}>
        <FilterSelect
          label={"Tipo de venda"}
          isDisabled={loadingPlans || loadingSellers || loadingSales}
          options={saleTypeOptions}
          value={saleTypeOptions.find((option) => option.value === selectedSaleType) ?? null}
          onChange={(option) => {
            setSelectedSaleType(option?.value ?? "");
          }}
        />
      </div>

      <div className={"col-auto"}>
        <FilterSelect
          label={"Planos"}
          isDisabled={loadingPlans || loadingSellers || loadingSales}
          value={
            plans
              ?.map((plan) => ({ value: `${plan.id}`, label: plan.name }))
              .find((plan) => plan.value === `${selectedPlan}`) ?? null
          }
          selectedMonth
          options={plans?.map((plan) => ({ value: `${plan.id}`, label: plan.name }))}
          onChange={(option) => {
            setSelectedPlan(Number(option?.value));
          }}
        />
      </div>

      <div className={"col-auto"}>
        <FilterSelect
          label={"Período"}
          isDisabled={loadingPlans || loadingSellers || loadingSales}
          value={salePeriodOptions.find((option) => option.value === selectedPeriod) ?? null}
          options={salePeriodOptions}
          onChange={(option) => {
            setSelectedPeriod(option?.value ?? "");
          }}
        />
      </div>

      <div className={"col-auto"}>
        <FilterSelect
          label={"Mês"}
          isClearable={false}
          isDisabled={loadingPlans || loadingSellers || loadingSales}
          value={bookletSaleMonthsSelectOptions.find((option) => option.value === selectedMonth) ?? null}
          options={bookletSaleMonthsSelectOptions}
          onChange={(option) => {
            setSelectedMonth(option?.value ?? "");
          }}
        />
      </div>
      <div className={"col-auto"}>
        <FilterSelect
          label={"Ano"}
          isClearable={false}
          isDisabled={loadingPlans || loadingSellers || loadingSales}
          value={yearsSelectOptions.find((option) => option.value === selectedYear) ?? null}
          options={yearsSelectOptions}
          onChange={(option) => {
            setSelectedYear(option?.value ?? "");
          }}
        />
      </div>
      <div className="col-auto py-auto d-flex align-items-center">
        <FakeLink onClick={handleClearFilter}>limpar</FakeLink>
      </div>
    </div>
  );
};

export default BookletSalesFilters;
