import React from "react";
import styled from "styled-components";

const LiveStatusText = styled.div<{ color: React.CSSProperties["color"] }>`
  color: ${(props) => props.color};
  font-size: 18px;
  font-weight: bold;
`;

const modules = {
  LiveStatusText,
};

export default modules;
