import * as React from "react";
import { InputHTMLAttributes } from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #f3f3f3;
  border-radius: 5px;
  max-height: 35px;
  display: flex;
  align-items: center;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  height: 35px;
  max-height: 35px;
  outline: none;
  background-color: transparent;
  min-height: 0px;
  padding: 0 5px;
  width: auto;
  min-width: 10px;
  width: 120px;
  font-weight: bold;
  border: none;
`;

interface FilterInputprops extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const FilterInput: React.FC<FilterInputprops> = (props) => {
  return (
    <Container>
      <span>{`${props.label ? `${props.label}:` : ""}`}</span>
      <Input {...props} />
    </Container>
  );
};

export default FilterInput;
