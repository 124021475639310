import React from "react";
import Modal, { ModalProps } from "react-responsive-modal";
import Api from "@services/Api";
import { Excursion } from "@util/interfaces";
import { toastError, toastMessage } from "@util/functions";
import ExcursionsMap from "@components/ui/ExcursionsMap";

interface ExcursionLocationModalProps extends ModalProps {
  excursion: Excursion | undefined;
}

export const ExcursionLocationModal: React.FC<ExcursionLocationModalProps> = (props) => {
  const { excursion } = props;
  const handleMapClick = (latLng: { lat: number; lng: number }) => {
    submitLocation(latLng.lat, latLng.lng);
  };

  const submitLocation = async (lat: number, lng: number) => {
    if (!props.excursion) return;
    try {
      await Api.patch(`/excursion/${props.excursion.id}`, { location_lat: lat, location_lng: lng });
      toastMessage("Localização atualizada com sucesso!", "success");
      props.onClose();
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <Modal {...props}>
      <div style={{ height: "80vh", width: "90vw" }}>
        <ExcursionsMap
          handleMapClick={handleMapClick}
          defaultLocation={
            excursion?.location_lat && excursion.location_lng
              ? { lat: excursion.location_lat, lng: excursion.location_lng }
              : undefined
          }
        />
      </div>
    </Modal>
  );
};
