import { Store } from "@util/interfaces";
import * as React from "react";
import { Modal } from "react-responsive-modal";
import GoogleMapReact from "google-map-react";
import Map from "@components/ui/Map";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";

interface LocationFormProps {
  store: Store;
}

const LocationForm: React.FC<LocationFormProps> = ({ store }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [lat, setLat] = React.useState<string | null>(`${store.lat ?? ""}`);
  const [lng, setLng] = React.useState<string | null>(`${store.lng ?? ""}`);

  const handleOpenMap = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      await Api.post(`/store/${store.id}/lat_lng`, { lat, lng });
      toastMessage(`Localização atualizada.!`, "success");
    } catch (error) {
      toastError(error);
    }
  };

  const handleMapClick = (event: GoogleMapReact.ClickEventValue) => {
    setLat(`${event.lat}`);
    setLng(`${event.lng}`);
  };

  return (
    <div>
      <form className={"form row"}>
        <div className={"col-4 form-group"}>
          <input
            className={"form-control"}
            value={`${lat}`}
            onChange={(e) => {
              setLat(e.target.value);
            }}
            placeholder="Latitude"
          />
        </div>
        <div className={"col-4 form-group"}>
          <input
            className={"form-control"}
            value={`${lng}`}
            onChange={(e) => {
              setLng(e.target.value);
            }}
            placeholder="Longitude"
          />
        </div>
        <div className={"col-auto"}>
          <button type="button" onClick={handleOpenMap} className={"btn btn-outline-primary"}>
            Escolher no mapa
          </button>
        </div>
        <div className={"col-auto"}>
          <button type="button" onClick={handleSave} className={"btn btn-primary"}>
            Salvar
          </button>
        </div>
      </form>

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        center
      >
        <div
          style={{
            maxWidth: "90vw",
            maxHeight: "80vh",
            height: "600px",
            width: 500,
            borderStyle: "solid",
            borderWidth: 3,
            borderColor: "#9841e2",
            borderRadius: 8,
            overflow: "hidden",
          }}
        >
          <Map handleMapClick={handleMapClick} />
        </div>
      </Modal>
    </div>
  );
};

export default LocationForm;
