import moment from "moment";
import { toast, TypeOptions } from "react-toastify";
import { PaymentMethod, PaymentStatus, SalePayment, SalePeriod, SaleType } from "./interfaces";
import Money from "./money";

// As many components can trigger error, and we are not sure if all of them throws 'Error'
// we use 'any' and then filter
export function toastError(error: any, type?: TypeOptions) {
  let message: string = "Erro desconhecido";
  if (error) {
    if (error.response?.data?.userMessage) message = error.response.data.userMessage;
    else if (error.response?.data?.message) message = error.response.data.message;
    else if (error.userMessage) message = error.userMessage;
    else if (error.message) message = error.message;
    else if (typeof error === "string") message = error;
  }
  toast(message, { type: type ?? "error" });
}

export function toastMessage(message: string, type?: TypeOptions) {
  toast(message, { type });
}

export function getPlanFromNumber(number: number) {
  switch (number) {
    case 1:
      return "Free";
    case 2:
      return "Standard";
    case 3:
      return "Gold";
    case 4:
      return "Platinum";
    default:
      return "Free";
  }
}
export function getPaymentMethodName(
  paymentMethod: PaymentMethod,
  cardFlag: string | undefined,
  installmentCount?: number | undefined
) {
  switch (paymentMethod) {
    case PaymentMethod.CREDIT_CARD:
      return `${cardFlag}-${installmentCount}x`;
    case PaymentMethod.PIX:
      return "Pix";
    case PaymentMethod.BOLETO:
      return "Boleto";
    case PaymentMethod.CASH:
      return "Dinheiro";
    case PaymentMethod.BOOKLET:
      return "Carnê";
  }
}

export function getSalePeriodName(period: SalePeriod) {
  switch (period) {
    case SalePeriod.MONTHLY:
      return "Mensal";
    case SalePeriod.SEMESTERLY:
      return "Semestral";
    case SalePeriod.YEARLY:
      return "Anual";
  }
}

export function getSaleTypeName(type: SaleType) {
  switch (type) {
    case SaleType.FIRST_TIME:
      return "Primeira vez";
    case SaleType.RENEWAL:
      return "Renovação";
    case SaleType.UPGRADE:
      return "Atualização";
  }
}

export function getInt(float: number | string) {
  if (typeof float === "string") float = +float;
  let number = Math.round((float ?? 0) * Math.pow(10, 2));
  if (typeof number !== "number") return 0;
  return parseInt(`${number}`);
}

export function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export function normalizeString(text: string) {
  if (!text) return "";
  text = text.toLowerCase();
  text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
  text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
  text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
  text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
  text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
  text = text.replace(new RegExp("[Ç]", "gi"), "c");
  text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return text;
}

export const getBookletSaleStatus = (expiresAt: Date, today: Date, diffDays: number) => {
  if (expiresAt >= today) return "active";
  else if (diffDays <= 60) {
    return "pastDue";
  } else {
    return "expired";
  }
};

export const getBookletSaleStatusColor = (status: string) => {
  switch (status) {
    case "active":
      return "#79f752";
    case "pastDue":
      return "#e3a540";
    case "expired":
      return "#e34040";
    default:
      return "black";
  }
};

export const getBookletSaleStatusLabel = (status: string) => {
  switch (status) {
    case "active":
      return "Em dias";
    case "pastDue":
      return "Inadimplente";
    case "expired":
      return "Desativada";
    default:
      return "N/D";
  }
};

export const getSaleStatusColor = (status: string) => {
  switch (status) {
    case "active":
      return "#79f752";
    case "pending":
      return "#e3a540";
    case "expired":
      return "#e34040";
    default:
      return "black";
  }
};

export const getSaleStatusLabel = (status: string) => {
  switch (status) {
    case "active":
      return "Ativa";
    case "pending":
      return "Pendente";
    case "expired":
      return "Expirada";
    default:
      return "N/D";
  }
};

export const getPaymentStatusColor = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.CREATED:
      return "#cccccc";
    case PaymentStatus.ANALYSIS:
      return "#3ba3ff";
    case PaymentStatus.CANCELED:
      return "#e34040";
    case PaymentStatus.PAID:
      return "#79f752";
    case PaymentStatus.REJECTED:
      return "#e34040";
    case PaymentStatus.EXPIRED:
      return "#e34040";
    default:
      return "black";
  }
};

export const getPaymentStatusLabel = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.CREATED:
      return "Aguardando pagamento";
    case PaymentStatus.ANALYSIS:
      return "Em análise";
    case PaymentStatus.CANCELED:
      return "Cancelado";
    case PaymentStatus.PAID:
      return "Pago";
    case PaymentStatus.REJECTED:
      return "Rejeitado";
    case PaymentStatus.EXPIRED:
      return "Expirado";
    default:
      return "Não definido";
  }
};

export function getDaysInMonthUTC(month: number, year: number) {
  var date = new Date(Date.UTC(year, month, 1));
  var days = [];
  while (date.getUTCMonth() === month) {
    days.push(new Date(date));
    date.setUTCDate(date.getUTCDate() + 1);
  }
  return days;
}

export function getDaysInMonth(month: number, year: number) {
  let date = moment(`${year}-${month}-1`).startOf("month").startOf("day");
  let days = [];
  while (date.month() + 1 === month) {
    days.push(date.toDate());
    date.add(1, "day");
  }
  return days;
}

export function convertPaymentsToCSV(array: SalePayment[]) {
  try {
    let result: any;
    let payments = array.map((payment) => ({
      data: moment(payment.paid_at).format("DD/MM/YYYY"),
      vendedor: payment.sale.seller?.name ?? "Usuário inexistente",
      tipo: getSaleTypeName(payment.sale.type) ?? "",
      marca: payment.sale.store?.name ?? "Loja inexistente",
      valor: Money({ amount: getInt(payment.value) }).toUnit(),
    }));

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(payments[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    payments.forEach((item: any) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  } catch (error) {
    toastError("Erro ao gerar arquivo CSV");
  }
}
