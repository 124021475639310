import GoogleMapReact from "google-map-react";
import * as React from "react";
import MapOverlay from "./Overlay";
import planta from "@assets/img/planta_mdc.png";
import MapMarker from "../MapMaker";

const MAPS_API_KEY = "AIzaSyD_SjJ9Y4WWq1RkNTpP-PbX8zp_Bed8-Bo";

const zoomLevels = [
  { zoom: 19, mapSize: 2785 },
  { zoom: 18, mapSize: 1393 },
];

interface MapProps {
  handleMapClick: (event: GoogleMapReact.ClickEventValue) => void;
  defaultLocation?: { lat: number; lng: number };
}

const Map: React.FC<MapProps> = ({ handleMapClick, defaultLocation }) => {
  const [zoom, setZoom] = React.useState(19);
  const [currentLocation, setCurrentLocation] = React.useState<{
    lat: number;
    lng: number;
  }>();
  const defaultProps = {
    center: {
      lat: -7.945660167207577,
      lng: -36.22653487733574,
    },
    zoom: 19,
  };

  const handleMapChange = (event: GoogleMapReact.ChangeEventValue) => {
    if (event.zoom !== zoom) {
      setZoom(event.zoom);
    }
  };

  return (
    <GoogleMapReact
      onClick={(event) => {
        setCurrentLocation({ lat: event.lat, lng: event.lng });
        handleMapClick(event);
      }}
      options={{
        draggableCursor: "default",
        mapTypeId: "satellite",
        minZoom: 18,
        maxZoom: 19,
        keyboardShortcuts: false,
      }}
      bootstrapURLKeys={{ key: MAPS_API_KEY }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      onChange={handleMapChange}
      zoom={zoom}
    >
      <MapOverlay lat={-7.94235} lng={-36.230075}>
        <img
          src={planta}
          height={zoomLevels.find((z) => z.zoom === zoom)?.mapSize}
          style={{ opacity: 1 }}
          alt="planta mdc"
        />
      </MapOverlay>
      {currentLocation && <MapMarker lat={currentLocation.lat} lng={currentLocation.lng} />}
      {defaultLocation && <MapMarker lat={defaultLocation.lat} lng={defaultLocation.lng} />}
    </GoogleMapReact>
  );
};

export default Map;
