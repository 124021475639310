import * as React from "react";
import { LoadingIndicator, ProductImageRootContainer } from "./components";
import { Department, Image, Segment, StablishmentType, Store, StoreFormValues } from "@util/interfaces";
import Api from "@services/Api";
import { renameFile, toastError, toastMessage } from "@util/functions";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { InputLabel } from "@components/ui/Typography";
import Divider from "@components/ui/Divider";
import { useHistory, useParams } from "react-router";
import { useDropzone } from "react-dropzone";
import { confirmAlert } from "react-confirm-alert";
import DropImageInput from "@components/ui/DropImageInput";
import { cpf } from "cpf-cnpj-validator";
import { cnpj } from "cpf-cnpj-validator";
import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";
import { useAppDispatch } from "@redux/index";
import { fetchStores } from "@redux/AppData.store";
import Select from "@components/ui/Select";
import PatrimoniesForm from "./PatrimoniesForm";
import LocationForm from "./LocationForm";
import ImagesList from "@components/ui/ImagesList";

type EditStoreScreenRouteProps = {
  store_id?: string;
};

const EditStoreScreen: React.FC = () => {
  const { store_id } = useParams<EditStoreScreenRouteProps>();
  const [store, setStore] = React.useState<Store>();
  const [departments, setDepartments] = React.useState([]);
  const [segments, setSegments] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [uploadingProductImages, setUploadingProductImages] = React.useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    fetchStore();
    fecthData();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (store && segments.length > 0 && departments.length > 0) {
      setLoading(false);
    }
  }, [store, segments, departments]);

  const fetchStore = async () => {
    try {
      const { data } = await Api.get(`/store/id/${store_id}`);
      setStore(data.store);
    } catch (error) {
      setLoading(false);
      alert("Erro ao obter dados do estabelecimento");
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (uploadingProductImages) return;
    try {
      setUploadingProductImages(true);
      const formData = new FormData();
      for (const file of acceptedFiles) {
        formData.append("files", file);
      }
      await Api.post(`/store/${store_id}/images_update`, formData);
      fetchStore();
      setUploadingProductImages(false);
      toastMessage("Imagens atualizadas", "success");
    } catch (error) {
      setUploadingProductImages(false);
      toastError(error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSubmit = async (values: StoreFormValues, actions: FormikHelpers<StoreFormValues>) => {
    try {
      actions.setSubmitting(true);
      if (values.cpf && !cpf.isValid(values.cpf)) return toastMessage("CPF inválido", "error");
      if (values.cnpj && !cnpj.isValid(values.cnpj)) return toastMessage("CNPJ inválido", "error");
      const body = {
        ...values,
        segments: values.segments.map((segment: any) => segment.id ?? segment.value ?? segment),
        departments: values.departments.map((department: any) => department.id ?? department.value ?? department),
        phone_1: values.phone_1_number,
        phone_2: values.phone_2_number,
        phone_1_is_whatsapp:
          values.phone_1_is_whatsapp === true || values.phone_1_is_whatsapp === "true" ? true : false,
        phone_2_is_whatsapp:
          values.phone_2_is_whatsapp === true || values.phone_2_is_whatsapp === "true" ? true : false,
        is_active: values.is_active === true || values.is_active === "true" ? true : false,
        is_marketplace_active: values.is_marketplace_active === true || values.is_marketplace_active === "true" ? true : false,
        accepts_card: values.accepts_card === true || values.accepts_card === "true" ? true : false,
        has_delivery: values.has_delivery === true || values.has_delivery === "true" ? true : false,
        patrimonies: values.patrimonies.map((patrimonies) => ({ ...patrimonies })),
      };
      await Api.put(`/store/${store_id}`, body);
      actions.setSubmitting(true);
      dispatch(fetchStores());
      toastMessage("Loja atualizada", "success");
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const onLogoChange = async (files: File[]) => {
    try {
      const formData = new FormData();
      let file = files[0];
      const url = URL.createObjectURL(file);
      file = renameFile(file, "logo");
      formData.append("files", file);
      await Api.post(`/store/${store_id}/images_update`, formData);
      if (store) {
        let tempStore: Store = {
          ...store,
          logo_image: {
            id: 0,
            url,
            key: `dummy-key`,
          },
        };
        setStore(tempStore);
      }
      toastMessage("Imagem atualizada");
    } catch (error) {
      toastError(error);
    }
  };

  const fecthData = async () => {
    try {
      const departmentsResponse = await Api.get(`/department`);
      const segmentsResponse = await Api.get(`/segment`);
      setDepartments(
        departmentsResponse.data.departments?.map?.((department: Department) => ({
          value: department.id,
          label: department.name,
        }))
      );
      setSegments(
        segmentsResponse.data.segments?.map?.((segment: Segment) => ({
          value: segment.id,
          label: `${segment.department?.name} - ${segment.name}`,
        }))
      );
    } catch (error) {
      toastError(error);
    }
  };

  const initialValues: StoreFormValues = {
    name: store?.name,
    legal_name: store?.legal_name,
    patrimony_code: store?.patrimony_code,
    email: store?.email,
    cnpj: store?.cnpj,
    cpf: store?.cpf,
    instagram: store?.instagram,
    site: store?.site,
    accepts_card: store?.accepts_card,
    has_delivery: store?.has_delivery,
    is_active: store?.is_active,
    phone_1_is_whatsapp: store?.phone_1?.is_whatsapp,
    phone_1_number: store?.phone_1?.number,
    phone_2_is_whatsapp: store?.phone_2?.is_whatsapp,
    phone_2_number: store?.phone_2?.number,
    patrimonies: store?.patrimonies ?? [],
    departments: store?.departments ?? [],
    segments: store?.segments?.map((segment) => segment) ?? [],
    stablishment_type: store?.stablishment_type ?? StablishmentType.STORE,
    stablishment_custom_address: store?.stablishment_custom_address ?? "",
    catalog_code: store?.catalog_code ?? "",
    marketplace_url: store?.marketplace_url ?? "",
    is_marketplace_active: store?.is_marketplace_active,
  };

  const handleStoreDelete = async () => {
    confirmAlert({
      title: "Atenção",
      message: "Deseja excluir este estabelecimento?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/store/${store_id}`);
              toastMessage("Estabelecimento excluído", "success");
              history.push("/estabelecimentos");
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  if (loading || !store)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Editar estabelecimento</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Editar estabelecimento</PageTitle>
        <div>
          <a className="btn btn-outline-primary me-2" href={`/estabelecimentos/${store_id}/horarios`}>
            horário de funcionamento
          </a>
          <button className="btn btn-outline-danger" onClick={handleStoreDelete}>
            excluir estabelecimento
          </button>
        </div>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="row m-0">
                <div className="col-12 mb-3">
                  <PageTitle style={{ fontSize: 24 }}>Dados do estabelecimento</PageTitle>
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Nome fantasia</InputLabel>
                  <Field name="name" className="form-control" placeholder="Digite o nome do estabelecimento" required />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Tipo</InputLabel>
                  <Field name="stablishment_type" as="select" className="form-control">
                    <option value={"store"}>Loja</option>
                    <option value={"hotel"}>Hotel</option>
                    <option value={"kiosk"}>Quiosque</option>
                    <option value={"restaurant"}>Restaurante</option>
                  </Field>
                </div>

                <div className="mb-4 col-md-2">
                  <InputLabel>Plano</InputLabel>
                  <select name="plan" className="form-control" disabled defaultValue={store?.plan?.id}>
                    <option value={store?.plan?.id}>{store?.plan?.name}</option>
                  </select>
                </div>

                <div className="mb-4 col-md-8">
                  <InputLabel>Razão social</InputLabel>
                  <Field
                    name="legal_name"
                    className="form-control"
                    placeholder="Digite a razão social do estabelecimento"
                  />
                </div>

                <div className="mb-4 col-md-4">
                  <InputLabel>Código do patrimônio</InputLabel>
                  <Field
                    name="patrimony_code"
                    className="form-control"
                    placeholder="Digite o código patrimonial do estabelecimento"
                  />
                </div>

                <div className="mb-4 col-md-6">
                  <InputLabel>CPF</InputLabel>
                  <Field name="cpf" className="form-control" placeholder="Digite o CPF do responsável" />
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>CNPJ</InputLabel>
                  <Field name="cnpj" className="form-control" placeholder="Digite o CNPJ do estabelecimento" />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Email</InputLabel>
                  <Field
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Digite o email do estabelecimento"
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Instagram</InputLabel>
                  <Field
                    name="instagram"
                    className="form-control"
                    placeholder="Digite o instagram do estabelecimento"
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Site</InputLabel>
                  <Field name="site" className="form-control" placeholder="Digite o site do estabelecimento" />
                </div>
                <div className="mb-4 col-md-6 row no-gutters p-0 m-0">
                  <div className="col-8">
                    <InputLabel>Telefone (1)</InputLabel>
                    <Field
                      name="phone_1_number"
                      className="form-control"
                      placeholder="Digite o telefone (1) do estabelecimento"
                    />
                  </div>
                  <div className="col-4">
                    <InputLabel>Whatsapp</InputLabel>
                    <Field name="phone_1_is_whatsapp" as="select" className="form-control">
                      <option value={"true"} selected={values.phone_1_is_whatsapp ? true : false}>
                        Sim
                      </option>
                      <option value={"false"} selected={values.phone_1_is_whatsapp ? false : true}>
                        Não
                      </option>
                    </Field>
                  </div>
                </div>
                <div className="mb-4 col-md-6 row no-gutters p-0 m-0">
                  <div className="col-8">
                    <InputLabel>Telefone (2)</InputLabel>
                    <Field
                      name="phone_2_number"
                      className="form-control"
                      placeholder="Digite o telefone (2) do estabelecimento"
                    />
                  </div>
                  <div className="col-4">
                    <InputLabel>Whatsapp</InputLabel>
                    <Field name="phone_2_is_whatsapp" as="select" className="form-control">
                      <option value={"true"} selected={values.phone_2_is_whatsapp ? true : false}>
                        Sim
                      </option>
                      <option value={"false"} selected={values.phone_2_is_whatsapp ? false : true}>
                        Não
                      </option>
                    </Field>
                  </div>
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Ativo</InputLabel>
                  <Field name="is_active" as="select" className="form-control">
                    <option value={"true"}>Sim</option>
                    <option value={"false"}>Não</option>{" "}
                  </Field>
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Aceita cartão</InputLabel>
                  <Field name="accepts_card" as="select" className="form-control">
                    <option value={"true"}>Sim</option>
                    <option value={"false"}>Não</option>{" "}
                  </Field>
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Faz delivery</InputLabel>
                  <Field name="has_delivery" as="select" className="form-control">
                    <option value={"true"}>Sim</option>
                    <option value={"false"}>Não</option>{" "}
                  </Field>
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Departamentos</InputLabel>
                  <Select
                    label="Departamentos"
                    placeholder={"Selecione os departamentos"}
                    defaultValue={departments.filter(
                      (department: any) => values.departments.findIndex((s) => s.id === department.value) >= 0
                    )}
                    options={departments}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={(selectedValues) => {
                      setFieldValue("departments", selectedValues);
                    }}
                  />
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Segmentos</InputLabel>
                  <Select
                    label="Segmentos"
                    placeholder={"Selecione os segmentos"}
                    defaultValue={segments.filter(
                      (segment: any) => values.segments.findIndex((s) => s.id === segment.value) >= 0
                    )}
                    options={segments}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={(selectedValues) => {
                      setFieldValue("segments", selectedValues);
                    }}
                  />
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Endereço (Hotel, quiosque e restaurante)</InputLabel>
                  <Field
                    name="stablishment_custom_address"
                    className="form-control"
                    placeholder="Digite o endereço do estabelecimento"
                  />
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Código da loja no catálogo digital</InputLabel>
                  <Field
                    name="catalog_code"
                    className="form-control"
                    placeholder="Digite o código da loja no catálogo digital"
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Markeplace está ativo</InputLabel>
                  <Field name="is_marketplace_active" as="select" className="form-control">
                    <option value={"true"} selected={values.is_marketplace_active ? true : false}>Sim</option>
                    <option value={"false"} selected={values.is_marketplace_active ? false : true}>Não</option>{" "}
                  </Field>
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Link da loja no marketplace</InputLabel>
                  <Field
                    name="marketplace_url"
                    className="form-control"
                    placeholder="Digite o link da loja no marketplace"
                  />
                </div>

                <div className="mb-4 col-12">
                  <Divider />
                </div>
                <div className="col-12 mb-3">
                  <PageTitle style={{ fontSize: 24 }}>Patrimônios</PageTitle>
                </div>
                <PatrimoniesForm
                  store={store}
                  onSuccess={() => {
                    fetchStore();
                  }}
                />
                <div className="my-3">
                  <Divider />
                </div>
                {store.stablishment_type !== StablishmentType.STORE && (
                  <>
                    <div className="col-12 mb-3">
                      <PageTitle style={{ fontSize: 24 }}>Localização</PageTitle>
                    </div>
                    <LocationForm store={store} />
                    <div className="my-3">
                      <Divider />
                    </div>
                  </>
                )}
                <div className="col-12 mb-3">
                  <PageTitle style={{ fontSize: 24 }}>Imagens</PageTitle>
                </div>
                <div className="col-2">
                  <InputLabel>Logomarca</InputLabel>
                  <DropImageInput imageUrl={store?.logo_image?.url} onFileSelect={onLogoChange} />
                </div>
                <div className="col-12 my-3">
                  <InputLabel>Imagens</InputLabel>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <ProductImageRootContainer
                    {...getRootProps()}
                    style={{
                      backgroundColor: "rgba(200,200,200,0.2)",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: 10,
                    }}
                  >
                    <input {...getInputProps()} />
                    <span style={{ textAlign: "center" }}>
                      {uploadingProductImages ? "Enviando" : "Arraste imagens para cá, ou clique aqui para selecionar"}
                    </span>
                  </ProductImageRootContainer>
                  <ImagesList images={store.product_images ?? []} />
                </div>
                <div className={"col-auto"}></div>
                <div className="col-12">
                  <button className="btn btn-primary" type="submit" style={{ marginTop: 20 }} disabled={isSubmitting}>
                    {isSubmitting ? "Salvando..." : "Salvar"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default EditStoreScreen;
