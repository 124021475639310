import { getPaymentStatusLabel, getPaymentStatusColor } from "@util/functions";
import { CreditCardBrands, PaymentMethod, PaymentStatus, SalePayment } from "@util/interfaces";
import React from "react";

const PaymentStatusPill: React.FC<{ payment: SalePayment }> = ({ payment }) => {
  let tooltipText = "";

  switch (payment.payment_method) {
    case PaymentMethod.PIX:
      tooltipText += `Meio de pagamento: PIX<br/>`;
      break;
    case PaymentMethod.BOLETO:
      tooltipText += `Meio de pagamento: BOLETO<br/>`;
      break;
    case PaymentMethod.CREDIT_CARD:
      tooltipText += `Meio de pagamento: CARTÃO DE CRÉDITO<br/>`;
      break;
    case PaymentMethod.CASH:
      tooltipText += `Meio de pagamento: ESPÉCIE<br/>`;
      break;
    case PaymentMethod.BOOKLET:
      tooltipText += `Meio de pagamento: CARNÊ<br/>`;
      break;
  }

  switch (payment.credit_card_brand) {
    case CreditCardBrands.AMERICAN_EXPRESS:
      tooltipText += `Bandeira: American Express ${payment.installment_count}x<br/>`;
      break;
    case CreditCardBrands.MASTERCARD:
      tooltipText += `Bandeira: Mastercard ${payment.installment_count}x<br/>`;
      break;
    case CreditCardBrands.VISA:
      tooltipText += `Bandeira: Visa ${payment.installment_count}x<br/>`;
      break;
    case CreditCardBrands.ELO:
      tooltipText += `Bandeira: Elo ${payment.installment_count}x<br/>`;
      break;
    case CreditCardBrands.DINERS:
      tooltipText += `Bandeira: Diners ${payment.installment_count}x<br/>`;
      break;
    case CreditCardBrands.DISCOVER:
      tooltipText += `Bandeira: Discover ${payment.installment_count}x<br/>`;
      break;
    case CreditCardBrands.JCB:
      tooltipText += `Bandeira: JCB ${payment.installment_count}x<br/>`;
      break;
    case CreditCardBrands.HIPERCARD:
      tooltipText += `Bandeira: Hipercard ${payment.installment_count}x<br/>`;
      break;
    case CreditCardBrands.AURA:
      tooltipText += `Bandeira: Aura ${payment.installment_count}x<br/>`;
      break;
    case CreditCardBrands.HIPER_ITAU:
      tooltipText += `Bandeira: Hiper (Itaú) ${payment.installment_count}x<br/>`;
      break;
    default:
      break;
  }

  return (
    <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} data-tip={tooltipText}>
      <div
        data-tag="allowRowEvents"
        style={{
          display: "inline-grid",
          margin: "none",
          width: 10,
          height: 10,
          marginRight: 5,
          backgroundColor: getPaymentStatusColor(payment.status as PaymentStatus),
          borderRadius: 10,
        }}
      />
      <span>{getPaymentStatusLabel(payment.status as PaymentStatus)}</span>
    </div>
  );
};

export default PaymentStatusPill;
