import Api from "@services/Api";
import { toastError } from "@util/functions";
import { Sale, User } from "@util/interfaces";
import * as React from "react";

import { useParams } from "react-router";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";

type ListSalesProps = {
  seller_id?: string;
};

const columns: TableColumn<Sale>[] = [
  {
    name: "Nome",
    selector: (row: Sale) => row.store.name,
    sortable: true,
  },
];

const SellerSalesListScreen: React.FC = () => {
  const { seller_id } = useParams<ListSalesProps>();
  const [loading, setLoading] = React.useState(true);
  const [seller, setSeller] = React.useState<User>();

  React.useEffect(() => {
    fetchSellerDetails();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (seller) setLoading(false);
  }, [seller]);

  const fetchSellerDetails = async () => {
    try {
      const { data } = await Api.get(`/seller/id/${seller_id}`);
      setSeller(data.seller);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  if (loading || !seller)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Vendas de {seller?.name}</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Vendas de {seller?.name}</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loading}
          columns={columns}
          data={seller.sales}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default SellerSalesListScreen;
