import { InputLabel } from "@components/ui/Typography";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { ExcursionFormValues } from "@util/interfaces";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useHistory } from "react-router";
import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";

import Select from "@components/ui/Select";

const AddExcursionScreen: React.FC = () => {
  const history = useHistory();

  const handleSubmit = async (values: ExcursionFormValues, actions: FormikHelpers<ExcursionFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.post(`/excursion`, values);
      toastMessage("Excursão criada", "success");
      actions.setSubmitting(true);
      history.push("/excursoes");
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const initialValues: ExcursionFormValues = {
    name: "",
    state: "",
    city: "",
    address: "",
    phone: "",
    is_phone_whatsapp: false,
    phone_whatsapp_url: "",
    phone_2: "",
    is_phone_2_whatsapp: false,
    phone_2_whatsapp_url: "",
    route: "",
  };

  const handleTextAreaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = "inherit";
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
  };

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Cadastrar excursão</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="row m-0 p-0">
                <div className="mb-4 col-md-3">
                  <InputLabel>Nome</InputLabel>
                  <Field name="name" className="form-control" placeholder="Digite o nome" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Estado</InputLabel>
                  <Field name="state" className="form-control" placeholder="Digite o estado" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Cidade</InputLabel>
                  <Field name="city" className="form-control" placeholder="Digite a cidade" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Endereço (no moda center)</InputLabel>
                  <Field name="address" className="form-control" placeholder="Digite informações do endereço" />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (1)</InputLabel>
                  <Field name="phone" className="form-control" placeholder="Digite o telefone (1)" required />
                </div>
                <div className="mb-4 col-md-3">
                  {values.is_phone_whatsapp}
                  <InputLabel>Telefone (1) whatsapp?</InputLabel>
                  <Select
                    placeholder={"Selecione"}
                    noOptionsMessage={() => "Sem resultados"}
                    options={[
                      { value: true, label: "Sim" },
                      { value: false, label: "Não" },
                    ]}
                    onChange={(option: any) => {
                      setFieldValue("is_phone_whatsapp", option.value);
                    }}
                  />
                </div>
                {values.is_phone_whatsapp && (
                  <div className="mb-4 col-md-3">
                    <InputLabel>Telefone (1) whatsapp url</InputLabel>
                    <Field name="phone_whatsapp_url" className="form-control" placeholder="Digite a url do whatsapp" />
                  </div>
                )}

                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (2)</InputLabel>
                  <Field name="phone_2" className="form-control" placeholder="Digite o telefone (2)" />
                </div>

                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (2) whatsapp?</InputLabel>
                  <Select
                    placeholder={"Selecione"}
                    noOptionsMessage={() => "Sem resultados"}
                    options={[
                      { value: true, label: "Sim" },
                      { value: false, label: "Não" },
                    ]}
                    onChange={(option: any) => {
                      setFieldValue("is_phone_2_whatsapp", option.value);
                    }}
                  />
                </div>

                {values.is_phone_2_whatsapp && (
                  <div className="mb-4 col-md-3">
                    <InputLabel>Telefone (2) whatsapp url</InputLabel>
                    <Field
                      name="phone_2_whatsapp_url"
                      className="form-control"
                      placeholder="Digite a url do whatsapp"
                    />
                  </div>
                )}

                <div className="mb-4 col-md-6">
                  <InputLabel>Rotas</InputLabel>
                  <Field
                    onKeyPress={handleTextAreaKeyDown}
                    as="textarea"
                    name="route"
                    className="form-control"
                    placeholder="Forneça as rotas"
                  />
                </div>

                <div className="col-12">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "enviando" : "enviar"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default AddExcursionScreen;
