import { FilterSelect } from "@components/ui/FilterSelect";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import {
  convertPaymentsToCSV,
  getInt,
  getPaymentMethodName,
  getSalePeriodName,
  getSaleTypeName,
  toastError,
} from "@util/functions";
import { SalePayment, User } from "@util/interfaces";
import Money from "@util/money";
import { monthsSelectOptions, yearsSelectOptions } from "@util/variables";
import moment from "@util/moment";
import * as React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const columns: TableColumn<SalePayment>[] = [
  {
    name: "Data",
    // The selector paid_at condition should never user Date.now() because API will not send not paid payments
    selector: (row) => new Date(row.paid_at ?? Date.now()).getTime(),
    format: (row) => moment(row.paid_at).format("DD/MM/YYYY"),
    sortable: true,
  },
  {
    name: "Nome",
    selector: (row) => row.sale.seller?.name ?? "Usuário inexistente",
    sortable: true,
  },
  {
    name: "Marca",
    selector: (row) => row.sale.store?.name ?? "Loja inexistente",
    sortable: true,
  },
  {
    name: "Valor",
    selector: (row) => row.value,
    format: (row) => Money({ amount: getInt(row.value) }).toFormat(), 
    sortable: true,
  },
  {
    name: "Tipo de venda",
    selector: (row) => row.sale.type,
    format: (row) => getSaleTypeName(row.sale.type),
    sortable: true,
  },
  {
    name: "Plano",
    selector: (row) => `${row.sale.plan.name} - ${getSalePeriodName(row.sale.period)}`,
    sortable: true,
  },
  {
    name: "Forma de pgmt.",
    selector: (row) =>
      row.payment_method
        ? getPaymentMethodName(row.payment_method, row.credit_card_brand, row.installment_count)
        : "Não informado",
    sortable: true,
  },
];

const PaymentsReportsScreen: React.FC = () => {
  const [payments, setPayments] = React.useState<SalePayment[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedMonth, setSelectedMonth] = React.useState((new Date().getMonth() + 1).toString());
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear().toString());
  const [sellers, setSellers] = React.useState<{ value: string; label: string }[]>([]);
  const [selectedSeller, setSelectedSeller] = React.useState("");
  const [downloadCsv, setDownloadCsv] = React.useState(false);

  React.useEffect(() => {
    if (downloadCsv) {
      startDownloadCsv(payments);
    }
    // eslint-disable-next-line
  }, [downloadCsv]);

  React.useEffect(() => {
    fetchSellers();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    fetchSales();
    // eslint-disable-next-line
  }, [selectedMonth, selectedYear, selectedSeller]);

  const fetchSales = async () => {
    try {
      const { data } = await Api.get(`/metrics/sellers_payments`, {
        params: { month: selectedMonth, year: selectedYear, seller: selectedSeller },
      });

      const bookletPayments = data.payments.filter((payment: SalePayment) => payment.payment_method === "booklet");
      const nonBookletPayments = data.payments.filter((payment: SalePayment) => payment.payment_method !== "booklet");

      const bookletPaymentsWithSaleValue: SalePayment[] = bookletPayments.map((payment: SalePayment) => {
        payment.payment_method === "booklet" && (payment.value = payment.sale.value);
        return payment;
      });

      const uniqueBookletPayments = bookletPaymentsWithSaleValue.filter(
        (payment, index, self) => index === self.findIndex((p) => p.sale.id === payment.sale.id)
      );

      const combinedPayments = [...uniqueBookletPayments, ...nonBookletPayments];

      setPayments(combinedPayments);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  const fetchSellers = async () => {
    try {
      const { data } = await Api.get(`/user/sellers`);
      setSellers(data.sellers.map((seller: User) => ({ value: seller.id, label: seller.name })));
    } catch (error) {
      toastError(error);
    }
  };

  function downloadSheet() {
    try {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Comissões");
      sheet.columns = [
        { header: "Data", key: "paid_at", width: 15 },
        { header: "Vendedor", key: "seller_name", width: 20 },
        { header: "Marca", key: "store_name", width: 30 },
        { header: "Tipo de venda", key: "sale_type", width: 30 },
        { header: "Valor", key: "value", width: 15 },
        { header: "Plano", key: "plan_name", width: 20 },
        { header: "Forma de pgmt.", key: "payment_method", width: 20 },
      ];
      payments.forEach((payment) => {
        sheet.addRow({
          paid_at: moment(payment.paid_at).format("DD/MM/YYYY"),
          seller_name: payment.sale.seller?.name ?? "Usuário inexistente",
          store_name: payment.sale.store?.name ?? "Loja inexistente",
          sale_type: getSaleTypeName(payment.sale.type) ?? "",
          value: Money({ amount: getInt(payment.value) }).toUnit(),
          plan_name: `${payment.sale.plan.name} - ${getSalePeriodName(payment.sale.period)}`,
          payment_method: payment.payment_method
            ? getPaymentMethodName(payment.payment_method, payment.credit_card_brand, payment.installment_count)
            : "Não informado",
        });
      });

      saveFile("Comissões.xlsx", workbook);
    } catch (error) {
      toastError(error);
      console.log(error, `Error getting file`);
    }
  }

  async function saveFile(fileName: string, workbook: any) {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    saveAs(
      new Blob([xls64], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      fileName
    );
  }

  function startDownloadCsv(array: SalePayment[]) {
    const link = document.createElement("a");
    let csv = convertPaymentsToCSV(array);
    if (csv == null) return;

    const filename = "comissoes.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
    setDownloadCsv(false);
  }

  const actionsMemo = React.useMemo(
    () => (
      <div>
        <button className={"btn btn-primary"} onClick={() => downloadSheet()}>
          Baixar XLS
        </button>
        <button className={"btn btn-primary ms-3"} onClick={() => startDownloadCsv(payments)}>
          Baixar CSV
        </button>
      </div>
    ),
    // eslint-disable-next-line
    [payments]
  );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Relatório de pagamentos</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <div className={"p-2 text-muted"}>
          <h5>Filtros</h5>
        </div>
        <div className={"row mt-2 border-bottom pb-4"}>
          <div className={"col-auto"}>
            <FilterSelect
              label={"Mês"}
              isClearable={false}
              isDisabled={loading}
              value={monthsSelectOptions.find((option) => option.value === selectedMonth) ?? null}
              options={monthsSelectOptions}
              onChange={(option) => {
                setSelectedMonth(option?.value ?? "");
              }}
            />
          </div>
          <div className={"col-auto"}>
            <FilterSelect
              label={"Ano"}
              isClearable={false}
              isDisabled={loading}
              value={yearsSelectOptions.find((option) => option.value === selectedYear) ?? null}
              options={yearsSelectOptions}
              onChange={(option) => {
                setSelectedYear(option?.value ?? "");
              }}
            />
          </div>
          <div className={"col-auto"}>
            <FilterSelect
              label={"Vendedor"}
              isClearable={true}
              isDisabled={loading}
              value={sellers.find((option) => option.value === selectedSeller) ?? null}
              options={sellers}
              onChange={(option) => {
                setSelectedSeller(option?.value ?? "");
              }}
            />
          </div>
        </div>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loading}
          columns={columns}
          data={payments}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          highlightOnHover
          pointerOnHover
          actions={actionsMemo}
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default PaymentsReportsScreen;
