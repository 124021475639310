import * as React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store from "./redux";

import ProtectedRoute from "@components/functionals/PrivateRoute";
import HomeScreen from "@pages/Home";
import LoginScreen from "@pages/Login";
import Logout from "@pages/Logout";

// Importing styles
import "react-toastify/dist/ReactToastify.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-responsive-modal/styles.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "./global.scss";
import ReactTooltip from "react-tooltip";
import EnviromentWaterMark from "@components/EnviromentWaterMark";
import env from "@util/env";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {/* Public screens */}
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/logout" component={Logout} />

          {/* Home screen with all authenticated app routes */}
          <ProtectedRoute path="/" component={HomeScreen} />
        </Switch>
      </Router>
      <ToastContainer autoClose={1000} />
      <ReactTooltip />
      {env.REACT_APP_ENV === "staging" && <EnviromentWaterMark />}
    </Provider>
  );
};

export default App;
