import * as React from "react";
import { useHistory } from "react-router";

const Logout: React.FC = () => {
  const history = useHistory();

  React.useEffect(() => {
    localStorage.clear();
    history.replace("/login");
  }, [history]);
  return <div></div>;
};

export default Logout;
