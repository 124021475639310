import { getBookletSaleStatusColor, getBookletSaleStatusLabel } from "@util/functions";
import React from "react";

const BookletSaleStatusPill: React.FC<{ status: string; diffDays: number }> = ({ status, diffDays }) => {
  return (
    <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
      <div
        style={{
          display: "inline-grid",
          width: 10,
          height: 10,
          marginRight: 5,
          backgroundColor: getBookletSaleStatusColor(status),
          borderRadius: 10,
        }}
      />
      {status === "pastDue" ? (
        <span>
          {getBookletSaleStatusLabel(status)} (<span>{diffDays} dias</span>)
        </span>
      ) : (
        <span>{getBookletSaleStatusLabel(status)}</span>
      )}
    </div>
  );
};

export default BookletSaleStatusPill;
