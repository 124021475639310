import * as React from "react";
import { useDropzone, DropzoneInputProps } from "react-dropzone";
import styled from "styled-components";

const DropZoneContainer = styled.div`
  height: 160px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 4px dashed rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  justify-content: center;
  display: flex;
  align-items: center;
  width: 200px;
`;

interface DropImagesInputProps extends DropzoneInputProps {
  imageUrl?: string;
  onFileSelect?: Function;
}

const DropImageInput: React.FC<DropImagesInputProps> = (props) => {
  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      props.onFileSelect?.(acceptedFiles);
    },
    [props]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <DropZoneContainer
      style={{
        backgroundImage: `url(${props.imageUrl})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        {...getRootProps()}
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input {...getInputProps()} />
        <p style={{ textAlign: "center" }}>{props.placeholder}</p>
      </div>
    </DropZoneContainer>
  );
};

export default DropImageInput;
