import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

LogRocket.init("moda-center/moda-center-backoffice");
setupLogRocketReact(LogRocket);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
