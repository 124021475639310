import * as React from "react";
import MapBox, { Source, Layer, Marker } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";

interface MapProps {
  handleMapClick: (latLng: { lat: number; lng: number }) => void;
  defaultLocation?: { lat: number; lng: number };
}

const ExcursionsMap: React.FC<MapProps> = ({ handleMapClick, defaultLocation }) => {
  const [currentLocation, setCurrentLocation] = React.useState<{
    lat: number;
    lng: number;
  }>();

  const defaultProps = {
    center: {
      lat: -7.945660167207577,
      lng: -36.22653487733574,
    },
    zoom: 19,
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <MapBox
        mapboxAccessToken="pk.eyJ1IjoiYWxjaWRlc3NpbHZhbmV0byIsImEiOiJjanFzNmFtdGYwZTRrNDlsbjhnbndhYWQ2In0.MPv9Ty8MkXsZY1M6_Rvumg"
        mapLib={import("mapbox-gl")}
        initialViewState={{
          longitude: defaultProps.center.lng,
          latitude: defaultProps.center.lat,
          zoom: 17,
        }}
        style={{ width: "100%", height: "100%" }}
        onClick={(event) => {
          console.log(event.lngLat.lat);
          setCurrentLocation({ lat: event.lngLat.lat, lng: event.lngLat.lng });
          handleMapClick({ lat: event.lngLat.lat, lng: event.lngLat.lng });
        }}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
      >
        <Source
          type="raster"
          tiles={[
            "https://api.maptiler.com/tiles/deac7f46-5ee9-456c-a1bb-284f460dd6c4/{z}/{x}/{y}.png?key=SxkhrSPvnhhnsRJkUzCF",
          ]}
        >
          <Layer type="raster" />
        </Source>
        {currentLocation && <Marker latitude={currentLocation.lat} longitude={currentLocation.lng} color="red" />}
        {defaultLocation && <Marker latitude={defaultLocation.lat} longitude={defaultLocation.lng} color="red" />}
      </MapBox>
    </div>
  );
};

export default ExcursionsMap;
