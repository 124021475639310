import * as React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";

type MapMarkerProps = {
  lat: number;
  lng: number;
};

const MapMarker: React.FC<MapMarkerProps> = () => {
  return (
    <FaMapMarkerAlt size={32} color={"#9841e2"} style={{ position: "absolute", transform: "translate(-50%, -100%)" }} />
  );
};

export default MapMarker;
