import * as React from "react";
import styled from "styled-components";

const Label = styled.span`
  font-size: 24px;
  color: #9fa2b4;
  font-weight: bold;
  transition: color 0.2s ease;
`;

const Number = styled.span`
  font-size: 32px;
  color: black;
  font-weight: 800;
  transition: color 0.2s ease;
`;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px #ececec solid;
  padding: 20px 80px;
  background-color: white;
  transition: border 0.2s;
  :hover {
    border: 2px #9841e2 solid;
    ${Label} {
      color: #9841e2;
    }
    ${Number} {
      color: #9841e2;
    }
  }
  @media (max-width: 600px) {
    padding: 20px 20px;
  }
`;

interface MetricCardProps {
  label: string;
  number: string;
  loading?: boolean;
}

const MetricCard: React.FC<MetricCardProps> = (props) => {
  return (
    <RootContainer>
      <Label>{props.label}</Label>
      {props.loading ? (
        <div
          className="spinner-border text-primary mb-3"
          style={{ width: 48, height: 48, opacity: 0.8 }}
          role="status"
        />
      ) : (
        <Number>{props.number}</Number>
      )}
    </RootContainer>
  );
};

export default MetricCard;
