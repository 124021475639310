import * as React from "react";
import Sidebar from "@components/ui/Sidebar";
import { Redirect, Switch } from "react-router";
import ProtectedRoute from "@components/functionals/PrivateRoute";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@redux/index";
import { RootContentContainer } from "./components";
import StoresListScreen from "@pages/Stores/List";
import { fetchStores } from "@redux/AppData.store";
import AddStoreScreen from "@pages/Stores/Add";
import EditStoreScreen from "@pages/Stores/Edit";
import SellersListScreen from "@pages/Sellers/List";
import AddSellerScreen from "@pages/Sellers/Add";
import HotelsListScreen from "@pages/Hotels/List";
import AddHotelScreen from "@pages/Hotels/Add";
import EditHotelScreen from "@pages/Hotels/Edit";
import ExcursionsListScreen from "@pages/Excursions/List";
import AddExcursionScreen from "@pages/Excursions/Add";
import EditExcursionScreen from "@pages/Excursions/Edit";
import AdminDashboard from "@pages/Dashboard/Admin";
import SellerDashboard from "@pages/Dashboard/Seller";
import PlansListScreen from "@pages/Plans/List";
import AddPlanScreen from "@pages/Plans/Add";
import EditPlanScreen from "@pages/Plans/Edit";
import SellerSalesListScreen from "@pages/Sellers/Sales";
import SalesListScreen from "@pages/Sales/List";
import EditSellerScreen from "@pages/Sellers/Edit";
import NewSaleScreen from "@pages/Sales/Add";
import PaymentsListScreen from "@pages/Payments/List";
import NewPaymentScreen from "@pages/Payments/Add";
import PaymentsReportsScreen from "@pages/Comissions";
import OpeningTimeScreen from "@pages/OpeningTime";
import LiveOptionsPage from "@pages/LiveOptions";
import MapsScreen from "@pages/Maps";
import AddLocationsPage from "@pages/Locations/addLocation";
import ListLocationsPage from "@pages/Locations/List";
import { AppBannersPage } from "@pages/AppBanners";
import CalendarPage from "@pages/Calendar";
import BookletSalesListScreen from "@pages/Booklets/List";

const Home: React.FC = () => {
  const userRole = localStorage.getItem("user_role");
  return userRole === "admin" ? <Redirect to="/dashboard" /> : <Redirect to="/estabelecimentos" />;
};

const HomeScreen: React.FC = () => {
  const { isSidebarToggled } = useSelector((state: RootState) => state.appSettings);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchStores());
  }, [dispatch]);

  const DashboardScreen: React.FC = () => {
    const userRole = localStorage.getItem("user_role");
    return userRole === "admin" ? <AdminDashboard /> : <SellerDashboard />;
  };

  return (
    <div
      style={{
        flex: 1,
        flexDirection: "row",
        display: "flex",
        backgroundColor: "#f7f8fc",
      }}
    >
      <Sidebar />
      <RootContentContainer is_sidebar_toggled={isSidebarToggled}>
        <Switch>
          <ProtectedRoute exact path={"/"} component={Home} />
          <ProtectedRoute exact path={"/dashboard"} component={DashboardScreen} />
          <ProtectedRoute exact path={"/estabelecimentos"} component={StoresListScreen} />
          <ProtectedRoute exact path={"/estabelecimentos/cadastro"} component={AddStoreScreen} />
          <ProtectedRoute exact path={"/estabelecimentos/:store_id/editar"} component={EditStoreScreen} />
          <ProtectedRoute exact path={"/estabelecimentos/:store_id/horarios"} component={OpeningTimeScreen} />
          <ProtectedRoute exact path={"/vendedores"} component={SellersListScreen} />
          <ProtectedRoute exact path={"/vendedores/cadastro"} component={AddSellerScreen} />
          <ProtectedRoute exact path={"/hoteis"} component={HotelsListScreen} />
          <ProtectedRoute exact path={"/hoteis/cadastro"} component={AddHotelScreen} />
          <ProtectedRoute exact path={"/hoteis/:hotel_id/editar"} component={EditHotelScreen} />
          <ProtectedRoute exact path={"/excursoes"} component={ExcursionsListScreen} />
          <ProtectedRoute exact path={"/excursoes/cadastro"} component={AddExcursionScreen} />
          <ProtectedRoute exact path={"/excursoes/:excursion_id/editar"} component={EditExcursionScreen} />
          <ProtectedRoute exact path={"/planos"} component={PlansListScreen} />
          <ProtectedRoute exact path={"/planos/cadastro"} component={AddPlanScreen} />
          <ProtectedRoute exact path={"/planos/:plan_id/editar"} component={EditPlanScreen} />
          <ProtectedRoute exact path={"/vendedores/:seller_id/vendas"} component={SellerSalesListScreen} />
          <ProtectedRoute exact path={"/vendas"} component={SalesListScreen} />
          <ProtectedRoute exact path={"/vendas/cadastro"} component={NewSaleScreen} />
          <ProtectedRoute exact path={"/vendas/:sale_id/pagamentos"} component={PaymentsListScreen} />
          <ProtectedRoute exact path={"/vendas/:sale_id/pagamentos/cadastro"} component={NewPaymentScreen} />
          <ProtectedRoute exact path={"/carnes"} component={BookletSalesListScreen} />
          <ProtectedRoute exact path={"/vendedores/:seller_id/editar"} component={EditSellerScreen} />
          <ProtectedRoute exact path={"/relatorios"} component={PaymentsReportsScreen} />
          <ProtectedRoute exact path={"/live"} component={LiveOptionsPage} />
          <ProtectedRoute exact path={"/maps"} component={MapsScreen} />
          <ProtectedRoute exact path={"/add-location"} component={AddLocationsPage} />
          <ProtectedRoute exact path={"/locations"} component={ListLocationsPage} />
          <ProtectedRoute exact path={"/calendar"} component={CalendarPage} />
          <ProtectedRoute exact path={"/app-banners"} component={AppBannersPage} />
        </Switch>
      </RootContentContainer>
    </div>
  );
};

export default HomeScreen;
