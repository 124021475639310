import * as React from "react";

const EnviromentWaterMark: React.FC = () => {
  return (
    <div style={{ position: "absolute", bottom: 10, left: 10, color: "red", zIndex: 20 }}>
      <h5>STAGING</h5>
    </div>
  );
};

export default EnviromentWaterMark;
