import React, { useState } from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { Plan, Sale, SelectOption, Store } from "@util/interfaces";
import { toastError } from "@util/functions";
import { confirmAlert } from "react-confirm-alert";
import Select from "@components/ui/Select";
import AsyncSelect from "@components/ui/Select/Async";
import { useHistory } from "react-router";

const NewSaleScreen: React.FC = () => {
  const history = useHistory();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<number>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>();
  const [selectedSaleType, setSelectedSaleType] = useState<string>();
  const [selectedStoreId, setSelectedStoreId] = useState<number>();
  const [salePaymentLink] = useState<string>();
  const [pendingSale, setPendingSale] = useState<Sale>();
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [loadingPendingSale, setLoadingPendingSale] = useState(false);

  React.useEffect(() => {
    fetchPlans();
  }, []);

  React.useEffect(() => {
    if (selectedStoreId) {
      fetchPendingSale();
    }
    // eslint-disable-next-line
  }, [selectedStoreId]);

  const loadStores = async (inputValue: string): Promise<SelectOption[]> => {
    try {
      const { data } = await Api.get(`/store?name=${inputValue}`);
      return data.stores?.map?.((store: Store) => ({ value: store.id, label: store.name })) || [];
    } catch (error) {
      toastError(error);
      return [];
    }
  };

  const fetchPendingSale = async () => {
    try {
      setLoadingPendingSale(true);
      const { data } = await Api.get(`/store/${selectedStoreId}/pending_sale`);
      setPendingSale(data.sale);
      setLoadingPendingSale(false);
    } catch (error) {
      setLoadingPendingSale(false);
      toastError(error);
    }
  };

  const fetchPlans = async () => {
    try {
      const { data } = await Api.get(`/plan`);
      setPlans(data.plans);
      setLoadingPlans(false);
    } catch (error) {
      setLoadingPlans(false);
      toastError(error);
    }
  };

  const validateSubmit = () => {
    if (selectedSaleType === "upgrade" || selectedSaleType === "renewal") {
      confirmAlert({
        title: "Atenção",
        message: `Ao criar uma renovação ou atualização, qualquer venda ativa será 
        expirada com a data atual, e a loja irá para o plano Free até que a nova venda seja confirmada. 
        Deseja continuar?`,
        buttons: [
          {
            label: "Sim",
            onClick: async () => {
              handleSubmit();
            },
          },
          {
            label: "Cancelar",
            onClick: () => {},
          },
        ],
      });
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (!selectedPlanId || !selectedPeriod || !selectedSaleType || !selectedStoreId) {
      toastError("Preencha todos os dados para continuar");
      return;
    }
    setSubmittingForm(true);

    try {
      const { data } = await Api.post(`/sale`, {
        plan_id: selectedPlanId,
        period: selectedPeriod,
        sale_type: selectedSaleType,
        store_id: selectedStoreId,
      });
      history.push(`/vendas/${data.sale.id}/pagamentos`);
    } catch (error) {
      setSubmittingForm(false);
      toastError(error);
    }
  };

  const periodsOptions = [
    { value: "monthly", label: "Mensal" },
    { value: "semesterly", label: "Semestral" },
    { value: "yearly", label: "Anual" },
  ];

  const saleTypesOptions = [
    { value: "first_time", label: "Primeira venda" },
    { value: "renewal", label: "Renovação" },
    { value: "upgrade", label: "Atualização" },
  ];

  if (loadingPlans) {
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Cadastrar venda</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );
  }

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Cadastrar venda</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <div className="row m-0 p-0">
          <div className="mb-4 col-md-3">
            <label htmlFor="storeName">estabelecimento</label>
            <AsyncSelect
              placeholder="Buscar estabelecimento"
              loadingMessage={() => "Buscando..."}
              noOptionsMessage={() => "Sem resultados"}
              cacheOptions
              defaultOptions
              loadOptions={loadStores}
              onChange={(option: any) => {
                setSelectedStoreId(option?.value);
              }}
            />
          </div>
          <div className="mb-4 col-md-3">
            <label htmlFor="plan">plano</label>
            <Select
              placeholder="Selecionar plano"
              options={plans.map((plan) => ({ value: plan.id, label: plan.name }))}
              onChange={(option: any) => {
                setSelectedPlanId(option?.value);
              }}
            />
          </div>
          <div className="mb-4 col-md-3">
            <label htmlFor="period">período</label>
            <Select
              placeholder="Selecionar período"
              options={periodsOptions}
              onChange={(option: any) => {
                setSelectedPeriod(option?.value);
              }}
            />
          </div>
          <div className="mb-4 col-md-3">
            <label htmlFor="sale_type">tipo de venda</label>
            <Select
              placeholder="Selecionar tipo de venda"
              options={saleTypesOptions}
              onChange={(option: any) => {
                setSelectedSaleType(option?.value);
              }}
            />
          </div>

          {!loadingPendingSale && !pendingSale && !salePaymentLink && (
            <div className="col-12 mt-4">
              <button
                className="btn btn-primary"
                onClick={validateSubmit}
                disabled={submittingForm || pendingSale !== undefined}
              >
                {submittingForm ? "Criando..." : "Criar"}
              </button>
            </div>
          )}
        </div>
      </ContentContainer>
    </RootContainer>
  );
};

export default NewSaleScreen;
