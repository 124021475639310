import React from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import Modal from "react-responsive-modal";
import { useLocationCategories } from "./hooks/useLocationCategories";

interface LocationCategoryFormValues {
  name: string;
  description: string;
  color?: string;
  image_url?: string;
}

interface LocationCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LocationCategoryModal: React.FC<LocationCategoryModalProps> = ({ isOpen, onClose }) => {
  const { creatingLocationCategory, createLocationCategory } = useLocationCategories();

  async function handleSubmit(values: LocationCategoryFormValues) {
    await createLocationCategory({
      name: values.name,
      description: values.description,
      color: values.color,
      image_url: values.image_url,
    });
    onClose();
  }

  const initialValues: LocationCategoryFormValues = {
    name: "",
    description: "",
    color: "",
    image_url: "",
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      center
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({}) => (
          <Form style={{ minWidth: 300, marginTop: 20 }}>
            <div className="mb-1 col-12 row p-0 m-0">
              <div className="col-12 mb-3">
                <label>Nome</label>
                <Field type="text" name="name" className="form-control" />
              </div>
            </div>
            <div className="mb-1 col-12 row p-0 m-0">
              <div className="col-12 mb-3">
                <label>Descrição</label>
                <Field type="text" name="description" className="form-control" />
              </div>
            </div>
            <div className="mb-1 col-12 row p-0 m-0">
              <div className="col-12 mb-3">
                <label>Cor</label>
                <Field type="text" name="color" className="form-control" />
              </div>
            </div>
            <button className="btn btn-outline-primary ms-2" type="submit" disabled={creatingLocationCategory}>
              {creatingLocationCategory ? "Enviando..." : "Enviar"}
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
