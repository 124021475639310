import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { toastError } from "@util/functions";
import { ptBR } from "date-fns/locale";
import moment from "moment";
import * as React from "react";
import { Calendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { toast } from "react-toastify";

const CalendarPage: React.FC = () => {
  const [currentDates, setCurrentDates] = React.useState<Date[]>([]);
  const [selectedDates, setSelectedDates] = React.useState<Date[]>([...currentDates]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isFetchingDates, setIsFetchingDates] = React.useState(false);

  const modifiers = {
    selected: (date: Date) => selectedDates.some((selectedDate) => moment(selectedDate).isSame(date, "day")),
  };

  const modifiersClassNames = {
    selected: "bg-primary rounded-circle text-white",
  };

  React.useEffect(() => {
    fetchDates();
  }, []);

  const fetchDates = async () => {
    setIsFetchingDates(true);
    try {
      const { data } = await Api.get(`/system_settings/open_dates`);
      if (data.dates?.length > 0) {
        const fetchedDates = data.dates.map((date: string) => moment(date).utc().toDate());
        setCurrentDates(fetchedDates);
        setSelectedDates((prevSelectedDates) => [...prevSelectedDates, ...fetchedDates]);
      }
    } catch (error) {
      toastError(error);
    }
    setIsFetchingDates(false);
  };

  const handleDayClick = (date: any) => {
    setSelectedDates((prevSelectedDates) => {
      const isDateSelected = prevSelectedDates.some((selectedDate) => moment(selectedDate).isSame(date, "day"));

      if (isDateSelected) {
        return prevSelectedDates.filter((selectedDate) => !moment(selectedDate).isSame(date, "day"));
      } else {
        return [...prevSelectedDates, date];
      }
    });
  };

  const handleUpdateDates = async () => {
    setIsSubmitting(true);
    try {
      if (selectedDates.length === 0) {
        toast.error("Selecione pelo menos uma data!");
        setIsSubmitting(false);
        return;
      }

      const formattedDates = selectedDates.map((date) => moment(date).utc().format("DD/MM/YYYY").toString());
      const { data } = await Api.post(`/system_settings`, {
        dates: formattedDates,
      });
      toast.success("Datas salvas com sucesso!");
    } catch (error) {
      toastError(error);
    }
    setIsSubmitting(false);
  };

  if (isFetchingDates)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Editar calendário</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Editar calendário</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <div className="d-flex flex-row  align-items-center justify-content-center">
          <div className="col-12 col-md-6 m-2 m-md-0">
            <Calendar
              onDayClick={handleDayClick}
              modifiers={modifiers}
              locale={ptBR}
              modifiersClassNames={modifiersClassNames}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center my-5">
          <button onClick={handleUpdateDates} className={"btn btn-primary"} disabled={isSubmitting}>
            {isSubmitting ? "Salvando..." : "Salvar"}
          </button>
        </div>
      </ContentContainer>
    </RootContainer>
  );
};

export default CalendarPage;
