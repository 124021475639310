import * as React from "react";
import styled from "styled-components";
import { PropsWithChildren } from "react";

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px #ececec solid;
  padding: 20px 80px;
  background-color: white;
  transition: border 0.2s;
  :hover {
    border: 2px #9841e2 solid;
  }
  @media (max-width: 600px) {
    padding: 20px 20px;
  }
`;

interface CardProps extends PropsWithChildren {}

const Card: React.FC<CardProps> = ({ children }) => {
  return <RootContainer>{children}</RootContainer>;
};

export default Card;
