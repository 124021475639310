import FakeLink from "@components/ui/FakeLink";
import FilterInput from "@components/ui/FilterInput";
import { FilterSelect } from "@components/ui/FilterSelect";
import { setStoresQuery } from "@redux/AppData.store";
import { RootState, useAppDispatch } from "@redux/index";
import { normalizeString } from "@util/functions";
import { StorePlan } from "@util/interfaces";
import * as React from "react";
import { useSelector } from "react-redux";

const modulesOptions = [
  { value: "Laranja", label: "Laranja" },
  { value: "Amarelo", label: "Amarelo" },
  { value: "Verde", label: "Verde" },
  { value: "Azul", label: "Azul" },
  { value: "Vermelho", label: "Vermelho" },
  { value: "Branco", label: "Branco" },
];

const addressTypesOptions = [
  { value: "street", label: "Rua" },
  { value: "block", label: "Bloco" },
];

const storeBuildingTypeOptions = [
  { value: "store", label: "Loja" },
  { value: "box", label: "Box" },
];

const stablishmentTypeOptions = [
  { value: "store", label: "Loja" },
  { value: "kiosk", label: "Quiosque" },
  { value: "hotel", label: "Hotel" },
  { value: "restaurant", label: "Restaurante" },
];

const storePlansOptions = [
  { value: `${StorePlan.FREE}`, label: "Grátis" },
  { value: `${StorePlan.STANDARD}`, label: "Standard" },
  { value: `${StorePlan.GOLD}`, label: "Gold" },
  { value: `${StorePlan.PLATINUM}`, label: "Platinum" },
];

const storeInfoFilterOptions = [
  { value: "cpf", label: "CPF" },
  { value: "cnpj", label: "CNPJ" },
  { value: "legal_name", label: "Razão social" },
  { value: "patrimony_code", label: "Código do patrimônio" },
];

export const Filters: React.FC = () => {
  const { loadingStores, storesQuery } = useSelector((state: RootState) => state.appSettings);
  const dispatch = useAppDispatch();
  // Filter state
  const [storeNameFilterText, setStoreNameFilterText] = React.useState("");
  const [storeModuleFilterValue, setStoreModuleFilterValue] = React.useState("");
  const [storeAddressTypeFilterValue, setStoreAddressTypeFilterValue] = React.useState("");
  const [storeStreetFilterValue, setStoreStreetFilterValue] = React.useState("");
  const [storeBlockFilterValue, setStoreBlockFilterValue] = React.useState("");
  const [storeNumberFilterValue, setStoreNumberFilterValue] = React.useState("");
  const [storeBuildingTypeFilterValue, setStoreBuildingTypeFilterValue] = React.useState("");
  const [storePlanFilterValue, setStorePlanFilterValue] = React.useState<StorePlan | undefined>();
  const [stablishmentTypeFilterValue, setStablishmentTypeFilterValue] = React.useState("");
  const [storeInfoFilterType, setStoreInfoFilterType] = React.useState("");
  const [storeInfoFilterValue, setStoreInfoFilterValue] = React.useState("");

  React.useEffect(() => {
    return () => {
      handleClearFilter();
    };
    // eslint-disable-next-line
  }, []);

  const handleClearFilter = () => {
    setStoreNameFilterText("");
    setStoreModuleFilterValue("");
    setStoreAddressTypeFilterValue("");
    setStoreStreetFilterValue("");
    setStoreBlockFilterValue("");
    setStoreNumberFilterValue("");
    setStoreBuildingTypeFilterValue("");
    setStablishmentTypeFilterValue("");
    setStorePlanFilterValue(undefined);
    setStoreInfoFilterType("");
    setStoreInfoFilterValue("");
    dispatch(
      setStoresQuery({
        ...storesQuery,
        name: undefined,
        stablishment_type: undefined,
        module: undefined,
        address_type: undefined,
        street: undefined,
        block: undefined,
        number: undefined,
        building_type: undefined,
        plan: undefined,
        store_info_type: undefined,
        store_info_value: undefined,
      })
    );
  };

  const onSubmit = () => {
    let query = { ...storesQuery, page: 1 };
    query.name = storeNameFilterText ?? undefined;
    query.module = storeModuleFilterValue ?? undefined;
    query.address_type = storeAddressTypeFilterValue ?? undefined;
    query.street = normalizeString(storeStreetFilterValue) ?? undefined;
    query.block = normalizeString(storeBlockFilterValue) ?? undefined;
    query.number = normalizeString(storeNumberFilterValue) ?? undefined;
    query.building_type = storeBuildingTypeFilterValue ?? undefined;
    query.plan = storePlanFilterValue ?? undefined;
    query.stablishment_type = stablishmentTypeFilterValue ?? undefined;
    query.store_info_type = storeInfoFilterType ?? undefined;
    query.store_info_value = storeInfoFilterValue ?? undefined;
    dispatch(setStoresQuery(query));
  };

  return (
    <form
      className="row py-1 border-bottom pb-4 g-2 align-items-center"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className={"col-auto"}>
        <FilterInput
          label={"Nome fantasia"}
          disabled={loadingStores}
          placeholder={"Todas"}
          value={storeNameFilterText}
          onChange={(e) => {
            setStoreNameFilterText(e.currentTarget.value);
          }}
        />
      </div>
      <div className={"col-auto"}>
        <FilterSelect
          label={"Tipo"}
          isDisabled={loadingStores}
          options={stablishmentTypeOptions}
          value={stablishmentTypeOptions.find((option) => stablishmentTypeFilterValue === option.value) || null}
          onChange={(option) => setStablishmentTypeFilterValue(option?.value ?? "")}
        />
      </div>
      <div className={"col-auto"}>
        <FilterSelect
          label={"Buscar por dados do estabelecimento"}
          isDisabled={loadingStores}
          options={storeInfoFilterOptions}
          value={storeInfoFilterOptions.find((option) => storeInfoFilterType === option.value) || null}
          onChange={(option) => setStoreInfoFilterType(option?.value ?? "")}
        />
      </div>

      {storeInfoFilterType !== "" && (
        <div className={"col-auto"}>
          <FilterInput
            label={
              storeInfoFilterType === "cpf"
                ? "CPF"
                : storeInfoFilterType === "cnpj"
                ? "CNPJ"
                : storeInfoFilterType === "legal_name"
                ? "Razão social"
                : "Código do patrimônio"
            }
            disabled={loadingStores}
            placeholder={"Todas"}
            value={storeInfoFilterValue}
            onChange={(e) => {
              setStoreInfoFilterValue(e.currentTarget.value);
            }}
          />
        </div>
      )}
      <div className={"col-auto"}>
        <FilterSelect
          label={"Módulo"}
          isDisabled={loadingStores}
          options={modulesOptions}
          value={modulesOptions.find((option) => storeModuleFilterValue === option.value) || null}
          onChange={(option) => setStoreModuleFilterValue(option?.value ?? "")}
        />
      </div>
      <div className={"col-auto"}>
        <FilterSelect
          label={"Tipo de endereço"}
          isDisabled={loadingStores}
          options={addressTypesOptions}
          value={addressTypesOptions.find((option) => storeAddressTypeFilterValue === option.value) || null}
          onChange={(option) => setStoreAddressTypeFilterValue(option?.value ?? "")}
        />
      </div>

      {storeAddressTypeFilterValue === "block" && (
        <div className={"col-auto"}>
          <FilterInput
            disabled={loadingStores}
            label={"Bloco"}
            placeholder={"Todos"}
            value={storeBlockFilterValue}
            onChange={(e) => {
              setStoreBlockFilterValue(e.currentTarget.value);
            }}
          />
        </div>
      )}

      {storeAddressTypeFilterValue === "street" && (
        <div className={"col-auto"}>
          <FilterInput
            disabled={loadingStores}
            label={"Rua"}
            placeholder={"Todas"}
            value={storeStreetFilterValue}
            onChange={(e) => {
              setStoreStreetFilterValue(e.currentTarget.value);
            }}
          />
        </div>
      )}

      <div className={"col-auto"}>
        <FilterInput
          disabled={loadingStores}
          label={"Número"}
          placeholder={"Todos"}
          value={storeNumberFilterValue}
          onChange={(e) => {
            setStoreNumberFilterValue(e.currentTarget.value);
          }}
        />
      </div>

      <div className={"col-auto"}>
        <FilterSelect
          label={"Tipo de estabelecimento"}
          isDisabled={loadingStores}
          options={storeBuildingTypeOptions}
          value={storeBuildingTypeOptions.find((option) => storeBuildingTypeFilterValue === option.value) || null}
          onChange={(option) => setStoreBuildingTypeFilterValue(option?.value ?? "")}
        />
      </div>

      <div className={"col-auto"}>
        <FilterSelect
          label={"Plano"}
          isDisabled={loadingStores}
          options={storePlansOptions}
          value={storePlansOptions.find((option) => storePlanFilterValue === Number(option?.value)) || null}
          onChange={(option) => setStorePlanFilterValue(Number(option?.value) ?? "")}
        />
      </div>
      <div className="col-auto py-auto d-flex align-items-center ">
        <button className={"btn btn-primary"} style={{ padding: 5, height: "auto" }} onClick={onSubmit}>
          Buscar
        </button>
      </div>
      <div className="col-auto py-auto d-flex align-items-center">
        <FakeLink onClick={handleClearFilter}>limpar filtros</FakeLink>
      </div>
    </form>
  );
};
