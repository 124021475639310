import * as React from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";
import { Excursion } from "@util/interfaces";
import { normalizeString, toastError } from "@util/functions";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Api from "@services/Api";
import states from "@util/states.json";
import { FilterSelect } from "@components/ui/FilterSelect";
import FilterInput from "@components/ui/FilterInput";
import FakeLink from "@components/ui/FakeLink";
import { useWindowSize } from "@util/hooks";
import { ExcursionLocationModal } from "../LocationModal";

const ExcursionsListScreen: React.FC = () => {
  const [excursions, setExcursions] = React.useState<Excursion[]>([]);
  const [loading, setLoading] = React.useState(true);
  const { width } = useWindowSize();
  // Filters state
  const [excursionNameFilterValue, setExcursionNameFilterValue] = React.useState("");
  const [excursionStateFilterValue, setExcursionStateFilterValue] = React.useState("");
  const [isLocationModalOpen, setIsLocationModalOpen] = React.useState(false);
  const [selectedExcursion, setSelectedExcursion] = React.useState<Excursion>();

  const handleExcursionLocationClick = (excursion: Excursion) => {
    setSelectedExcursion(excursion);
  };

  React.useEffect(() => {
    if (selectedExcursion) setIsLocationModalOpen(true);
  }, [selectedExcursion]);

  const columns: TableColumn<Excursion>[] = [
    {
      name: "Nome",
      selector: (row: Excursion) => row.name.trim(),
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row: Excursion) => row.state,
      sortable: true,
    },
    {
      name: "Cidade",
      selector: (row: Excursion) => row.city,
    },
    {
      name: "Endereço no mdc",
      selector: (row: Excursion) => row.address ?? "",
    },
    {
      name: "Telefone (1)",
      selector: (row: Excursion) => row.phone,
    },
    {
      name: "Telefone (2)",
      selector: (row: Excursion) => row.phone_2 ?? "",
    },
    {
      name: "Ações",
      cell: (row: Excursion) => (
        <div className="d-flex">
          <button
            className="btn btn-link"
            onClick={() => {
              handleExcursionLocationClick(row);
            }}
          >
            Localização
          </button>
        </div>
      ),
    },
  ];

  const history = useHistory();

  React.useEffect(() => {
    fetchExcursions();
    // eslint-disable-next-line
  }, []);

  const fetchExcursions = async () => {
    try {
      const { data } = await Api.get(`/excursion`);
      setExcursions(data.excursions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  const handleClearFilter = () => {
    setExcursionNameFilterValue("");
    setExcursionStateFilterValue("");
  };

  // Filtering items
  let filteredItems = excursions;
  if (excursionNameFilterValue) {
    filteredItems = filteredItems.filter(
      (excursion) =>
        excursion.name && normalizeString(excursion.name).includes(normalizeString(excursionNameFilterValue))
    );
  }
  if (excursionStateFilterValue) {
    filteredItems = filteredItems.filter(
      (excursion) =>
        excursion.state && normalizeString(excursion.state).includes(normalizeString(excursionStateFilterValue))
    );
  }

  if (loading)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Excursões</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Excursões</PageTitle>
        <Link to="/excursoes/cadastro" className="btn btn-primary">
          {width > 768 ? "Cadastrar Excursão" : "+"}
        </Link>
      </TitleContainer>
      <ContentContainer>
        <div className={"p-2 text-muted"}>
          <h5>Filtros</h5>
        </div>
        <div className="row py-1 border-bottom pb-4 g-2">
          <div className={"col-auto"}>
            <FilterInput
              label={"Nome"}
              disabled={loading}
              placeholder={"Todos"}
              value={excursionNameFilterValue}
              onChange={(e) => {
                setExcursionNameFilterValue(e.currentTarget.value);
              }}
            />
          </div>
          <div className={"col-auto"}>
            <FilterSelect
              label={"Estado"}
              isDisabled={loading}
              value={
                states
                  .map((state) => ({ value: state.short_name, label: state.full_name }))
                  .find((state) => state.value === excursionStateFilterValue) || null
              }
              options={states.map((state) => ({
                value: state.short_name,
                label: state.full_name,
              }))}
              onChange={(option) => {
                setExcursionStateFilterValue(option?.value ?? "");
              }}
            />
          </div>

          <div className="col-auto py-auto d-flex align-items-center">
            <FakeLink onClick={handleClearFilter}>limpar</FakeLink>
          </div>
        </div>
        <div className={"p-2 mt-2 text-muted"}>
          <h5>Resultados</h5>
        </div>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loading}
          columns={columns}
          data={filteredItems}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          highlightOnHover
          pointerOnHover
          onRowClicked={(row: Excursion) => {
            history.push(`/excursoes/${row.id}/editar`);
          }}
        />
      </ContentContainer>
      <ExcursionLocationModal
        open={isLocationModalOpen}
        excursion={selectedExcursion}
        center
        onClose={() => {
          setSelectedExcursion(undefined);
          setIsLocationModalOpen(false);
          fetchExcursions();
        }}
      />
    </RootContainer>
  );
};

export default ExcursionsListScreen;
