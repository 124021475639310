import { PageTitle } from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { Image } from "@util/interfaces";
import React from "react";
import Modal, { ModalProps } from "react-responsive-modal";

interface ImageUrlModalProps extends ModalProps {
  image: Image;
}

export const ImageUrlModal: React.FC<ImageUrlModalProps> = (props) => {
  const { image } = props;
  const [imageUrl, setImageUrl] = React.useState(image.clickable_url ?? "");
  const [isSaving, setIsSaving] = React.useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await Api.patch(`/image/${image.id}`, { url: imageUrl });
      toastMessage("Link da imagem atualizado", "success");
      props.onClose();
    } catch (error) {
      toastError(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal {...props}>
      <PageTitle>Atualizar link da imagem</PageTitle>
      <div style={{ minWidth: 400, maxWidth: "90vw", marginTop: 20 }}>
        <input
          className="form-control"
          style={{ maxWidth: "80vw" }}
          value={imageUrl}
          placeholder={"Link da imagem"}
          onChange={(e) => {
            setImageUrl(e.target.value);
          }}
        />
        <button className="btn btn-primary mt-3" onClick={handleSave} disabled={isSaving}>
          {isSaving ? "Salvando" : "Salvar"}
        </button>
      </div>
    </Modal>
  );
};
