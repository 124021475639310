import Api from "@services/Api";
import { AddressType, Patrimony } from "@util/interfaces";
import * as React from "react";
import { toastError, toastMessage } from "@util/functions";
import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";

const modules = ["verde", "azul", "amarelo", "laranja", "vermelho", "branco"];
const streets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P"];
const blocks = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

const MapForm: React.FC<{
  latitude: number;
  longitude: number;
  onClear?: () => void;
}> = ({ latitude, longitude, onClear }) => {
  // New patrimony form state
  const [module, setModule] = React.useState("");
  const [addressType, setAddressType] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [block, setBlock] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [buildingType, setBuildingType] = React.useState("");
  const [loadingPatrimony, setLoadingPatrimony] = React.useState(false);
  const [patrimony, setPatrimony] = React.useState<Patrimony>();

  React.useEffect(() => {
    setPatrimony(undefined);
    // eslint-disable-next-line
  }, [module, addressType, street, block, number, buildingType]);

  const clear = () => {
    setModule("");
    setAddressType("");
    setStreet("");
    setBlock("");
    setNumber("0");
    setBuildingType("");
    setPatrimony(undefined);
    onClear?.();
  };

  const fetchPatrimony = async () => {
    setPatrimony(undefined);
    if (!module || !addressType || !number || !buildingType) return;
    if (addressType === AddressType.BLOCK && !block) return;
    if (addressType === AddressType.STREET && !street) return;

    setLoadingPatrimony(true);
    try {
      const { data } = await Api.get(
        `/patrimony?module=${module}&address_type=${addressType}&street=${street}&block=${block}&number=${number}&building_type=${buildingType}`
      );
      if (!data.patrimonies[0]) {
        toastError("Nenhum patrimônio encontrado");
        setLoadingPatrimony(false);
        return;
      }
      setPatrimony(data.patrimonies[0]);
      setLoadingPatrimony(false);
    } catch (error) {
      setLoadingPatrimony(false);
      console.log(error);
    }
  };

  const assignCoordinates = async (force?: boolean) => {
    try {
      if (!patrimony) return toastMessage(``);
      let url = `/patrimony/${patrimony.id}/coordinates`;
      await Api.post(url, { latitude, longitude, force: force ? true : false });
      toastMessage(`Sucesso`, "success");
    } catch (error: any) {
      if (error.response?.data?.message === "patrimony-already-has-coordinates") {
        if (window.confirm("Este patrimônio já tem coordenadas configuradas. Deseja substituir as coordenadas?")) {
          await assignCoordinates(true);
        } else {
        }
        return;
      }
      toastError(error);
    }
  };

  const handleListener = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp") {
      setNumber(`${+number + 1}`);
    }
    document.removeEventListener("keydown", handleListener);
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleListener);
    fetchPatrimony();
    // eslint-disable-next-line
  }, [number]);

  return (
    <>
      <RootContainer>
        <TitleContainer>
          <PageTitle>Cadastrar coordenadas de patrimônios</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <div className="mb-1 col-12 row p-0 m-0">
            <div className="col-12 mb-3">
              <label>Módulo</label>
              <select
                className="form-control"
                value={module}
                onChange={(e) => {
                  setModule(e.target.value);
                }}
                required
              >
                <option value={""} disabled>
                  Selecionar
                </option>
                {modules.map((module) => (
                  <option key={module} value={module}>
                    {module}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 mb-3">
              <label>Tipo</label>
              <select
                className="form-control"
                value={addressType}
                onChange={(e) => {
                  setAddressType(e.target.value);
                }}
                disabled={!module}
                required
              >
                <option value={""} disabled>
                  Selecionar
                </option>
                <option value={"street"}>Rua</option>
                <option value={"block"}>Bloco</option>
              </select>
            </div>
            {addressType === "street" && (
              <div className="col-12 mb-3">
                <label>Rua</label>
                <select
                  className="form-control"
                  value={street}
                  onChange={(e) => {
                    setStreet(e.target.value);
                    setBlock("");
                  }}
                  disabled={!addressType}
                  required
                >
                  <option value={""} disabled>
                    Selecionar
                  </option>
                  {streets.map((street) => (
                    <option key={street} value={street}>
                      {street}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {addressType === "block" && (
              <div className="col-12 mb-3">
                <label>Bloco</label>
                <select
                  className="form-control"
                  value={block}
                  onChange={(e) => {
                    setBlock(e.target.value);
                    setStreet("");
                  }}
                  disabled={!addressType}
                  required
                >
                  <option value={""} disabled>
                    Selecionar
                  </option>
                  {blocks.map((block) => (
                    <option key={block} value={block}>
                      {block}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="col-12 mb-3">
              <label>Número</label>
              <input
                className="form-control"
                value={number}
                type={"number"}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
                disabled={!street && !block}
                required
              />
            </div>
            <div className="col-12 mb-3">
              <label>Tipo de est.</label>
              <select
                className="form-control"
                value={buildingType}
                onChange={(e) => {
                  setBuildingType(e.target.value);
                }}
                disabled={!number}
                required
              >
                <option value={""} disabled>
                  Selecionar
                </option>
                <option value={"store"}>Loja</option>
                <option value={"box"}>Box</option>
              </select>
            </div>
            <div className="col-6 mb-3">
              <label>Latitude</label>
              <input className="form-control" value={latitude} required />
            </div>
            <div className="col-6 mb-3">
              <label>Longitude</label>
              <input className="form-control" value={longitude} required />
            </div>
            {patrimony ? (
              <div className={""}>
                Patrimônio selecionado: {patrimony.module} - {patrimony.street} - {patrimony.number}
              </div>
            ) : null}
            <div className={"d-flex align-items-end"}>
              {patrimony ? (
                <button
                  className={"btn btn-primary"}
                  onClick={() => {
                    assignCoordinates();
                  }}
                >
                  Salvar coordenadas
                </button>
              ) : loadingPatrimony ? (
                <div>Buscando...</div>
              ) : (
                <button
                  className={"btn btn-outline-secondary"}
                  disabled={!module || !addressType || !number || !buildingType}
                  onClick={() => {
                    fetchPatrimony();
                  }}
                >
                  Buscar
                </button>
              )}
              <button onClick={() => clear()} className="btn btn-outline-primary ms-2">
                Limpar
              </button>
            </div>
          </div>
        </ContentContainer>
      </RootContainer>
    </>
  );
};

export default MapForm;
