import * as React from "react";
import Api from "@services/Api";
import { toastError } from "@util/functions";
import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";
import MetricCard from "@components/ui/MetricCard";
import { Subtitle, Title } from "@components/ui/Typography";
import moment from "moment";
import { DateRange } from "react-date-range";
import { Modal } from "react-responsive-modal";
import { pt } from "date-fns/locale";
import FakeLink from "@components/ui/FakeLink";
import { PlanSalesMetrics, PlanStoresMetrics } from "@util/interfaces";

const DashboardScreen: React.FC = () => {
  const [subscriptionsStartDate, setSubscriptionsStartDate] = React.useState(moment().startOf("month"));
  const [subscriptionsEndDate, setSubscriptionsEndDate] = React.useState(moment());
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  const [dateSelectStarted, setDateSelectStarted] = React.useState(false);
  const [plansStoresMetrics, setPlansStoresMetrics] = React.useState<PlanStoresMetrics[]>([]);
  const [loadingTotalMetrics, setLoadingTotalMetrics] = React.useState(true);
  const [plansSalesMetrics, setPlansSalesMetrics] = React.useState<PlanSalesMetrics[]>([]);
  const [loadingPlansMetrics, setLoadingPlansMetrics] = React.useState(true);

  React.useEffect(() => {
    fetchPlansStoresMetrics();
  }, []);

  React.useEffect(() => {
    if (!dateSelectStarted) {
      fetchPlansSalesMetrics();
    }
    // eslint-disable-next-line
  }, [subscriptionsStartDate, subscriptionsEndDate]);

  const fetchPlansStoresMetrics = async () => {
    try {
      setLoadingTotalMetrics(true);
      const { data } = await Api.get(`/metrics/plans_total`);
      const plans = data.plans as PlanStoresMetrics[];
      const total = plans.reduce((acc, curr) => acc + curr.stores_count, 0);
      setPlansStoresMetrics([{ id: 0, name: "Total", stores_count: total }, ...plans]);
      setLoadingTotalMetrics(false);
    } catch (error) {
      setLoadingTotalMetrics(false);
      toastError(error);
    }
  };

  const fetchPlansSalesMetrics = async () => {
    try {
      const url = `/metrics/sales?startDate=${subscriptionsStartDate.format(
        `DD/MM/YYYY`
      )}&endDate=${subscriptionsEndDate.format(`DD/MM/YYYY`)}`;
      const { data } = await Api.get(url);
      const plans = data.plans as PlanSalesMetrics[];
      const total = plans.reduce((acc, curr) => acc + curr.sales_count, 0);
      setPlansSalesMetrics([{ id: 0, name: "Total", sales_count: total }, ...plans]);
      setLoadingPlansMetrics(false);
    } catch (error) {
      setLoadingPlansMetrics(false);
      toastError(error);
    }
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleDateChange = (values: any) => {
    if (dateSelectStarted) {
      setDateSelectStarted(false);
      setIsDatePickerOpen(false);
    } else {
      setDateSelectStarted(true);
    }
    setSubscriptionsStartDate(moment(values.selection.startDate));
    setSubscriptionsEndDate(moment(values.selection.endDate));
  };

  const selectionRange = {
    startDate: subscriptionsStartDate.toDate(),
    endDate: subscriptionsEndDate.toDate(),
    key: "selection",
  };

  const LoadingMetricCard = () => (
    <div className="col-6 col-lg-3 mt-2 mt-lg-0">
      <MetricCard label="..." number={``} loading={true} />
    </div>
  );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Dashboard</PageTitle>
      </TitleContainer>
      <ContentContainer
        style={{
          boxShadow: "none",
          backgroundColor: "transparent",
          overflowX: "hidden",
          border: "none",
        }}
      >
        <div className="row g-3">
          <div className="col-12 px-3 mb-2">
            <Title>Total</Title>
            <br />
            <Subtitle>Estabtaaelecimento que estão ativos neste momento</Subtitle>
          </div>
          {loadingTotalMetrics && <LoadingMetricCard />}
          {plansStoresMetrics.map((plan) => (
            <div className="col-6 col-lg-3" key={plan.id}>
              <MetricCard label={plan.name} number={`${plan.stores_count}`} />
            </div>
          ))}
        </div>
        <div className="row g-3">
          <div className="col-12 px-3 mt-5">
            <Title>Vendas</Title>
            <br />
            <div className="d-flex flex-row align-items-end">
              <div style={{ marginRight: 10 }}>
                <Subtitle>
                  Vendas feitas entre <b>{subscriptionsStartDate.format("DD/MM/YYYY")}</b> e{" "}
                  <b>{subscriptionsEndDate.format("DD/MM/YYYY")}</b>{" "}
                  <FakeLink onClick={toggleDatePicker}>mudar</FakeLink>
                </Subtitle>
              </div>
            </div>
          </div>
          {loadingPlansMetrics && (
            <>
              <div className="col-6 col-lg-3">
                <MetricCard label={"..."} number={``} loading={loadingPlansMetrics} />
              </div>
            </>
          )}
          {plansSalesMetrics.map((plan) => (
            <div className="col-6 col-lg-3" key={plan.id}>
              <MetricCard label={plan.name} number={`${plan.sales_count}`} loading={loadingPlansMetrics} />
            </div>
          ))}
        </div>
      </ContentContainer>

      <Modal
        open={isDatePickerOpen}
        onClose={() => {
          setIsDatePickerOpen(false);
        }}
        center
      >
        <div className="pt-4">
          <DateRange locale={pt} ranges={[selectionRange]} onChange={handleDateChange} rangeColors={["#9841e2"]} />
        </div>
      </Modal>
    </RootContainer>
  );
};

export default DashboardScreen;
