import * as React from "react";
import styled from "styled-components";

const Button = styled.button`
  outline: none;
  background: none;
  border: none;
  color: #9841e2;
  padding: 0;
  margin: 0;
  height: auto;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FakeLink: React.FC<React.ButtonHTMLAttributes<HTMLElement>> = (props) => {
  return <Button {...props} />;
};

export default FakeLink;
