import * as React from "react";
import GoogleMapReact from "google-map-react";
import Map from "@components/ui/Map";
import { LocationCategoryModal } from "./LocationCategoryModal";
import { useLocationCategories } from "./hooks/useLocationCategories";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";

interface LocationFormValues {
  locationCategoryId: string;
  lat: number;
  lng: number;
  name: string;
  description?: string;
  url?: string;
  color?: string;
}

const LocationsPage: React.FC = () => {
  const [currentLocation, setCurrentLocation] = React.useState<{
    lat: number;
    lng: number;
  }>();

  const [isCategoryModalOpen, setIsCategoryModalOpen] = React.useState(false);

  const { locationCategories, fetchLocationCategories } = useLocationCategories();

  const handleMapClick = (event: GoogleMapReact.ClickEventValue) => {
    setCurrentLocation({ lat: event.lat, lng: event.lng });
  };

  const handleLocationCategoryModalClose = () => {
    setIsCategoryModalOpen(false);
    setCurrentLocation(undefined);
    fetchLocationCategories();
  };

  const handleSubmit = async (values: LocationFormValues, actions: FormikHelpers<LocationFormValues>) => {
    actions.setSubmitting(true);
    try {
      await Api.post(`/location`, {
        name: values.name,
        description: values.description,
        url: values.url,
        color: values.color,
        lat: currentLocation?.lat ?? "",
        lng: currentLocation?.lng ?? "",
        location_category_id: values.locationCategoryId,
      });
      toastMessage("Ponto criado com sucesso!", "success");
    } catch (error) {
      toastError(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const initialValues: LocationFormValues = {
    locationCategoryId: "",
    lat: currentLocation?.lat || 0,
    lng: currentLocation?.lng || 0,
    name: "",
    description: "",
    url: "",
    color: "",
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: "40vw", height: "100vh" }}>
        <RootContainer>
          <TitleContainer>
            <PageTitle>Pontos avulsos</PageTitle>
          </TitleContainer>
          <ContentContainer className={"mt-4"}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-1 col-12 row p-0 m-0">
                    <div className="col-12 mb-3">
                      <label>Categoria</label>
                      <a href="#" className="ms-2" onClick={() => setIsCategoryModalOpen(true)}>
                        Adicionar categoria
                      </a>
                      <Field as="select" name="locationCategoryId" className="form-control" required>
                        <option disabled value="">
                          Selecione uma categoria
                        </option>
                        {locationCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="col-12 mb-3">
                      <label>Nome do ponto</label>
                      <Field className="form-control" name="name" required />
                    </div>
                    <div className="col-12 mb-3">
                      <label>Descrição do ponto</label>
                      <Field className="form-control" name="description" required />
                    </div>
                    <div className="col-12 mb-3">
                      <label>Cor do ponto (em hexadecimal)</label>
                      <Field className="form-control" name="color" required />
                    </div>
                    <div className="col-12 mb-3">
                      <label>Link</label>
                      <Field className="form-control" name="url" required />
                    </div>
                    <div className="col-6 mb-3">
                      <label>Latitude</label>
                      <input className="form-control" value={currentLocation?.lat ?? ""} required />
                    </div>
                    <div className="col-6 mb-3">
                      <label>Longitude</label>
                      <input className="form-control" value={currentLocation?.lng ?? ""} required />
                    </div>
                  </div>
                  <button type="submit" className="btn btn-outline-primary ms-2" disabled={isSubmitting}>
                    Enviar
                  </button>
                </Form>
              )}
            </Formik>
          </ContentContainer>
        </RootContainer>
      </div>
      <div style={{ width: "60vw", height: "100vh", padding: 10 }}>
        <div
          style={{
            height: "100%",
            borderStyle: "solid",
            borderWidth: 3,
            borderColor: "#9841e2",
            borderRadius: 8,
            overflow: "hidden",
          }}
        >
          <Map handleMapClick={handleMapClick} />
        </div>
      </div>
      <LocationCategoryModal isOpen={isCategoryModalOpen} onClose={handleLocationCategoryModalClose} />
    </div>
  );
};

export default LocationsPage;
