import { SelectOption } from "@util/interfaces";
import * as React from "react";
import ReactSelect, { Props } from "react-select";
import { reactSelectStyles, reactSelectTheme } from "./configs";

const Select: React.FC<Props<SelectOption, boolean>> = (props) => {
  return (
    <ReactSelect theme={reactSelectTheme} menuPortalTarget={document.body} styles={reactSelectStyles} {...props} />
  );
};

export default Select;
