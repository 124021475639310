import Api from "@services/Api";
import { AddressType, Patrimony, Store } from "@util/interfaces";
import { confirmAlert } from "react-confirm-alert";
import * as React from "react";
import { toastError, toastMessage } from "@util/functions";

const modules = ["verde", "azul", "amarelo", "laranja", "vermelho", "branco"];
const streets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P"];
const blocks = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

const PatrimoniesForm: React.FC<{ store: Store; onSuccess: () => void }> = ({ store, onSuccess }) => {
  // New patrimony form state
  const [module, setModule] = React.useState("");
  const [addressType, setAddressType] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [block, setBlock] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [buildingType, setBuildingType] = React.useState("");
  const [loadingPatrimony, setLoadingPatrimony] = React.useState(false);
  const [patrimony, setPatrimony] = React.useState<Patrimony>();

  React.useEffect(() => {
    setPatrimony(undefined);
  }, [module, addressType, street, block, number, buildingType]);

  const clear = () => {
    setModule("");
    setAddressType("");
    setStreet("");
    setBlock("");
    setNumber("");
    setBuildingType("");
    setPatrimony(undefined);
  };

  const fetchPatrimony = async () => {
    if (!module || !addressType || !number || !buildingType) return;
    if (addressType === AddressType.BLOCK && !block) return;
    if (addressType === AddressType.STREET && !street) return;
    setLoadingPatrimony(true);
    try {
      const { data } = await Api.get(
        `/patrimony?module=${module}&address_type=${addressType}&street=${street}&block=${block}&number=${number}&building_type=${buildingType}`
      );
      if (!data.patrimonies[0]) {
        toastError("Nenhum patrimônio encontrado");
        setLoadingPatrimony(false);
        return;
      }
      setPatrimony(data.patrimonies[0]);
      setLoadingPatrimony(false);
    } catch (error) {
      setLoadingPatrimony(false);
      console.log(error);
    }
  };

  const handleAssignStoreToPatrimony = async () => {
    if (!patrimony) return;
    confirmAlert({
      title: "Atenção",
      message: `Deseja associar esta loja ao patrimônio?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.post(`/patrimony/${patrimony.id}/assign_store`, {
                store_id: store.id,
              });
              toastMessage("Loja associada ao patrimônio", "success");
              onSuccess();
              clear();
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDetachButtonClick = (patrimony: Patrimony) => {
    confirmAlert({
      title: "Atenção",
      message: "Deseja desassociar esta loja deste patrimônio?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.post(`/patrimony/${patrimony.id}/detach_store`, {
                store_id: store.id,
              });
              toastMessage("Patrimônio desassociado", "success");
              onSuccess();
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <>
      {store.patrimonies.map((patrimony: Patrimony) => (
        <div className="mb-1 col-12 row p-0 m-0" key={patrimony.id}>
          <div className="col-md-2">
            <label>Módulo</label>
            <select className="form-control" value={patrimony.module} disabled>
              <option value={""} disabled>
                Selecionar
              </option>
              {modules.map((module) => (
                <option key={module} value={module}>
                  {module}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <label>Tipo</label>
            <select className="form-control" value={patrimony.address_type} disabled>
              <option value={""} disabled>
                Selecionar
              </option>
              <option value={"street"}>Rua</option>
              <option value={"block"}>Bloco</option>
            </select>
          </div>
          {patrimony.address_type === "street" && (
            <div className="col-md-2">
              <label>Rua</label>
              <select className="form-control" value={patrimony.street} disabled>
                <option value={""} disabled>
                  Selecionar
                </option>
                {streets.map((street) => (
                  <option key={street} value={street}>
                    {street}
                  </option>
                ))}
              </select>
            </div>
          )}
          {patrimony.address_type === "block" && (
            <div className="col-md-2">
              <label>Bloco</label>
              <select className="form-control" value={patrimony.block} disabled>
                <option value={""} disabled>
                  Selecionar
                </option>
                {blocks.map((block) => (
                  <option key={block} value={block}>
                    {block}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="col-md-2">
            <label>Número</label>
            <input className="form-control" value={patrimony.number} disabled />
          </div>
          <div className="col-md-2">
            <label>Tipo de est.</label>
            <select className="form-control" value={patrimony.building_type} disabled>
              <option value={""} disabled>
                Selecionar
              </option>
              <option value={"store"}>Loja</option>
              <option value={"box"}>Box</option>
            </select>
          </div>
          <div className={"col-md-2 d-flex align-items-end"}>
            <button
              className={"btn btn-outline-danger"}
              onClick={(e) => {
                e.preventDefault();
                handleDetachButtonClick(patrimony);
              }}
            >
              <i className="fas fa-trash-alt" />
              <span>desassociar</span>
            </button>
          </div>
        </div>
      ))}
      <div className="mb-1 col-12 row p-0 m-0">
        <div className="col-md-2">
          <label>Módulo</label>
          <select
            className="form-control"
            value={module}
            onChange={(e) => {
              setModule(e.target.value);
            }}
          >
            <option value={""} disabled>
              Selecionar
            </option>
            {modules.map((module) => (
              <option key={module} value={module}>
                {module}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2">
          <label>Tipo</label>
          <select
            className="form-control"
            value={addressType}
            onChange={(e) => {
              setAddressType(e.target.value);
            }}
            disabled={!module}
          >
            <option value={""} disabled>
              Selecionar
            </option>
            <option value={"street"}>Rua</option>
            <option value={"block"}>Bloco</option>
          </select>
        </div>
        {addressType === "street" && (
          <div className="col-md-2">
            <label>Rua</label>
            <select
              className="form-control"
              value={street}
              onChange={(e) => {
                setStreet(e.target.value);
                setBlock("");
              }}
              disabled={!addressType}
            >
              <option value={""} disabled>
                Selecionar
              </option>
              {streets.map((street) => (
                <option key={street} value={street}>
                  {street}
                </option>
              ))}
            </select>
          </div>
        )}
        {addressType === "block" && (
          <div className="col-md-2">
            <label>Bloco</label>
            <select
              className="form-control"
              value={block}
              onChange={(e) => {
                setBlock(e.target.value);
                setStreet("");
              }}
              disabled={!addressType}
            >
              <option value={""} disabled>
                Selecionar
              </option>
              {blocks.map((block) => (
                <option key={block} value={block}>
                  {block}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="col-md-2">
          <label>Número</label>
          <input
            className="form-control"
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
            disabled={!street && !block}
          />
        </div>
        <div className="col-md-2">
          <label>Tipo de est.</label>
          <select
            className="form-control"
            value={buildingType}
            onChange={(e) => {
              setBuildingType(e.target.value);
            }}
            disabled={!number}
          >
            <option value={""} disabled>
              Selecionar
            </option>
            <option value={"store"}>Loja</option>
            <option value={"box"}>Box</option>
          </select>
        </div>
        <div className={"col-md-2 d-flex align-items-end"}>
          {patrimony ? (
            <button
              className={"btn btn-outline-primary"}
              onClick={(e) => {
                e.preventDefault();
                handleAssignStoreToPatrimony();
              }}
            >
              Associar
            </button>
          ) : loadingPatrimony ? (
            <div>Buscando...</div>
          ) : (
            <button
              className={"btn btn-outline-secondary"}
              disabled={!module || !addressType || !number || !buildingType}
              onClick={() => {
                fetchPatrimony();
              }}
            >
              Buscar
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default PatrimoniesForm;
