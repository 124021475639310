import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import PaymentStatusPill from "@components/ui/PaymentStatusPill";
import ManualPaymentConfirmationForm from "../ManualPaymentConfirmation";
import Api from "@services/Api";
import { getInt, toastError, toastMessage } from "@util/functions";
import { PaymentMethod, PaymentStatus, Sale, SalePayment } from "@util/interfaces";
import Money from "@util/money";
import moment from "moment";
import * as React from "react";
import DataTable from "react-data-table-component";
import { TableColumn } from "react-data-table-component";
import { Modal } from "react-responsive-modal";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CopyToClipboard from "@util/clipboard";

type SalePaymentsListProps = {
  sale_id?: string;
};

const userRole = localStorage.getItem("user_role");

const clmns: TableColumn<SalePayment>[] = [
  {
    name: "id",
    selector: (payment: SalePayment) => payment.id,
    sortable: true,
    grow: 0,
    minWidth: "80px",
  },
  {
    name: "Valor",
    selector: (payment: SalePayment) => payment.value,
    format: (payment: SalePayment) => Money({ amount: getInt(payment.value) }).toFormat(),
    sortable: true,
  },
  {
    name: "Data de pagamento",
    selector: (payment: SalePayment) => new Date(payment.paid_at ?? Date.now()).getTime(),
    format: (payment: SalePayment) => (payment.paid_at ? moment(payment.paid_at).format("DD/MM/YYYY") : "-"),
    sortable: true,
  },
  {
    name: "Data de expiração",
    selector: (payment: SalePayment) => new Date(payment.expires_at ?? Date.now()).getTime(),
    format: (payment: SalePayment) => (payment.expires_at ? moment(payment.expires_at).format("DD/MM/YYYY") : "-"),
    sortable: true,
  },
  {
    name: "Situação",
    selector: (payment: SalePayment) => payment.status,
    cell: (payment: SalePayment) => <PaymentStatusPill payment={payment} />,
    sortable: true,
  },
  {
    name: "Ações",
    selector: (payment: SalePayment) => payment.id,
    cell: (payment: SalePayment) => (
      <div className={"d-flex"}>
        {payment.emepag_order_id && (
          <CopyToClipboard
            text={`https://link.emepag.com.br/${payment.emepag_order_id}`}
            onCopy={() => {
              toastMessage("Link copiado", "success");
            }}
            className={"btn btn-outline-primary rounded-pill btn-sm ms-1"}
          >
            <span>Copiar link</span>
          </CopyToClipboard>
        )}
        {payment.status !== PaymentStatus.PAID && userRole === "admin" && (
          <button
            data-tag="allowRowEvents"
            data-id="manualPaymentButton"
            className="btn btn-outline-primary rounded-pill btn-sm"
          >
            Confirmar pagamento
          </button>
        )}
      </div>
    ),
    compact: true,
    width: "260px",
  },
];

const PaymentsListScreen: React.FC = () => {
  const { sale_id } = useParams<SalePaymentsListProps>();
  const [loadingPayments, setLoadingPayments] = React.useState(true);
  const [payments, setPayments] = React.useState<SalePayment[]>([]);
  const [sale, setSale] = React.useState<Sale>();
  const [loadingSale, setLoadingSale] = React.useState(true);
  const [isManualPaymentConfirmationModalOpen, setisManualPaymentConfirmationModalOpen] = React.useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = React.useState<number>();
  const [selectedPaymentExpirationDate, setSelectedPaymentExpirationDate] = React.useState<Date>();
  const [observationsValue, setObservationsValue] = React.useState("");

  const paymentsSum = payments.reduce(
    (prev, curr) =>
      Money({ amount: getInt(prev) })
        .add(Money({ amount: getInt(curr.value) }))
        .toUnit(),
    0
  );

  React.useEffect(() => {
    fetchPayments({});
    fetchSale();
    ReactTooltip.rebuild();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (sale?.observations) {
      setObservationsValue(sale.observations);
    }
  }, [sale]);

  React.useEffect(() => {
    if (payments.length > 0) {
      ReactTooltip.rebuild();
    }
  }, [payments]);

  const handleObservationsUpdate = async () => {
    try {
      await Api.put(`/sale/${sale_id}`, { observations: observationsValue });
      toastMessage("Observações atualizadas", "success");
    } catch (error) {
      toastError(error);
    }
  };

  const fetchPayments = async (query: any) => {
    try {
      const { data } = await Api.get(`/payment`, { params: { sale_id } });
      setPayments(data.payments);
      setLoadingPayments(false);
    } catch (error) {
      toastError(error);
    }
  };

  const fetchSale = async () => {
    try {
      const { data } = await Api.get(`/sale/id/${sale_id}`);
      setSale(data.sale);
      setLoadingSale(false);
    } catch (error) {
      toastError(error);
    }
  };

  // Using any as event type because we can't access the attribute data-id with the correct event type
  const handleTableRowClick = (row: SalePayment, event: any) => {
    if (event.target.attributes["data-id"]?.value === "manualPaymentButton") {
      setSelectedPaymentId(row.id);
      setSelectedPaymentExpirationDate(row.expires_at ? new Date(row.expires_at) : undefined);
      setisManualPaymentConfirmationModalOpen(true);
    }
  };

  if (loadingPayments || !sale || loadingSale)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Pagamentos da venda {sale_id}</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Pagamentos da venda {sale_id}</PageTitle>
        <Link to={`/vendas/${sale_id}/pagamentos/cadastro`} className="btn btn-primary">
          + novo pagamento
        </Link>
      </TitleContainer>
      <ContentContainer style={{ maxHeight: 150, minHeight: 150 }}>
        <div className={"p-2 text-muted"}>
          <h5>Observações da venda</h5>
        </div>
        <div>
          <textarea
            className={"form-control"}
            style={{ minHeight: 75 }}
            value={observationsValue}
            onChange={(e) => {
              setObservationsValue(e.target.value);
            }}
            onBlur={handleObservationsUpdate}
          />
        </div>
      </ContentContainer>
      <ContentContainer className={"mt-3"} style={{ maxHeight: "calc(100vh - 245px)" }}>
        <div className={"p-2 text-muted"}>
          <h5>
            Seus pagamentos somam {Money({ amount: getInt(paymentsSum) }).toFormat()} de{" "}
            {Money({ amount: getInt(sale.value) }).toFormat()}
          </h5>
        </div>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loadingPayments}
          columns={clmns}
          data={payments}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          responsive
          onRowClicked={handleTableRowClick}
        />
      </ContentContainer>
      <Modal
        styles={{ modal: { overflow: "unset" } }}
        open={isManualPaymentConfirmationModalOpen}
        onClose={() => {
          setisManualPaymentConfirmationModalOpen(false);
        }}
        center
      >
        <ManualPaymentConfirmationForm
          onFinish={() => {
            setisManualPaymentConfirmationModalOpen(false);
            fetchPayments({});
          }}
          paymentId={selectedPaymentId}
          expiresAt={selectedPaymentExpirationDate}
        />
      </Modal>
      <ReactTooltip multiline={true} />
    </RootContainer>
  );
};

export default PaymentsListScreen;
