import { ErrorMessage, Field } from "formik";
import * as React from "react";
import InputMask, { Props } from "react-input-mask";

interface FormikTextInputProps extends React.InputHTMLAttributes<HTMLInputElement>, Props {
  label: string;
  name: string;
  mask: string;
}

interface FormikFieldProps {
  field: Props;
}

const FormikTextInput: React.FC<FormikTextInputProps> = (props) => {
  return (
    <React.Fragment>
      <Field {...props}>
        {({ field }: FormikFieldProps) => {
          return (
            <div>
              <label>{props.label ? `${props.label}${props.required ? " *" : ""}` : ""}</label>
              <InputMask
                className={"form-control"}
                placeholder=" "
                {...field}
                {...props}
                style={{
                  outline: "none",
                  border: "1px solid #B6B6B6",
                  width: "100%",
                  height: "40px",
                  borderRadius: "8px",
                  padding: "10px 15px",
                  ...props.style,
                }}
              />
            </div>
          );
        }}
      </Field>
      <ErrorMessage className="text-danger mt-1" component="span" name={props.name ?? ""} />
    </React.Fragment>
  );
};

export default FormikTextInput;
