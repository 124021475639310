import * as React from "react";
import Card from "@components/ui/Card";
import {
	ContentContainer,
	LoadingIndicator,
	PageTitle,
	RootContainer,
	TitleContainer,
} from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { LiveOptions, SelectOption, Store } from "@util/interfaces";
import Styled from "./styled";
import AsyncSelect from "@components/ui/Select/Async";
import { confirmAlert } from "react-confirm-alert";

const LiveOptionsPage: React.FC = () => {
	const [liveOptions, setLiveOptions] = React.useState<LiveOptions>();
	const [fetchingData, setFetchingData] = React.useState(true);
	const [selectedStoreId, setSelectedStoreId] = React.useState();
	const [videoUrlInputValue, setVideoUrlInputValue] = React.useState("");

	const handleToggleLive = async () => {
		if (!liveOptions?.video_id) return toastError("Você precisa fornecer a URL da live primeiro.");
		try {
			await Api.post(`/live_options/toggle_live`);
			toastMessage(`Live ${liveOptions?.is_on ? "Desativada" : "Ativada"}`, "success");
			fetchLiveOptions();
		} catch (error) {
			toastError(error);
		}
	};

	const fetchLiveOptions = async () => {
		setFetchingData(true);
		try {
			const { data } = await Api.get(`/live_options`);
			setLiveOptions(data.liveOptions);
			setFetchingData(false);
		} catch (error) {
			toastError(error);
		}
	};

	const fetchStores = async (inputValue: string): Promise<SelectOption[]> => {
		try {
			const { data } = await Api.get(`/store?name=${inputValue}`);
			return data.stores?.map?.((store: Store) => ({ value: store.id, label: store.name })) || [];
		} catch (error) {
			toastError(error);
			return [];
		}
	};

	const handleStoreChange = async () => {
		try {
			await Api.post(`/live_options/change_active_store`, { store_id: selectedStoreId });
			fetchLiveOptions();
			toastMessage(`Loja ativa atualizada com sucesso!`, "success");
		} catch (error) {
			toastError(error);
		}
	};

	const handleVideoUrlChange = async () => {
		try {
			await Api.post(`/live_options/change_video_id`, { video_id: videoUrlInputValue });
			fetchLiveOptions();
			toastMessage(`URL da live atualizada com sucesso!`, "success");
		} catch (error) {
			toastError(error);
		}
	};

	React.useEffect(() => {
		fetchLiveOptions();
	}, []);

	React.useEffect(() => {
		if (selectedStoreId !== undefined) {
			confirmAlert({
				title: "Atenção!",
				message: "Esta loja ficará disponível no app para todos os usuários. Deseja continuar?",
				buttons: [
					{
						label: "Sim",
						onClick: () => {
							handleStoreChange();
						},
					},
					{
						label: "Não",
						onClick: () => {
							return;
						},
					},
				],
			});
		}
		// eslint-disable-next-line
	}, [selectedStoreId]);

	if (fetchingData) {
		return (
			<RootContainer>
				<TitleContainer>
					<PageTitle>Configurações da Live</PageTitle>
				</TitleContainer>
				<ContentContainer>
					<LoadingIndicator />
				</ContentContainer>
			</RootContainer>
		);
	}

	return (
		<RootContainer>
			<TitleContainer>
				<PageTitle>Configurações da Live</PageTitle>
			</TitleContainer>
			<div className={"row"} style={{ padding: 17 }}>
				<div className={"col-md-4"}>
					<Card>
						<div>Status da live:</div>
						<Styled.LiveStatusText color={liveOptions?.is_on ? "#fa3c3c" : "black"}>
							{liveOptions?.is_on ? "ONLINE" : "OFFLINE"}
						</Styled.LiveStatusText>
						<div>
							{liveOptions?.is_on ? (
								<button className={"btn btn-outline-danger mt-3"} onClick={handleToggleLive}>
									Desativar
								</button>
							) : (
								<button className={"btn btn-outline-primary mt-3"} onClick={handleToggleLive}>
									Ativar
								</button>
							)}
						</div>
					</Card>
				</div>
				<div className={"col-md-4"}>
					<Card>
						<div>Empresa ativa:</div>
						<Styled.LiveStatusText color={"black"}>
							{liveOptions?.active_store ? liveOptions.active_store.name : "Nenhuma loja ativa"}
						</Styled.LiveStatusText>
						<div className={"mt-3 w-100"}>
							<AsyncSelect
								placeholder="Buscar loja"
								loadingMessage={() => "Buscando..."}
								noOptionsMessage={() => "Sem resultados"}
								cacheOptions
								loadOptions={fetchStores}
								onChange={(option: any) => {
									setSelectedStoreId(option?.value);
								}}
							/>
						</div>
					</Card>
				</div>
				<div className={"col-md-4"}>
					<Card>
						<div>Link da live:</div>
						<Styled.LiveStatusText color={"black"}>{liveOptions?.video_id}</Styled.LiveStatusText>
						<div className={"mt-3 w-100 d-flex justify-content-center"}>
							<input
								className={"form-control w-100"}
								value={videoUrlInputValue}
								onChange={(e) => {
									setVideoUrlInputValue(e.target.value);
								}}
							/>
							<button
								className={"btn btn-outline-primary ms-2"}
								disabled={!videoUrlInputValue}
								onClick={handleVideoUrlChange}
							>
								Trocar
							</button>
						</div>
					</Card>
				</div>
			</div>
		</RootContainer>
	);
};

export default LiveOptionsPage;
