import * as React from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";
import { Sale } from "@util/interfaces";
import { getBookletSaleStatus, getSalePeriodName, getSaleTypeName, toastError, toastMessage } from "@util/functions";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@redux/index";
import { fetchSales, setSalesQuery } from "@redux/AppData.store";
import { confirmAlert } from "react-confirm-alert";
import Api from "@services/Api";
import BookletSaleStatusPill from "@components/ui/BookletPaymentStatusPill";
import BookletSalesFilters from "./filters";

const columns: TableColumn<Sale>[] = [
  {
    name: "Marca",
    selector: (row: Sale) => row.store?.name ?? "Estab. excluído",
    sortable: true,
  },
  {
    name: "Plano",
    selector: (row: Sale) => `${row.plan.name} - ${getSalePeriodName(row.period)}`,
    sortable: true,
  },
  {
    name: "Vendedor",
    selector: (row: Sale) => row.seller.name,
    sortable: true,
  },
  {
    name: "Tipo de venda",
    selector: (row: Sale) => getSaleTypeName(row.type),
    sortable: true,
  },
  {
    name: "Vencimento do Último Pagamento",
    selector: (row: Sale) => {
      const paidPayments = row.payments?.filter((payment) => payment.status === "paid");
      if (!paidPayments || paidPayments.length === 0) {
        return "-";
      }
      const latestPaidPayment = paidPayments.reduce((latest, current) => {
        return new Date(latest.expires_at) > new Date(current.expires_at) ? latest : current;
      });
      return new Date(latestPaidPayment.expires_at).toLocaleDateString("pt-BR");
    },
    sortable: true,
  },

  {
    name: "Situação ",
    cell: (row: Sale) => {
      const paidPayments = row.payments?.filter((payment) => payment.status === "paid");
      if (!paidPayments || paidPayments.length === 0) {
        return <div>-</div>;
      }

      const latestPaidPayment = paidPayments.reduce((latest, current) =>
        new Date(latest.expires_at) > new Date(current.expires_at) ? latest : current
      );
      const expiresAt = new Date(latestPaidPayment.expires_at);
      const today = new Date();
      const diffTime = Math.max(today.getTime() - expiresAt.getTime(), 0);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      const status = getBookletSaleStatus(expiresAt, today, diffDays);

      return <BookletSaleStatusPill status={status} diffDays={diffDays} />;
    },
    sortable: false,
  },

  {
    name: "Opções",
    selector: (sale: Sale) => sale.id,
    compact: true,
    width: "200px",
    cell: (row: Sale) => {
      const status = row.expired_at ? "expired" : row.expires_at ? "active" : "pending";
      return (
        <div className={"d-flex"}>
          <a
            href={`/vendas/${row.id}/pagamentos`}
            className={"btn btn-outline-primary rounded-pill btn-sm"}
            style={{ whiteSpace: "nowrap" }}
          >
            Pagamentos
          </a>
          <button
            data-tag="allowRowEvents"
            data-id="deleteButton"
            className={"btn btn-outline-danger rounded-pill btn-sm ms-1"}
            style={{ whiteSpace: "nowrap" }}
          >
            Excluir
          </button>
          {status === "pending" && (
            <button
              data-tag="allowRowEvents"
              data-id="cancelButton"
              className={"btn btn-outline-danger rounded-pill btn-sm ms-1"}
              style={{ whiteSpace: "nowrap" }}
            >
              Cancelar
            </button>
          )}
        </div>
      );
    },
  },
];

const BookletSalesListScreen: React.FC = () => {
  const { filteredSales, loadingSales } = useSelector((state: RootState) => state.appSettings);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setSalesQuery({ payment_method: "booklet" }));
    dispatch(fetchSales());
    // eslint-disable-next-line
  }, []);

  // Using any as event type because we can't access the attribute data-id with the correct event type
  const handleTableRowClick = (row: Sale, event: any) => {
    if (event.target.attributes["data-id"]?.value === "cancelButton") {
      handleStoreDelete(row.id);
    }
    if (event.target.attributes["data-id"]?.value === "deleteButton") {
      handleDeleteSaleWithPayments(row.id);
    }
  };

  const handleDeleteSaleWithPayments = async (saleId: number) => {
    confirmAlert({
      title: "Atenção",
      message:
        "Deseja excluir esta venda? Também serão excluídos todos os pagamentos que esta venda possuir. Esta ação não pode ser desfeita.",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/sale/${saleId}/sale_and_payments`);
              toastMessage("Venda excluída", "success");
              dispatch(fetchSales());
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handleStoreDelete = async (saleId: number) => {
    confirmAlert({
      title: "Atenção",
      message: "Deseja excluir esta venda? Esta ação não pode ser desfeita.",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/sale/${saleId}`);
              toastMessage("Venda excluída", "success");
              dispatch(fetchSales());
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  if (loadingSales)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Vendas no Carnê</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Vendas no Carnê</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <div className={"p-2 text-muted"}>
          <h5>Filtros</h5>
        </div>
        <BookletSalesFilters />
        <div className={"p-2 text-muted mt-2"}>
          <h5>Resultados </h5>
        </div>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loadingSales}
          columns={columns}
          data={filteredSales}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          responsive
          onRowClicked={handleTableRowClick}
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default BookletSalesListScreen;
