import * as React from "react";
import { PropsWithChildren } from "react";

interface MapsOverlayProps extends PropsWithChildren {
  lat: number;
  lng: number;
}

const MapOverlay: React.FC<MapsOverlayProps> = (props) => {
  return <div>{props.children}</div>;
};

export default MapOverlay;
