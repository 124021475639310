import { FilterSelect } from "@components/ui/FilterSelect";
import MetricCard from "@components/ui/MetricCard";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { getDaysInMonth, getInt, toastError } from "@util/functions";
import { SalePayment } from "@util/interfaces";
import Money from "@util/money";
import { monthsSelectOptions, yearsSelectOptions } from "@util/variables";
import moment from "moment";
import * as React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

const columns: TableColumn<SalePayment>[] = [
  {
    name: "Data",
    // The selector paid_at condition should never user Date.now() because API will not send not paid payments
    selector: (row) => new Date(row.paid_at ?? Date.now()).getTime(),
    format: (row) => moment(row.paid_at).format("DD/MM/YYYY"),
    sortable: true,
    id: 1,
  },
  {
    name: "Marca",
    selector: (row) => row.sale.store?.name ?? "Loja inexistente",
    sortable: true,
  },
  {
    name: "Valor",
    selector: (row) => row.value,
    format: (row) => Money({ amount: getInt(row.value) }).toFormat(),
    sortable: true,
  },
];

const SellerDashboard: React.FC = () => {
  const [loadingMetrics, setLoadingMetrics] = React.useState(true);
  const [selectedMonth, setSelectedMonth] = React.useState((new Date().getMonth() + 1).toString());
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear().toString());
  const [payments, setPayments] = React.useState<SalePayment[]>([]);

  React.useEffect(() => {
    fetchMetrics();
    // eslint-disable-next-line
  }, [selectedMonth, selectedYear]);

  React.useEffect(() => {
    console.log(payments, "pagamentos");
  }, [payments]);

  const fetchMetrics = async () => {
    setLoadingMetrics(true);
    try {
      const { data } = await Api.get(`/metrics/sellers_payments`, {
        params: { month: selectedMonth, year: selectedYear },
      });
      const bookletPayments = data.payments.filter((payment: SalePayment) => payment.payment_method === "booklet");
      const nonBookletPayments = data.payments.filter((payment: SalePayment) => payment.payment_method !== "booklet");

      const bookletPaymentsWithSaleValue: SalePayment[] = bookletPayments.map((payment: SalePayment) => {
        payment.payment_method === "booklet" && (payment.value = payment.sale.value);
        return payment;
      });

      const uniqueBookletPayments = bookletPaymentsWithSaleValue.filter(
        (payment, index, self) => index === self.findIndex((p) => p.sale.id === payment.sale.id)
      );

      const uniqueBookletFirstPayments = uniqueBookletPayments.filter((payment: SalePayment) => {
        if (payment.paid_at) {
          const paidMonth = new Date(payment.paid_at).getUTCMonth();
          const createdMonth = new Date(payment.sale.created_at).getUTCMonth();
          return paidMonth === createdMonth;
        }
      });

      console.log(uniqueBookletPayments, "pagamentos unicos");

      const combinedPayments = [...uniqueBookletFirstPayments, ...nonBookletPayments];
      setPayments(combinedPayments);
      setLoadingMetrics(false);
    } catch (error) {
      setLoadingMetrics(false);
      toastError(error);
    }
  };

  const buildSalesChartData = () => {
    const days = getDaysInMonth(+selectedMonth, +selectedYear);
    const holeMonthData: { value: number; date: Date }[] = days.map((day) => {
      return {
        date: day,
        value: payments
          .filter((payment) => moment(payment.paid_at).format("DD/MM/YYYY") === moment(day).format("DD/MM/YYYY"))
          .reduce((acc, curr) => acc + curr.value, 0),
      };
    });
    let paymentsData: { value: number; date: Date }[] = [];
    for (let i = 0; i < holeMonthData.length; i++) {
      const data = holeMonthData[i];
      paymentsData.push({
        value: i > 0 ? paymentsData[i - 1].value + data.value : data.value,
        date: new Date(data.date ?? Date.now()),
      });
    }
    let isCurrentMonth =
      moment().format(`MM/YYYY`) === moment(`${selectedMonth}/${selectedYear}`, "M/YYYY").format(`MM/YYYY`);
    return paymentsData.map((pd) => ({
      date: pd.date,
      value: moment(pd.date).date() <= moment().date() || !isCurrentMonth ? pd.value : null,
      objective: 7000,
    }));
  };

  const getPaymentsTotal = () => {
    let paymentsTotal = payments.reduce((acc, curr) => acc + curr.value, 0);
    return Money({ amount: getInt(paymentsTotal) }).toFormat();
  };

  const getComission = () => {
    let paymentsTotal = payments.reduce((acc, curr) => acc + curr.value, 0);
    let comissionBase = Money({ amount: getInt(paymentsTotal) }).subtract(Money({ amount: getInt(7000) }));
    let comission =
      paymentsTotal > 7000
        ? comissionBase
            .multiply(0.1)
            .add(Money({ amount: getInt(350) }))
            .toUnit()
        : 0;
    return Money({ amount: getInt(comission) }).toFormat();
  };

  if (loadingMetrics)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Dashboard</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Dashboard</PageTitle>
      </TitleContainer>
      <div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 100px)" }}>
        <ContentContainer>
          <div className={"text-muted"}>
            <h5>Filtros</h5>
          </div>
          <div className={"row"}>
            <div className={"col-auto"}>
              <FilterSelect
                label={"Mês"}
                isClearable={false}
                isDisabled={loadingMetrics}
                value={monthsSelectOptions.find((option) => option.value === selectedMonth) ?? null}
                options={monthsSelectOptions}
                onChange={(option) => {
                  setSelectedMonth(option?.value ?? "");
                }}
              />
            </div>
            <div className={"col-auto"}>
              <FilterSelect
                label={"Ano"}
                isClearable={false}
                isDisabled={loadingMetrics}
                value={yearsSelectOptions.find((option) => option.value === selectedYear) ?? null}
                options={yearsSelectOptions}
                onChange={(option) => {
                  setSelectedYear(option?.value ?? "");
                }}
              />
            </div>
          </div>
        </ContentContainer>
        <div className={"row"} style={{ padding: 17 }}>
          <div className="col-lg-4 mb-3 mb-lg-0 px-0 px-lg-2">
            <MetricCard label={"Vendas"} number={getPaymentsTotal()} loading={loadingMetrics} />
          </div>
          <div className="col-lg-4 mb-3 mb-lg-0 px-0 px-lg-2">
            <MetricCard label={"Meta"} number={`R$ 7.000,00`} loading={loadingMetrics} />
          </div>
          <div className="col-lg-4 mb-3 mb-lg-0 px-0 px-lg-2">
            <MetricCard label={"Minha comissão"} number={getComission()} loading={loadingMetrics} />
          </div>
        </div>
        <ContentContainer>
          <div className={"mt-2 text-muted"}>
            <h5>Minhas vendas</h5>
          </div>
          <div className={"row"}>
            <div className={"col-lg-6 py-4"}>
              <div style={{ width: "100%", height: 300 }}>
                <ResponsiveContainer>
                  <LineChart width={600} height={300} data={buildSalesChartData()}>
                    <Line name="Valor" type="monotone" dataKey="value" stroke="#9841e2" />
                    <Line name="Meta" dataKey="objective" stroke="#198754" />
                    <XAxis
                      dataKey="date"
                      label={(d: Date) => "s"}
                      tickFormatter={(t: Date) => moment(t).format("DD/MM")}
                      fontSize={10}
                    />
                    <YAxis tickFormatter={(t) => Money({ amount: getInt(t) }).toFormat()} fontSize={10} />
                    <Tooltip
                      labelFormatter={(t: Date) => moment(t).format("DD/MM/YYYY")}
                      formatter={(t: any) => Money({ amount: getInt(t) }).toFormat()}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className={"col-lg-6"}>
              <DataTable
                noDataComponent="Nenhum resultado encontrado"
                progressComponent={<LoadingIndicator />}
                progressPending={loadingMetrics}
                columns={columns}
                data={payments}
                pagination
                fixedHeader
                defaultSortAsc={true}
                defaultSortFieldId={1}
                customStyles={{ table: { style: { maxHeight: 290 } } }}
                fixedHeaderScrollHeight="80vh"
                highlightOnHover
                pointerOnHover
              />
            </div>
          </div>
        </ContentContainer>
      </div>
    </RootContainer>
  );
};

export default SellerDashboard;
