import * as React from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";
import { Plan } from "@util/interfaces";
import { getInt, toastError } from "@util/functions";
import { Link } from "react-router-dom";
import Api from "@services/Api";
import Money from "@util/money";

const columns: TableColumn<Plan>[] = [
  {
    name: "Plano",
    selector: (row: Plan) => row.name,
  },
  {
    name: "Mensal",
    selector: (row: Plan) => Money({ amount: getInt(row.price_monthly) }).toFormat(),
  },
  {
    name: "Semestral",
    selector: (row: Plan) => Money({ amount: getInt(row.price_semesterly) }).toFormat(),
  },
  {
    name: "Anual",
    selector: (row: Plan) => Money({ amount: getInt(row.price_yearly) }).toFormat(),
  },
];

const PlansListScreen: React.FC = () => {
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchPlans();
    // eslint-disable-next-line
  }, []);

  const fetchPlans = async () => {
    try {
      const { data } = await Api.get(`/plan`);
      setPlans(data.plans);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  if (loading)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Planos</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Planos</PageTitle>
        <Link to="/planos/cadastro" className="btn btn-primary">
          cadastrar plano
        </Link>
      </TitleContainer>
      <ContentContainer>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loading}
          columns={columns}
          data={plans}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default PlansListScreen;
