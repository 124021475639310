import FormikTextInput from "@components/ui/FormikTextInput";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { Store } from "@util/interfaces";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import * as React from "react";
import { useParams } from "react-router";

type OpeningTimeScreenProps = {
  store_id?: string;
};

interface OpeningTimeFormValues {
  monday_open: string;
  monday_close: string;
  tuesday_open: string;
  tuesday_close: string;
  wednesday_open: string;
  wednesday_close: string;
  thursday_open: string;
  thursday_close: string;
  friday_open: string;
  friday_close: string;
  saturday_open: string;
  saturday_close: string;
  sunday_open: string;
  sunday_close: string;
}

const OpeningTimeScreen: React.FC = () => {
  const [store, setStore] = React.useState<Store>();
  const [fetchingStore, setFetchingStore] = React.useState(true);
  const { store_id } = useParams<OpeningTimeScreenProps>();

  const fetchStore = async () => {
    try {
      const { data } = await Api.get(`/store/id/${store_id}`);
      setStore(data.store);
      setFetchingStore(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    fetchStore();
    // eslint-disable-next-line
  }, []);

  const initialValues: OpeningTimeFormValues = {
    monday_open: store?.opening_time?.monday_open ? moment(store.opening_time.monday_open).format("HH:mm") : "",
    monday_close: store?.opening_time?.monday_close ? moment(store.opening_time.monday_close).format("HH:mm") : "",
    tuesday_open: store?.opening_time?.tuesday_open ? moment(store.opening_time.tuesday_open).format("HH:mm") : "",
    tuesday_close: store?.opening_time?.tuesday_close ? moment(store.opening_time.tuesday_close).format("HH:mm") : "",
    wednesday_open: store?.opening_time?.wednesday_open
      ? moment(store.opening_time.wednesday_open).format("HH:mm")
      : "",
    wednesday_close: store?.opening_time?.wednesday_close
      ? moment(store.opening_time.wednesday_close).format("HH:mm")
      : "",
    thursday_open: store?.opening_time?.thursday_open ? moment(store.opening_time.thursday_open).format("HH:mm") : "",
    thursday_close: store?.opening_time?.thursday_close
      ? moment(store.opening_time.thursday_close).format("HH:mm")
      : "",
    friday_open: store?.opening_time?.friday_open ? moment(store.opening_time.friday_open).format("HH:mm") : "",
    friday_close: store?.opening_time?.friday_close ? moment(store.opening_time.friday_close).format("HH:mm") : "",
    saturday_open: store?.opening_time?.saturday_open ? moment(store.opening_time.saturday_open).format("HH:mm") : "",
    saturday_close: store?.opening_time?.saturday_close
      ? moment(store.opening_time.saturday_close).format("HH:mm")
      : "",
    sunday_open: store?.opening_time?.sunday_open ? moment(store.opening_time.sunday_open).format("HH:mm") : "",
    sunday_close: store?.opening_time?.sunday_close ? moment(store.opening_time.sunday_close).format("HH:mm") : "",
  };

  const handleSubmit = async (values: OpeningTimeFormValues, actions: FormikHelpers<OpeningTimeFormValues>) => {
    actions.setSubmitting(true);
    try {
      await Api.post(`/store/${store_id}/opening_time`, values);
      toastMessage(`Dados atualizados.`, "success");
      actions.setSubmitting(false);
    } catch (error) {
      toastError(error);
      actions.setSubmitting(false);
    }
  };

  if (fetchingStore)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Editar estabelecimento</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Editar horário de funcionamento</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className={"row m-0"}>
                <div className="col-12 mb-3">
                  <PageTitle style={{ fontSize: 24 }}>Horário de funcionamento</PageTitle>
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"monday_open"} mask="99:99" label={"Na segunda abre às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"monday_close"} mask="99:99" label={"Na segunda fecha às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"tuesday_open"} mask="99:99" label={"Na terça abre às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"tuesday_close"} mask="99:99" label={"Na terça fecha às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"wednesday_open"} mask="99:99" label={"Na quarta abre às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"wednesday_close"} mask="99:99" label={"Na quarta fecha às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"thursday_open"} mask="99:99" label={"Na quinta abre às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"thursday_close"} mask="99:99" label={"Na quinta fecha às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"friday_open"} mask="99:99" label={"Na sexta abre às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"friday_close"} mask="99:99" label={"Na sexta fecha às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"saturday_open"} mask="99:99" label={"No sábado abre às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"saturday_close"} mask="99:99" label={"No sábado fecha às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"sunday_open"} mask="99:99" label={"No domingo abre às"} />
                </div>
                <div className="mb-4 col-md-6">
                  <FormikTextInput name={"sunday_close"} mask="99:99" label={"No domingo fecha às"} />
                </div>
                <div className="col-12">
                  <button type="submit" className={"btn btn-primary"} disabled={isSubmitting}>
                    Salvar
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default OpeningTimeScreen;
