import { InputLabel } from "@components/ui/Typography";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { HotelFormValues } from "@util/interfaces";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useHistory } from "react-router";
import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";

const AddHotelScreen: React.FC = () => {
  const history = useHistory();

  const handleSubmit = async (values: HotelFormValues, actions: FormikHelpers<HotelFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.post(`/hotel`, values);
      toastMessage("Hotel criado", "success");
      actions.setSubmitting(true);
      history.push("/hoteis");
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const initialValues: HotelFormValues = {
    name: "",
    phone: "",
    phone_2: "",
  };

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Cadastrar hotel</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="row m-0 p-0">
                <div className="mb-4 col-md-3">
                  <InputLabel>Nome</InputLabel>
                  <Field name="name" className="form-control" placeholder="Digite o nome" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Email</InputLabel>
                  <Field name="email" className="form-control" placeholder="Digite o email" />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (1)</InputLabel>
                  <Field name="phone" className="form-control" placeholder="Digite o telefone (1)" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (2)</InputLabel>
                  <Field name="phone_2" className="form-control" placeholder="Digite o telefone (2)" />
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Endereço</InputLabel>
                  <Field name="address" className="form-control" placeholder="Digite o endereço" />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "enviando" : "enviar"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default AddHotelScreen;
