import * as React from "react";
import { InputLabel } from "@components/ui/Typography";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { SellerFormValues, User } from "@util/interfaces";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useHistory, useParams } from "react-router";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";

type EditSellerProps = {
  seller_id?: string;
};

const EditSellerScreen: React.FC = () => {
  const history = useHistory();
  const { seller_id } = useParams<EditSellerProps>();
  const [loading, setLoading] = React.useState(true);
  const [seller, setSeller] = React.useState<User>();

  React.useEffect(() => {
    fetchSellerDetails();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (seller) setLoading(false);
    // eslint-disable-next-line
  }, [seller]);

  const fetchSellerDetails = async () => {
    try {
      const { data } = await Api.get(`/user/sellers/details`, { params: { seller_id } });
      setSeller(data.seller);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  const handleSubmit = async (values: SellerFormValues, actions: FormikHelpers<SellerFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.put(`/user/${seller_id}`, values);
      toastMessage("Usuário atualizado", "success");
      actions.setSubmitting(true);
      history.push("/vendedores");
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  if (loading || !seller)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Vendedores</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  const initialValues: SellerFormValues = {
    name: seller.name,
    password: seller.password,
    email: seller.email,
  };

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Cadastrar vendedor</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="row m-0 p-0">
                <div className="mb-4 col-md-4">
                  <InputLabel>Nome</InputLabel>
                  <Field
                    name="name"
                    className="form-control"
                    placeholder="Digite o nome do vendedor"
                    required
                    disabled
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Email</InputLabel>
                  <Field
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Digite o email do vendedor"
                    required
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Senha</InputLabel>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Digite o nome do vendedor"
                    required
                  />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "enviando" : "enviar"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default EditSellerScreen;
