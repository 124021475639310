import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@services/Api";
import { toastError } from "@util/functions";
import { Sale, Store, StoresQuery, LocationsQuery, SalesQuery } from "@util/interfaces";
import store from "./index";

const app = createSlice({
  name: "app",
  initialState: {
    user: null,
    isSidebarToggled: window.innerWidth > 768,
    // Stores
    stores: [] as Store[],
    storesCount: 0,
    loadingStores: true,
    storesQuery: { take: 10 } as StoresQuery,
    // Sales
    sales: [] as Sale[],
    filteredSales: [] as Sale[],
    loadingSales: true,
    salesQuery: {} as SalesQuery,
    // Locations
    locationsQuery: {} as LocationsQuery,
  },
  reducers: {
    toggleSidebar(state) {
      state.isSidebarToggled = !state.isSidebarToggled;
    },
    setStores(state, action: PayloadAction<Store[]>) {
      state.stores = action.payload;
    },
    setStoresQuery(state, action: PayloadAction<StoresQuery>) {
      state.storesQuery = action.payload;
    },
    setStoresCount(state, action: PayloadAction<number>) {
      state.storesCount = action.payload;
    },
    setLoadingStores(state, action: PayloadAction<boolean>) {
      state.loadingStores = action.payload;
    },
    setSales(state, action: PayloadAction<Sale[]>) {
      state.sales = action.payload;
    },
    setSalesQuery(state, action: PayloadAction<SalesQuery>) {
      state.salesQuery = action.payload;
    },
    setLoadingSales(state, action: PayloadAction<boolean>) {
      state.loadingSales = action.payload;
    },
    setFilteredSales(state, action: PayloadAction<Sale[]>) {
      state.filteredSales = action.payload;
    },
    setLocationsQuery(state, action: PayloadAction<LocationsQuery>) {
      state.locationsQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStores.pending, (state, action) => {
      state.loadingStores = true;
    });
    builder.addCase(fetchStores.rejected, (state, action) => {
      state.loadingStores = false;
      toastError(action.error);
    });
    builder.addCase(fetchStores.fulfilled, (state, action) => {
      state.loadingStores = false;
      state.stores = action.payload.stores;
      state.storesCount = action.payload.count;
    });

    builder.addCase(fetchSales.pending, (state, action) => {
      state.loadingSales = true;
    });
    builder.addCase(fetchSales.rejected, (state, action) => {
      state.loadingSales = false;
      toastError(action.error);
    });
    builder.addCase(fetchSales.fulfilled, (state, action) => {
      state.loadingSales = false;
      state.sales = action.payload;
      state.filteredSales = action.payload;
    });
  },
});

export default app.reducer;

export const {
  toggleSidebar,
  setStores,
  setLoadingStores,
  setStoresQuery,
  setStoresCount,
  setSales,
  setSalesQuery,
  setLoadingSales,
  setFilteredSales,
  setLocationsQuery,
} = app.actions;

export const fetchStores = createAsyncThunk("store", async (payload, { rejectWithValue }) => {
  try {
    let url = "/store?";
    const query = store.getState().appSettings.storesQuery;
    if (query) {
      if (query.name) url += `name=${encodeURIComponent(query.name)}&`;
      if (query.block) url += `block=${query.block}&`;
      if (query.address_type) url += `address_type=${query.address_type}&`;
      if (query.module) url += `module=${query.module}&`;
      if (query.street) url += `street=${query.street}&`;
      if (query.number) url += `number=${query.number}&`;
      if (query.building_type) url += `building_type=${query.building_type}&`;
      if (query.plan) url += `plan=${query.plan}&`;
      if (query.take) url += `take=${query.take}&`;
      if (query.page) url += `page=${query.page}&`;
      if (query.stablishment_type) url += `stablishment_type=${query.stablishment_type}&`;
      if (query.store_info_type) url += `${query.store_info_type}=${query.store_info_value}&`;
    }
    const { data } = await Api.get(url);
    return data;
  } catch (error) {
    console.log(error, "error on fetchstores");
    throw rejectWithValue(error);
  }
});

// eslint-disable-next-line
export const fetchSales = createAsyncThunk("sales", async (undefined, { rejectWithValue }) => {
  try {
    let url = "/sale?";
    const query = store.getState().appSettings.salesQuery;

    if (query) {
      if (query.payment_method) url += `payment_method=${query.payment_method}&`;
    }

    const { data } = await Api.get(url);
    return data.sales;
  } catch (error) {
    console.log(error, "error on fetchsales");
    throw rejectWithValue(error);
  }
});
