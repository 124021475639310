import { PageTitle } from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { Video } from "@util/interfaces";
import React from "react";
import Modal, { ModalProps } from "react-responsive-modal";

interface VideoUrlModalProps extends ModalProps {
  video: Video;
}

export const VideoUrlModal: React.FC<VideoUrlModalProps> = (props) => {
  const { video } = props;
  const [videoUrl, setVideoUrl] = React.useState(video.clickable_url ?? "");
  const [isSaving, setIsSaving] = React.useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await Api.patch(`/video/${video.id}`, { url: videoUrl });
      toastMessage("Link do vídeo atualizado", "success");
      props.onClose();
    } catch (error) {
      toastError(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal {...props}>
      <PageTitle>Atualizar link do vídeo</PageTitle>
      <div style={{ minWidth: 400, maxWidth: "90vw", marginTop: 20 }}>
        <input
          className="form-control"
          style={{ maxWidth: "80vw" }}
          value={videoUrl}
          placeholder={"Link do video"}
          onChange={(e) => {
            setVideoUrl(e.target.value);
          }}
        />
        <button className="btn btn-primary mt-3" onClick={handleSave} disabled={isSaving}>
          {isSaving ? "Salvando" : "Salvar"}
        </button>
      </div>
    </Modal>
  );
};
