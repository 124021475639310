  import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";
import * as React from "react";

import { NewPaymentScreenSelectionButton, NewPaymentScreenSelectionContainer } from "./components";
import NewEmepagPaymentScreen from "./NewEmepagPaymentScreen";
import NewManualPaymentScreen from "./NewManualPayment";

enum NewPaymentScreenSelection {
  CREATE_EMEPAG_PAYMENT = "CREATE_EMEPAG_PAYMENT",
  CREATE_MANUAL_PAYMENT = "CREATE_MANUAL_PAYMENT",
}

const NewPaymentScreen: React.FC = () => {
  const [newPaymentSelectedScreen, setnewPaymentSelectedScreen] = React.useState<NewPaymentScreenSelection>();

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Novo pagamento</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <NewPaymentScreenSelectionContainer className={"pb-3 border-bottom"}>
          <NewPaymentScreenSelectionButton
            selected={newPaymentSelectedScreen === NewPaymentScreenSelection.CREATE_EMEPAG_PAYMENT}
            onClick={() => setnewPaymentSelectedScreen(NewPaymentScreenSelection.CREATE_EMEPAG_PAYMENT)}
          >
            Quero criar um pagamento através do emepag
          </NewPaymentScreenSelectionButton>
          <NewPaymentScreenSelectionButton
            selected={newPaymentSelectedScreen === NewPaymentScreenSelection.CREATE_MANUAL_PAYMENT}
            onClick={() => setnewPaymentSelectedScreen(NewPaymentScreenSelection.CREATE_MANUAL_PAYMENT)}
          >
            Quero criar um pagamento manual
          </NewPaymentScreenSelectionButton>
        </NewPaymentScreenSelectionContainer>
        {newPaymentSelectedScreen === NewPaymentScreenSelection.CREATE_EMEPAG_PAYMENT && <NewEmepagPaymentScreen />}
        {newPaymentSelectedScreen === NewPaymentScreenSelection.CREATE_MANUAL_PAYMENT && <NewManualPaymentScreen />}
      </ContentContainer>
    </RootContainer>
  );
};

export default NewPaymentScreen;
