import { getSaleStatusColor, getSaleStatusLabel } from "@util/functions";
import React from "react";

const SaleStatusPill: React.FC<{ status: string }> = ({ status }) => {
  return (
    <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
      <div
        style={{
          display: "inline-grid",
          width: 10,
          height: 10,
          marginRight: 5,
          backgroundColor: getSaleStatusColor(status),
          borderRadius: 10,
        }}
      />
      <span>{getSaleStatusLabel(status)}</span>
    </div>
  );
};

export default SaleStatusPill;
