import { InputLabel, Subtitle, Title } from "@components/ui/Typography";
import * as React from "react";
import { Formik, FormikHelpers, Form, Field } from "formik";
import LogRocket from "logrocket";

import ErrorMessage, { FormContainer, FormContainerWrapper, RootContainer, TextContainer } from "./components";

import logo from "@assets/img/logo.png";
import Api from "@services/Api";
import Auth from "@services/Auth";
import { Redirect, useHistory } from "react-router";

interface LoginFormValues {
  username: string;
  password: string;
}

const LoginScreen: React.FC = () => {
  const initialValues: LoginFormValues = { username: "", password: "" };
  const [errorMessage, setErrorMessage] = React.useState("");
  const history = useHistory();

  const handleSubmit = async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
    try {
      setErrorMessage("");
      actions.setSubmitting(true);
      const { data } = await Api.post(`/user/authenticate`, values);
      Auth.setAuthData(data.token, data.userName, data.userRole);
      LogRocket.identify(data.userName, {
        name: data.name,
        email: values.username,
        role: data.userRole,
      });
      history.replace("/estabelecimentos");
    } catch (error: any) {
      actions.setSubmitting(false);
      if (error?.response?.data?.userMessage === "unauthorized") return setErrorMessage("credenciais inválidas");
      setErrorMessage(error?.response?.data?.userMessage ?? "erro de conexão");
    }
  };

  if (Auth.isAuthenticated()) return <Redirect to="/" />;

  return (
    <RootContainer>
      <FormContainerWrapper>
        <img src={logo} width={60} height={60} alt="gears" />
        <TextContainer className={"text-center"}>
          <Title>Painel Moda Center</Title>
          <Subtitle>Entre com suas credenciais</Subtitle>
        </TextContainer>
        <FormContainer>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-4">
                  <InputLabel>Email</InputLabel>
                  <Field
                    name="username"
                    type="email"
                    className="form-control"
                    placeholder="Digite seu email"
                    required
                  />
                </div>
                <div className="">
                  <InputLabel>Senha</InputLabel>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Digite sua senha"
                    required
                  />
                </div>
                <ErrorMessage message={errorMessage} />
                <div className="w-100 d-grid mt-4">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    entrar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </FormContainerWrapper>
    </RootContainer>
  );
};

export default LoginScreen;
