import Map from "@components/ui/Map";
import * as React from "react";
import MapForm from "./MapForm";

const MapsScreen: React.FC = () => {
  const [currentLocation, setCurrentLocation] = React.useState<{
    lat: number;
    lng: number;
  }>();

  const handleMapClick = (latLng: { lat: number; lng: number }) => {
    setCurrentLocation({ lat: latLng.lat, lng: latLng.lng });
  };

  const handleFormClear = () => {
    setCurrentLocation(undefined);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: "40vw", height: "100vh" }}>
        <MapForm latitude={currentLocation?.lat ?? 0} longitude={currentLocation?.lng ?? 0} onClear={handleFormClear} />
      </div>
      <div style={{ width: "60vw", height: "100vh", padding: 10 }}>
        <div
          style={{
            height: "100%",
            borderStyle: "solid",
            borderWidth: 3,
            borderColor: "#9841e2",
            borderRadius: 8,
            overflow: "hidden",
          }}
        >
          <Map handleMapClick={handleMapClick} />
        </div>
      </div>
    </div>
  );
};

export default MapsScreen;
