import * as React from "react";
import { Divider, ListItemsContainer, LogoContainer, MenuItem, RootContainer, ToggleButton } from "./components";

// Importing assets
import logo from "@assets/img/logo.png";
import {
	IoBedOutline,
	BiBuilding,
	BiMenu,
	BsPower,
	BsPerson,
	IoBusOutline,
	GoDashboard,
	BiCoinStack,
	BsCashCoin,
	FaRegHandshake,
	BiMap,
	MdLiveTv,
 FaRegImage, 
 IoCalendarOutline,
 IoFileTrayFullOutline
} from "react-icons/all";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@redux/index";
import { toggleSidebar } from "@redux/AppData.store";

const Sidebar: React.FC = () => {
	const userRole = localStorage.getItem("user_role");
	const { pathname } = useLocation();
	const { isSidebarToggled } = useSelector((state: RootState) => state.appSettings);
	const dispatch = useAppDispatch();

	return (
		<RootContainer is_toggled={isSidebarToggled}>
			<LogoContainer>
				<img src={logo} width={50} height={50} alt="logo" />
				<span style={{ color: "white", fontWeight: "bold", fontSize: 19 }}>Moda Center</span>
			</LogoContainer>
			<ListItemsContainer>
				<MenuItem
					href="/dashboard"
					icon={
						<GoDashboard
							color={pathname.includes("/dashboard") ? "white" : "#dbdbdb"}
							style={{ marginRight: 10 }}
							size={16}
						/>
					}
					is_active={pathname.includes("/dashboard")}
					text={"Dashboard"}
				/>
				{userRole === "admin" && (
					<>
						<MenuItem
							href="/vendedores"
							icon={
								<BsPerson
									color={pathname.includes("/vendedores") ? "white" : "#dbdbdb"}
									style={{ marginRight: 10 }}
									size={16}
								/>
							}
							is_active={pathname.includes("/vendedores")}
							text={"Vendedores"}
						/>
						<MenuItem
							href="/hoteis"
							icon={
								<IoBedOutline
									color={pathname.includes("/hoteis") ? "white" : "#dbdbdb"}
									style={{ marginRight: 10 }}
									size={16}
								/>
							}
							is_active={pathname.includes("/hoteis")}
							text={"Hotéis"}
						/>
						<MenuItem
							href="/excursoes"
							icon={
								<IoBusOutline
									color={pathname.includes("/excursoes") ? "white" : "#dbdbdb"}
									style={{ marginRight: 10 }}
									size={16}
								/>
							}
							is_active={pathname.includes("/excursoes")}
							text={"Excursões"}
						/>

						<MenuItem
							href="/planos"
							icon={
								<BiCoinStack
									color={pathname.includes("/planos") ? "white" : "#dbdbdb"}
									style={{ marginRight: 10 }}
									size={16}
								/>
							}
							is_active={pathname.includes("/planos")}
							text={"Planos"}
						/>
						<MenuItem
							href="/relatorios"
							icon={
								<BsCashCoin
									color={pathname.includes("/relatorios") ? "white" : "#dbdbdb"}
									style={{ marginRight: 10 }}
									size={16}
								/>
							}
							is_active={pathname.includes("/relatorios")}
							text={"Relatórios"}
						/>
						<MenuItem
							href="/app-banners"
							icon={
								<FaRegImage
									color={pathname.includes("/app-banners") ? "white" : "#dbdbdb"}
									style={{ marginRight: 10 }}
									size={16}
								/>
							}
							is_active={pathname.includes("/app-banners")}
							text={"Banners"}
						/>
					</>
				)}
				<MenuItem
					href="/estabelecimentos"
					icon={
						<BiBuilding
							color={pathname.includes("/estabelecimentos") ? "white" : "#dbdbdb"}
							style={{ marginRight: 10 }}
							size={16}
						/>
					}
					is_active={pathname.includes("/estabelecimentos")}
					text={"Estabelecimentos"}
				/>
				<MenuItem
					href="/vendas"
					icon={
						<FaRegHandshake
							color={pathname.includes("/vendas") ? "white" : "#dbdbdb"}
							style={{ marginRight: 10 }}
							size={16}
						/>
					}
					is_active={pathname.includes("/vendas")}
					text={"Vendas"}
				/>
				<MenuItem
					href="/carnes"
					icon={
						<IoFileTrayFullOutline
							color={pathname.includes("/carnes") ? "white" : "#dbdbdb"}
							style={{ marginRight: 10 }}
							size={16}
						/>
					}
					is_active={pathname.includes("/carnes")}
					text={"Carnês"}
				/>
				<MenuItem
					href="/calendar"
					icon={
						<IoCalendarOutline
							color={pathname.includes("/calendar") ? "white" : "#dbdbdb"}
							style={{ marginRight: 10 }}
							size={16}
						/>
					}
					is_active={pathname.includes("/calendar")}
					text={"Calendário"}
				/>
				<MenuItem
					href="/live"
					icon={
						<MdLiveTv color={pathname.includes("/live") ? "white" : "#dbdbdb"} style={{ marginRight: 10 }} size={16} />
					}
					is_active={pathname.includes("/live")}
					text={"Live"}
				/>
				<MenuItem
					href="/maps"
					icon={
						<BiMap color={pathname.includes("/maps") ? "white" : "#dbdbdb"} style={{ marginRight: 10 }} size={16} />
					}
					is_active={pathname.includes("/maps")}
					text={"Geolocalização (patrimônios)"}
				/>
				<MenuItem
					href="/locations"
					icon={
						<BiMap
							color={pathname.includes("/locations") ? "white" : "#dbdbdb"}
							style={{ marginRight: 10 }}
							size={16}
						/>
					}
					is_active={pathname.includes("/locations")}
					text={"Geolocalização (pontos avulsos)"}
				/>

				<Divider className="my-1" />
				<MenuItem
					href="/logout"
					icon={
						<BsPower color={pathname.includes("/logout") ? "white" : "#dbdbdb"} style={{ marginRight: 10 }} size={16} />
					}
					is_active={pathname.includes("/logout")}
					text={"Sair"}
				/>
			</ListItemsContainer>
			<ToggleButton
				is_sidebar_toggled={isSidebarToggled}
				onClick={() => {
					dispatch(toggleSidebar());
				}}
			>
				<BiMenu size={32} />
			</ToggleButton>
		</RootContainer>
	);
};

export default Sidebar;
