import styled from "styled-components";

import { Subtitle } from "@components/ui/Typography";

export const LoadingIndicator: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      className="my-4"
    >
      <div className="spinner-border text-primary mb-3" style={{ width: 50, height: 50 }} role="status" />
      <Subtitle>Buscando dados</Subtitle>
    </div>
  );
};

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 80px;
  justify-content: space-between;
  padding-right: 20px;
  @media (max-width: 768px) {
    padding-left: 65px;
  }
`;

export const PageTitle = styled.span`
  font-size: 32px;
  color: #a4a6b3;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const ContentContainer = styled.div`
  background-color: white;
  width: 98%;
  margin: auto;
  max-height: calc(100vh - 100px);
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  border: 2px #ececec solid;
  @media (max-width: 575.98px) {
    max-height: calc(100vh - 85px);
  }
`;
