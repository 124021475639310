import * as React from "react";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { useHistory, useParams } from "react-router";
import CurrencyInput from "react-currency-input-field";
import ReactTooltip from "react-tooltip";
import { SlideDown } from "@components/ui/SlideDown";
import moment from "moment";
import Modal from "react-responsive-modal";
import { Calendar } from "react-date-range";

interface NewEmepagPaymentFormValues {
  max_installments_count?: number;
  expires_at?: Date;
  value?: number;
}

interface NewEmepagPaymentScreenProps {
  sale_id: string;
}

const NewEmepagPaymentScreen: React.FC = () => {
  const formRef = React.useRef<FormikProps<NewEmepagPaymentFormValues>>(null);
  const { sale_id } = useParams<NewEmepagPaymentScreenProps>();
  const [isExpiresAtMenuOpen, setIsExpiresAtMenuOpen] = React.useState(false);
  const history = useHistory();

  const handleSubmit = async (
    values: NewEmepagPaymentFormValues,
    actions: FormikHelpers<NewEmepagPaymentFormValues>
  ) => {
    try {
      actions.setSubmitting(true);
      const data = {
        ...values,
        sale_id: parseInt(sale_id),
        create_emepag_order: true,
      } as any;
      if (values.expires_at) data.expires_at = moment(values.expires_at).format("DD/MM/YYYY");
      await Api.post(`/payment`, data);
      toastMessage("Pagamento criado", "success");
      actions.setSubmitting(false);
      history.replace(`/vendas/${sale_id}/pagamentos`);
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const handleExpiresAtChange = (expiresAt: Date) => {
    setIsExpiresAtMenuOpen(false);
    formRef.current?.setFieldValue("expires_at", expiresAt);
  };

  const initialValues: NewEmepagPaymentFormValues = {
    max_installments_count: 12,
  };

  return (
    <SlideDown>
      <Formik innerRef={formRef} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className={"row mt-3"} autoComplete="off">
            <div className={"col-lg-auto form-group"}>
              <label>Valor do pagamento</label>
              <CurrencyInput
                style={{ minWidth: 400 }}
                prefix="R$"
                allowNegativeValue={false}
                decimalSeparator={","}
                groupSeparator={"."}
                className={"form-control"}
                placeholder="Deixe em branco para calcular automaticamente"
                decimalsLimit={2}
                onValueChange={(value, name) => {
                  setFieldValue("value", parseFloat(value?.replace(".", "").replace(",", ".") ?? ""));
                }}
              />
            </div>
            <div className={"col-lg-auto form-group"}>
              <label>Data de expiração</label>
              <Field
                name="expires_at"
                placeholder={"Selecionar data"}
                className="form-control"
                value={values.expires_at ? moment(values.expires_at).format("DD/MM/YYYY") : undefined}
                onChange={(e: any) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  setIsExpiresAtMenuOpen(true);
                }}
              />
            </div>
            <div className={"col-lg-auto form-group"}>
              <label>Máximo de parcelas</label>
              <Field
                name="max_installments_count"
                type="number"
                className="form-control w-auto"
                max={12}
                min={1}
                required
              />
            </div>
            <div className={"form-group mt-3"}>
              <button className={"btn btn-primary"} disabled={isSubmitting}>
                {isSubmitting ? "Enviando..." : "Enviar"}
              </button>
            </div>
            <Modal
              open={isExpiresAtMenuOpen}
              center={true}
              onClose={() => {
                setIsExpiresAtMenuOpen(false);
              }}
            >
              <Calendar
                onChange={handleExpiresAtChange}
                date={values.expires_at}
                color="#9841e2"
                rangeColors={["#9841e2"]}
              />
            </Modal>
          </Form>
        )}
      </Formik>
      <ReactTooltip className="tooltip" clickable={true} html={true} />
    </SlideDown>
  );
};

export default NewEmepagPaymentScreen;
