import { LoadingIndicator, RootContainer } from "@components/ui/PageBaseComponents";
import { TitleContainer } from "@components/ui/PageBaseComponents";
import { PageTitle } from "@components/ui/PageBaseComponents";
import { ContentContainer } from "@components/ui/PageBaseComponents";
import { ImagesContainer as CardContainer } from "./styled";
import ImagesList from "@components/ui/ImagesList";
import React, { useEffect } from "react";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { useDropzone } from "react-dropzone";
import { Image, Video } from "@util/interfaces";
import VideosList from "@components/ui/VideosList";

export const AppBannersPage: React.FC = () => {
  const [uploading, setUploading] = React.useState(false);
  const [fetchingImages, setFetchingImages] = React.useState(true);
  const [desktopImages, setDesktopImages] = React.useState<Image[]>([]);
  const [mobileImages, setMobileImages] = React.useState<Image[]>([]);
  const [desktopVideos, setDesktopVideos] = React.useState<Video[]>([]);
  const [mobileVideos, setMobileVideos] = React.useState<Video[]>([]);
  const [fetchingVideos, setFetchingVideos] = React.useState(true);

  const onDrop = async (acceptedFiles: File[], platform: "desktop" | "mobile") => {
    if (uploading) return;
    try {
      setUploading(true);
      const formData = new FormData();
      for (const file of acceptedFiles) {
        formData.append("files", file);
      }
      formData.append("platform", platform);
      await Api.post(`/app-banners`, formData);
      fetchImages();
      setUploading(false);
      toastMessage("Imagens atualizadas", "success");
    } catch (error) {
      setUploading(false);
      toastError(error);
    }
  };

  const onDropVideos = async (acceptedFiles: File[], platform: "desktop" | "mobile") => {
    if (uploading) return;
    try {
      setUploading(true);
      const formData = new FormData();
      for (const file of acceptedFiles) {
        formData.append("video", file);
      }
      formData.append("platform", platform);
      await Api.post(`/video`, formData);
      fetchVideos();
      setUploading(false);
      toastMessage("Videos atualizados", "success");
    } catch (error) {
      setUploading(false);
      toastError(error);
    }
  };

  const fetchImages = async () => {
    try {
      const { data } = await Api.get("/app-banners");
      setDesktopImages(data.images.filter((image: Image) => image.is_desktop_banner));
      setMobileImages(data.images.filter((image: Image) => image.is_mobile_banner));
    } catch (error) {
      toastError(error);
    } finally {
      setFetchingImages(false);
    }
  };

  const fetchVideos = async () => {
    setFetchingVideos(true);
    try {
      const { data } = await Api.get("/video");
      setDesktopVideos(data.videos.filter((video: Video) => video.is_desktop_banner));
      setMobileVideos(data.videos.filter((video: Video) => video.is_mobile_banner));
    } catch (error) {
      toastError(error);
    } finally {
      setFetchingVideos(false);
    }
  };

  useEffect(() => {
    fetchImages();
    fetchVideos();
  }, []);

  const desktopDropzone = useDropzone({ onDrop: (files) => onDrop(files, "desktop") });
  const mobileDropzone = useDropzone({ onDrop: (files) => onDrop(files, "mobile") });
  const videoDesktopDropZone = useDropzone({
    accept: "video/*",
    maxFiles: 1,
    multiple: false,
    onDrop: (files) => onDropVideos(files, "desktop"),
  });
  const videoMobileDropZone = useDropzone({
    accept: "video/*",
    maxFiles: 1,
    multiple: false,
    onDrop: (files) => onDropVideos(files, "mobile"),
  });

  if (fetchingImages)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Gerenciar banners</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );
  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Gerenciar banners</PageTitle>
      </TitleContainer>
      <div style={{ height: "100vh", overflow: "auto", paddingBottom: 300 }}>
        <ContentContainer className="mb-4">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <CardContainer
              {...desktopDropzone.getRootProps()}
              style={{
                backgroundColor: "rgba(200,200,200,0.2)",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 10,
              }}
            >
              <input {...desktopDropzone.getInputProps()} />
              <span style={{ textAlign: "center" }}>
                <div>
                  <b>Imagens para computadores</b>
                </div>
                <div>
                  {uploading
                    ? "Enviando"
                    : "Arraste imagens para cá, ou clique aqui para selecionar as imagens que aparecerão nos computadores"}
                </div>
              </span>
            </CardContainer>
            <ImagesList images={desktopImages ?? []} showUrlButton />
          </div>
        </ContentContainer>
        <ContentContainer style={{ marginBottom: 30 }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <CardContainer
              {...mobileDropzone.getRootProps()}
              style={{
                backgroundColor: "rgba(200,200,200,0.2)",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 10,
              }}
            >
              <input {...mobileDropzone.getInputProps()} />
              <span style={{ textAlign: "center" }}>
                <div>
                  <b>Imagens para celulares</b>
                </div>
                <div>
                  {uploading
                    ? "Enviando"
                    : "Arraste imagens para cá, ou clique aqui para selecionar as imagens que aparecerão nos celulares"}
                </div>
              </span>
            </CardContainer>
            <ImagesList images={mobileImages ?? []} showUrlButton />
          </div>
        </ContentContainer>
        <ContentContainer>
          <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 30 }}>
            <CardContainer
              {...videoDesktopDropZone.getRootProps()}
              style={{
                backgroundColor: "rgba(200,200,200,0.2)",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 10,
              }}
            >
              <input {...videoDesktopDropZone.getInputProps()} />
              <span style={{ textAlign: "center" }}>
                <div>
                  <b>Videos para desktops</b>
                </div>
                <div>
                  {uploading
                    ? "Enviando"
                    : "Arraste vídeos para cá, ou clique aqui para selecionar os vídeos que aparecerão nos computadores"}
                </div>
              </span>
            </CardContainer>
            <VideosList videos={desktopVideos ?? []} showUrlButton />
          </div>
        </ContentContainer>
        <ContentContainer>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <CardContainer
              {...videoMobileDropZone.getRootProps()}
              style={{
                backgroundColor: "rgba(200,200,200,0.2)",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 10,
              }}
            >
              <input {...videoMobileDropZone.getInputProps()} />
              <span style={{ textAlign: "center" }}>
                <div>
                  <b>Videos para celulares</b>
                </div>
                <div>
                  {uploading
                    ? "Enviando"
                    : "Arraste vídeos para cá, ou clique aqui para selecionar os vídeos que aparecerão nos celulares"}
                </div>
              </span>
            </CardContainer>
            <VideosList videos={mobileVideos ?? []} showUrlButton />
          </div>
        </ContentContainer>
      </div>
    </RootContainer>
  );
};
