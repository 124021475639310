import * as React from "react";
import styled from "styled-components";
import ReactLoading from "react-loading";
import { Subtitle } from "@components/ui/Typography";

export const ProductImageActionsContainer = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255);
  height: 0px;
  z-index: 3;
  display: flex;
  height: 50px;
  max-height: 0px;
  opacity: 0;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export const ProductImageRootContainer = styled.div`
  background-color: "#909090";
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex: 1;
  height: 100px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  &:hover ${ProductImageActionsContainer} {
    max-height: 50px;
    opacity: 1;
    transition: all 0.3s;
  }
`;

export const LoadingIndicator: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: 50,
      }}
    >
      <ReactLoading type={"bars"} color={"black"} height={50} width={50} />
      <Subtitle>Buscando dados</Subtitle>
    </div>
  );
};
