import * as React from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";
import { User } from "@util/interfaces";
import { toastError } from "@util/functions";
import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import Api from "@services/Api";
import { useWindowSize } from "@util/hooks";

const columns: TableColumn<User>[] = [
  {
    name: "Ativo",
    selector: (row: User) => row.is_active ?? "",
    format: (row: User) => (row.is_active ? <FcCheckmark /> : <IoMdClose />),
    grow: 0,
  },
  {
    name: "Nome",
    selector: (row: User) => row.name,
    sortable: true,
  },
];

const SellersListScreen: React.FC = () => {
  const [sellers, setSellers] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState(true);
  const { width } = useWindowSize();

  React.useEffect(() => {
    fetchSellers();
  }, []);

  const fetchSellers = async () => {
    try {
      const { data } = await Api.get(`/user/sellers`);
      setSellers(data.sellers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  if (loading)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Vendedores</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Vendedores</PageTitle>
        <Link to="/vendedores/cadastro" className="btn btn-primary">
          {width >= 768 ? "Cadastrar vendedor" : "+"}
        </Link>
      </TitleContainer>
      <ContentContainer>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loading}
          columns={columns}
          data={sellers}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default SellersListScreen;
