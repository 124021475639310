import * as React from "react";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { CreditCardBrands, PaymentMethod } from "@util/interfaces";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { Calendar } from "react-date-range";
import moment from "moment";
import { ControlledMenu } from "@szhsin/react-menu";
import { AiOutlineClose } from "react-icons/ai";

interface Props {
  paymentId: number | undefined;
  expiresAt?: Date;
  onFinish: () => void;
}

interface ManualPaymentConfirmationFormValues {
  expires_at?: Date;
  value?: number;
  emepag_order_id?: string;
  paid_at?: Date;
  payment_method?: PaymentMethod;
  credit_card_brand?: CreditCardBrands;
  installment_count: number;
}

const ManualPaymentConfirmationForm: React.FC<Props> = ({ paymentId, onFinish, expiresAt }) => {
  const formRef = React.useRef<FormikProps<ManualPaymentConfirmationFormValues>>(null);

  const [isExpiresAtMenuOpen, setIsExpiresAtMenuOpen] = React.useState(false);
  const [isPaidAtMenuOpen, setIsPaidAtMenuOpen] = React.useState(false);

  const initialValues: ManualPaymentConfirmationFormValues = {
    expires_at: expiresAt,
    value: undefined,
    emepag_order_id: undefined,
    paid_at: undefined,
    payment_method: undefined,
    credit_card_brand: undefined,
    installment_count: 1,
  };

  const handleSubmit = async (
    values: ManualPaymentConfirmationFormValues,
    actions: FormikHelpers<ManualPaymentConfirmationFormValues>
  ) => {
    actions.setSubmitting(true);
    try {
      let data = {
        ...values,
        paid_at: values.paid_at ? moment(values.paid_at).format("DD/MM/YYYY") : null,
        expires_at: values.expires_at ? moment(values.expires_at).format("DD/MM/YYYY") : null,
      };
      console.log(values);
      await Api.post(`/payment/${paymentId}/manual_payment_confirmation`, data);
      toastMessage("Pagamento criado", "success");
      actions.setSubmitting(true);
      onFinish?.();
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const handlePaidAtChange = (paidAt: Date) => {
    setIsPaidAtMenuOpen(false);
    formRef.current?.setFieldValue("paid_at", paidAt);
  };

  const handleExpiresAtChange = (expiresAt: Date) => {
    setIsExpiresAtMenuOpen(false);
    formRef.current?.setFieldValue("expires_at", expiresAt);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formRef}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className={"row mt-3"} style={{ maxWidth: 400 }} autoComplete="off">
          <div className={"col-12 form-group"}>
            <label>Meio de pagamento</label>
            <Field name="payment_method" as="select" className="form-control">
              <option disabled value="" selected>
                Selecione uma opção
              </option>
              <option value="credit_card">Cartão de crédito</option>
              <option value="boleto">Boleto</option>
              <option value="pix">Pix</option>
              <option value="booklet">Carnê</option>
            </Field>
          </div>

          {values.payment_method === "credit_card" && (
            <>
              <div className={"col-12 form-group"}>
                <label>Bandeira</label>
                <Field name="credit_card_brand" as="select" className="form-control">
                  <option disabled value="" selected>
                    Selecione uma opção
                  </option>
                  <option value="visa">Visa</option>
                  <option value="mastercard">Mastercard</option>
                  <option value="amex">American Express</option>
                  <option value="diners">Diners</option>
                  <option value="elo">Elo</option>
                  <option value="hipercard">Hipercard</option>
                  <option value="hiper_itau">Hiper (itaú)</option>
                  <option value="jcb">JCB</option>
                  <option value="american_express">American Express</option>
                  <option value="discover">Discover</option>
                  <option value="aura">Aura</option>
                </Field>
              </div>
              <div className={"col-12 form-group"}>
                <label>Parcelas</label>
                <Field name="installment_count" as="select" className="form-control">
                  <option disabled value="" selected>
                    Selecione uma opção
                  </option>
                  <option value="1">1x</option>
                  <option value="2">2x</option>
                  <option value="3">3x</option>
                  <option value="4">4x</option>
                  <option value="5">5x</option>
                  <option value="6">6x</option>
                  <option value="7">7x</option>
                  <option value="8">8x</option>
                  <option value="9">9x</option>
                  <option value="10">10x</option>
                  <option value="11">11x</option>
                  <option value="12">12x</option>
                </Field>
              </div>
            </>
          )}
          <div className={"col-12 form-group"}>
            <label>Data de pagamento</label>
            <Field
              name="paid_at"
              placeholder={"Selecionar data"}
              className="form-control"
              value={values.paid_at ? moment(values.paid_at).format("DD/MM/YYYY") : undefined}
              onChange={(e: any) => {
                e.preventDefault();
              }}
              onClick={() => {
                setIsPaidAtMenuOpen(true);
              }}
            />
            <ControlledMenu state={isPaidAtMenuOpen ? "open" : "closed"} captureFocus={false}>
              <div className={"d-flex align-items-center justify-content-end px-2"}>
                <span
                  onClick={() => {
                    setIsPaidAtMenuOpen(false);
                  }}
                >
                  <span>Fechar</span>
                  <AiOutlineClose />
                </span>
              </div>
              <Calendar onChange={handlePaidAtChange} date={values.paid_at} color="#9841e2" rangeColors={["#9841e2"]} />
            </ControlledMenu>
          </div>
          <div className={"col-12 form-group"}>
            <label data-tip="Data em que o plano irá expirar">Data de expiração</label>
            <Field
              name="expires_at"
              placeholder={"Selecionar data"}
              className="form-control"
              value={moment(values.expires_at).format("DD/MM/YYYY")}
              onChange={(e: any) => {
                e.preventDefault();
              }}
              onClick={() => {
                setIsExpiresAtMenuOpen(true);
              }}
              required
            />
            <ControlledMenu state={isExpiresAtMenuOpen ? "open" : "closed"} captureFocus={false}>
              <div className={"d-flex align-items-center justify-content-end px-2"}>
                <span
                  onClick={() => {
                    setIsExpiresAtMenuOpen(false);
                  }}
                >
                  <span>Fechar</span>
                  <AiOutlineClose />
                </span>
              </div>
              <Calendar
                onChange={handleExpiresAtChange}
                date={values.expires_at}
                className=""
                color="#9841e2"
                rangeColors={["#9841e2"]}
              />
            </ControlledMenu>
          </div>

          <div className={"form-group mt-3"}>
            <button className={"btn btn-primary"} disabled={isSubmitting}>
              {isSubmitting ? "Enviando..." : "Enviar"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ManualPaymentConfirmationForm;
