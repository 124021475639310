import * as React from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";
import { Sale } from "@util/interfaces";
import { getSalePeriodName, getSaleTypeName, toastError, toastMessage } from "@util/functions";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@redux/index";
import { fetchSales, setSalesQuery } from "@redux/AppData.store";
import SalesFilters from "./filters";
import SaleStatusPill from "@components/ui/SaleStatusPill";
import { confirmAlert } from "react-confirm-alert";
import Api from "@services/Api";

const columns: TableColumn<Sale>[] = [
  {
    name: "Marca",
    selector: (row: Sale) => row.store?.name ?? "Estab. excluído",
    sortable: true,
  },
  {
    name: "Plano",
    selector: (row: Sale) => `${row.plan.name} - ${getSalePeriodName(row.period)}`,
    sortable: true,
  },
  {
    name: "Vendedor",
    selector: (row: Sale) => row.seller.name,
    sortable: true,
  },
  {
    name: "Tipo de venda",
    selector: (row: Sale) => getSaleTypeName(row.type),
    sortable: true,
  },
  {
    name: "Expira em",
    selector: (row: Sale) => {
      return new Date(row.expires_at ?? Date.now()).getTime();
    },
    cell: (row: Sale) => (row.expires_at ? new Date(row.expires_at).toLocaleDateString("pt-BR") : "-"),
    sortable: true,
  },
  {
    name: "Situação",
    selector: (sale: Sale) => sale.id,
    cell: (row: Sale) => {
      const status = row.expired_at ? "expired" : row.expires_at ? "active" : "pending";
      return <SaleStatusPill status={status} />;
    },
  },
  {
    name: "Opções",
    selector: (sale: Sale) => sale.id,
    compact: true,
    width: "200px",
    cell: (row: Sale) => {
      const status = row.expired_at ? "expired" : row.expires_at ? "active" : "pending";
      return (
        <div className={"d-flex"}>
          <a
            href={`/vendas/${row.id}/pagamentos`}
            className={"btn btn-outline-primary rounded-pill btn-sm"}
            style={{ whiteSpace: "nowrap" }}
          >
            Pagamentos
          </a>
          <button
            data-tag="allowRowEvents"
            data-id="deleteButton"
            className={"btn btn-outline-danger rounded-pill btn-sm ms-1"}
            style={{ whiteSpace: "nowrap" }}
          >
            Excluir
          </button>
          {status === "pending" && (
            <button
              data-tag="allowRowEvents"
              data-id="cancelButton"
              className={"btn btn-outline-danger rounded-pill btn-sm ms-1"}
              style={{ whiteSpace: "nowrap" }}
            >
              Cancelar
            </button>
          )}
        </div>
      );
    },
  },
];

const SalesListScreen: React.FC = () => {
  const { filteredSales, loadingSales } = useSelector((state: RootState) => state.appSettings);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setSalesQuery({}));
    dispatch(fetchSales());
    // eslint-disable-next-line
  }, []);

  // Using any as event type because we can't access the attribute data-id with the correct event type
  const handleTableRowClick = (row: Sale, event: any) => {
    if (event.target.attributes["data-id"]?.value === "cancelButton") {
      handleStoreDelete(row.id);
    }
    if (event.target.attributes["data-id"]?.value === "deleteButton") {
      handleDeleteSaleWithPayments(row.id);
    }
  };

  const handleDeleteSaleWithPayments = async (saleId: number) => {
    confirmAlert({
      title: "Atenção",
      message:
        "Deseja excluir esta venda? Também serão excluídos todos os pagamentos que esta venda possuir. Esta ação não pode ser desfeita.",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/sale/${saleId}/sale_and_payments`);
              toastMessage("Venda excluída", "success");
              dispatch(fetchSales());
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handleStoreDelete = async (saleId: number) => {
    confirmAlert({
      title: "Atenção",
      message: "Deseja excluir esta venda? Esta ação não pode ser desfeita.",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/sale/${saleId}`);
              toastMessage("Venda excluída", "success");
              dispatch(fetchSales());
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  if (loadingSales)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Vendas</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Vendas</PageTitle>
        <Link to="/vendas/cadastro" className="btn btn-primary">
          + nova venda
        </Link>
      </TitleContainer>
      <ContentContainer>
        <div className={"p-2 text-muted"}>
          <h5>Filtros</h5>
        </div>
        <SalesFilters />
        <div className={"p-2 text-muted mt-2"}>
          <h5>Resultados </h5>
        </div>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loadingSales}
          columns={columns}
          data={filteredSales}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          responsive
          onRowClicked={handleTableRowClick}
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default SalesListScreen;
