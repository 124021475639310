import * as React from "react";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { CreditCardBrands, PaymentMethod, PaymentStatus } from "@util/interfaces";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import { Calendar } from "react-date-range";
import { useHistory, useParams } from "react-router";
import ReactTooltip from "react-tooltip";
import { SlideDown } from "@components/ui/SlideDown";
import { Modal } from "react-responsive-modal";

interface NewManualPaymentScreenProps {
  sale_id: string;
}

interface NewManualPaymentFormValues {
  expires_at?: Date;
  value?: number;
  status: PaymentStatus;
  emepag_order_id?: string;
  paid_at?: Date;
  payment_method?: PaymentMethod;
  credit_card_brand?: CreditCardBrands;
  installment_count: number;
  booklet_installment_count: number;
}

const NewManualPaymentScreen: React.FC = () => {
  const userRole = localStorage.getItem("user_role");
  const formRef = React.useRef<FormikProps<NewManualPaymentFormValues>>(null);
  const [isExpiresAtMenuOpen, setIsExpiresAtMenuOpen] = React.useState(false);
  const [isPaidAtMenuOpen, setIsPaidAtMenuOpen] = React.useState(false);
  const history = useHistory();
  const { sale_id } = useParams<NewManualPaymentScreenProps>();

  const handleSubmit = async (
    values: NewManualPaymentFormValues,
    actions: FormikHelpers<NewManualPaymentFormValues>
  ) => {
    try {
      actions.setSubmitting(true);
      if (values.status === PaymentStatus.PAID) {
        if (!values.expires_at) throw new Error("Por favor, informe a data de vencimento");
        if (!values.paid_at) throw new Error("Por favor, informe a data de pagamento.");
        if (!values.payment_method) throw new Error("Por favor, informe o meio de pagamento.");
      }
      const data = {
        ...values,
        sale_id: parseInt(sale_id),
        create_emepag_order: false,
      } as any;
      if (values.expires_at) data.expires_at = moment(values.expires_at).format("DD/MM/YYYY");
      if (values.paid_at) data.paid_at = moment(values.paid_at).format("DD/MM/YYYY");
      await Api.post(`/payment`, data);
      console.log(values, "valores");
      toastMessage("Pagamento criado", "success");
      actions.setSubmitting(false);
      history.replace(`/vendas/${sale_id}/pagamentos`);
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const handlePaidAtChange = (paidAt: Date) => {
    setIsPaidAtMenuOpen(false);
    formRef.current?.setFieldValue("paid_at", paidAt);
  };

  const handleExpiresAtChange = (expiresAt: Date) => {
    setIsExpiresAtMenuOpen(false);
    formRef.current?.setFieldValue("expires_at", expiresAt);
  };

  const initialValues: NewManualPaymentFormValues = {
    expires_at: undefined,
    value: undefined,
    status: PaymentStatus.CREATED,
    emepag_order_id: undefined,
    paid_at: undefined,
    payment_method: undefined,
    credit_card_brand: undefined,
    installment_count: 1,
    booklet_installment_count: 6,
  };

  return (
    <SlideDown>
      <Formik innerRef={formRef} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className={"row mt-3"} autoComplete="off">
            <div className={"col-lg-auto form-group"}>
              <label>Valor do pagamento</label>
              <CurrencyInput
                prefix="R$"
                allowNegativeValue={false}
                decimalSeparator={","}
                groupSeparator={"."}
                className={"form-control"}
                placeholder="Deixe em branco para calcular automaticamente"
                decimalsLimit={2}
                onValueChange={(value, name) => {
                  setFieldValue("value", parseFloat(value?.replace(".", "").replace(",", ".") ?? ""));
                }}
              />
            </div>
            <div className={"col-lg-auto form-group"}>
              <label>Meio de pagamento</label>
              <Field name="payment_method" as="select" className="form-control">
                <option disabled value="" selected>
                  Selecione uma opção
                </option>
                <option value="credit_card">Cartão de crédito</option>
                <option value="boleto">Boleto</option>
                <option value="pix">Pix</option>
                <option value="booklet">Carnê</option>
              </Field>
            </div>

            {values.payment_method === "booklet" && (
              <>
                <div className={"col-lg-auto form-group"}>
                  <label>Parcelas</label>
                  <Field name="booklet_installment_count" as="select" className="form-control">
                    <option disabled value="" selected>
                      Selecione uma opção
                    </option>
                    <option value="1">1x</option>
                    <option value="2">2x</option>
                    <option value="3">3x</option>
                    <option value="4">4x</option>
                    <option value="5">5x</option>
                    <option value="6">6x</option>
                    <option value="7">7x</option>
                    <option value="8">8x</option>
                    <option value="9">9x</option>
                    <option value="10">10x</option>
                    <option value="11">11x</option>
                    <option value="12">12x</option>
                  </Field>
                </div>
              </>
            )}

            {values.payment_method === "credit_card" && (
              <>
                <div className={"col-lg-auto form-group"}>
                  <label>Bandeira</label>
                  <Field name="credit_card_brand" as="select" className="form-control">
                    <option disabled value="" selected>
                      Selecione uma opção
                    </option>
                    <option value="visa">Visa</option>
                    <option value="mastercard">Mastercard</option>
                    <option value="amex">American Express</option>
                    <option value="diners">Diners</option>
                    <option value="elo">Elo</option>
                    <option value="hipercard">Hipercard</option>
                    <option value="hiper_itau">Hiper (itaú)</option>
                    <option value="jcb">JCB</option>
                    <option value="american_express">American Express</option>
                    <option value="discover">Discover</option>
                    <option value="aura">Aura</option>
                  </Field>
                </div>
                <div className={"col-lg-auto form-group"}>
                  <label>Parcelas</label>
                  <Field name="installment_count" as="select" className="form-control">
                    <option disabled value="" selected>
                      Selecione uma opção
                    </option>
                    <option value="1">1x</option>
                    <option value="2">2x</option>
                    <option value="3">3x</option>
                    <option value="4">4x</option>
                    <option value="5">5x</option>
                    <option value="6">6x</option>
                    <option value="7">7x</option>
                    <option value="8">8x</option>
                    <option value="9">9x</option>
                    <option value="10">10x</option>
                    <option value="11">11x</option>
                    <option value="12">12x</option>
                  </Field>
                </div>
              </>
            )}
            <div className={"col-lg-auto form-group"}>
              <label>Data de pagamento</label>
              <Field
                name="paid_at"
                placeholder={"Selecionar data"}
                className="form-control"
                value={values.paid_at ? moment(values.paid_at).format("DD/MM/YYYY") : undefined}
                onChange={(e: any) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  setIsPaidAtMenuOpen(true);
                }}
              />
            </div>
            <div className={"col-lg-auto form-group"}>
              <label data-tip="Data em que o plano irá expirar">Data de expiração</label>
              <Field
                name="expires_at"
                placeholder={"Selecionar data"}
                className="form-control"
                value={values.expires_at ? moment(values.expires_at).format("DD/MM/YYYY") : undefined}
                onChange={(e: any) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  setIsExpiresAtMenuOpen(true);
                }}
              />
            </div>
            <div className={"col-lg-auto form-group"}>
              <label>Situação</label>
              <Field name="status" as="select" className="form-control">
                <option disabled value="" selected>
                  Selecione uma opção
                </option>
                <option value="created">Aguardando pagamento</option>
                {(userRole === "admin") && <option value="paid">Pago</option>}
              </Field>
            </div>

            <div className={"form-group mt-3"}>
              <button className={"btn btn-primary"} disabled={isSubmitting}>
                {isSubmitting ? "Enviando..." : "Enviar"}
              </button>
            </div>
            <Modal
              open={isExpiresAtMenuOpen}
              onClose={() => {
                setIsExpiresAtMenuOpen(false);
              }}
              center={true}
            >
              <Calendar
                onChange={handleExpiresAtChange}
                date={values.expires_at}
                color="#9841e2"
                rangeColors={["#9841e2"]}
              />
            </Modal>
            <Modal
              open={isPaidAtMenuOpen}
              center={true}
              onClose={() => {
                setIsPaidAtMenuOpen(false);
              }}
            >
              <Calendar onChange={handlePaidAtChange} date={values.paid_at} color="#9841e2" rangeColors={["#9841e2"]} />
            </Modal>
          </Form>
        )}
      </Formik>

      <ReactTooltip className="tooltip" />
    </SlideDown>
  );
};

export default NewManualPaymentScreen;
