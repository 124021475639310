import * as React from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";
import { Store } from "@util/interfaces";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@redux/index";
import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Filters } from "./Filters";
import { normalizeString } from "@util/functions";
import { fetchStores, setStores, setStoresQuery } from "@redux/AppData.store";

const columns: TableColumn<Store>[] = [
  {
    name: "Ativo",
    selector: (row: Store) => row.is_active,
    format: (row: Store) => (row.is_active ? <FcCheckmark /> : <IoMdClose />),
    grow: 0,
  },
  {
    name: "Nome",
    selector: (row: Store) => normalizeString(row.name),
    sortable: true,
  },
  {
    name: "Plano",
    selector: (row: Store) => row.plan?.priority,
    format: (row: Store) => row.plan?.name,
    sortable: true,
  },
];

const StoresListScreen: React.FC = () => {
  const { stores, loadingStores, storesCount, storesQuery } = useSelector((state: RootState) => state.appSettings);

  const history = useHistory();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchStores());
    return () => {
      dispatch(setStores([]));
    };
    // eslint-disable-next-line
  }, [storesQuery]);

  const handlePageChange = (page: number) => {
    dispatch(setStoresQuery({ ...storesQuery, page: page }));
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    dispatch(setStoresQuery({ ...storesQuery, take: newPerPage }));
  };

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Lojas</PageTitle>
        <Link to="/estabelecimentos/cadastro" className="btn btn-primary">
          cadastrar estabelecimento
        </Link>
      </TitleContainer>
      <ContentContainer>
        <div className={"p-2 text-muted"}>
          <h5>Filtros</h5>
        </div>
        <Filters />
        <div className={"p-2 mt-2 text-muted"}>
          <h5>Resultados</h5>
        </div>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loadingStores}
          columns={columns}
          data={stores}
          pagination
          paginationServer
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          highlightOnHover
          pointerOnHover
          paginationTotalRows={storesCount}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={(row: Store) => {
            history.push(`/estabelecimentos/${row.id}/editar`);
          }}
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default StoresListScreen;
