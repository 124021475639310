import { InputLabel } from "@components/ui/Typography";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { Hotel, HotelFormValues } from "@util/interfaces";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useHistory, useParams } from "react-router";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";

type EditHotelProps = {
  hotel_id?: string;
};

const EditHotelScreen: React.FC = () => {
  const history = useHistory();
  const { hotel_id } = useParams<EditHotelProps>();
  const [loading, setLoading] = React.useState(true);
  const [hotel, setHotel] = React.useState<Hotel>();

  React.useEffect(() => {
    fetchHotelDetails();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (hotel) setLoading(false);
  }, [hotel]);

  const fetchHotelDetails = async () => {
    try {
      const { data } = await Api.get(`/hotel/id/${hotel_id}`);
      setHotel(data.hotel);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  const handleSubmit = async (values: HotelFormValues, actions: FormikHelpers<HotelFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.patch(`/hotel/${hotel_id}`, values);
      toastMessage("Hotel atualizado", "success");
      actions.setSubmitting(true);
      history.push("/hoteis");
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const handleStoreDelete = async () => {
    confirmAlert({
      title: "Atenção",
      message: "Deseja excluir este hotel?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/hotel/${hotel_id}`);
              toastMessage("Hotel excluído", "success");
              history.push("/hoteis");
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const initialValues: HotelFormValues = {
    name: hotel?.name,
    phone: hotel?.phone,
    phone_2: hotel?.phone_2,
    email: hotel?.email,
    address: hotel?.address,
  };

  if (loading || !hotel)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Editar hotel</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Editar hotel</PageTitle>
        <button className="btn btn-outline-danger btn-sm" onClick={handleStoreDelete}>
          excluir hotel
        </button>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="row m-0 p-0">
                <div className="mb-4 col-md-3">
                  <InputLabel>Nome</InputLabel>
                  <Field name="name" className="form-control" placeholder="Digite o nome" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Email</InputLabel>
                  <Field name="email" className="form-control" placeholder="Digite o email" />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (1)</InputLabel>
                  <Field name="phone" className="form-control" placeholder="Digite o telefone (1)" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (2)</InputLabel>
                  <Field name="phone_2" className="form-control" placeholder="Digite o telefone (2)" />
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Endereço</InputLabel>
                  <Field name="address" className="form-control" placeholder="Digite o endereço" />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "enviando" : "enviar"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default EditHotelScreen;
