import FakeLink from "@components/ui/FakeLink";
import FilterInput from "@components/ui/FilterInput";
import { FilterSelect } from "@components/ui/FilterSelect";
import React from "react";
import { useLocationCategories } from "../hooks/useLocationCategories";
import { useAppDispatch } from "@redux/index";
import { setLocationsQuery } from "@redux/AppData.store";

export const LocationFilters = () => {
  const { locationCategories } = useLocationCategories();
  const [locationNameFilterText, setLocationNameFilterText] = React.useState("");
  const locationCategoriesFilterOptions = locationCategories.map((category) => ({
    value: `${category.id}`,
    label: category.name,
  }));
  const dispatch = useAppDispatch();
  const [selectedLocationCategory, setSelectedLocationCategory] = React.useState("");
  const onSubmit = () => {
    dispatch(
      setLocationsQuery({
        name: locationNameFilterText,
        location_category_id: selectedLocationCategory,
      })
    );
  };
  const handleClearFilter = () => {
    setLocationNameFilterText("");
    setSelectedLocationCategory("");
  };
  return (
    <form
      className="row py-1 pb-4 g-2 justify-content-end align-items-center"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className={"col-auto"}>
        <FilterInput
          label={"Nome"}
          placeholder={"Todas"}
          value={locationNameFilterText}
          onChange={(e) => {
            setLocationNameFilterText(e.currentTarget.value);
          }}
        />
      </div>
      <div className={"col-auto"}>
        <FilterSelect
          label={"Tipo"}
          options={locationCategoriesFilterOptions}
          value={locationCategoriesFilterOptions.find((option) => selectedLocationCategory === option.value) || null}
          onChange={(option) => setSelectedLocationCategory(option?.value ?? "")}
        />
      </div>
      <div className="col-auto py-auto d-flex align-items-center ">
        <button className={"btn btn-primary"} style={{ padding: 5, height: "auto" }}>
          Buscar
        </button>
      </div>
      <div className="col-auto py-auto d-flex align-items-center">
        <FakeLink onClick={handleClearFilter}>limpar filtros</FakeLink>
      </div>
    </form>
  );
};
