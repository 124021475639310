import { InputLabel } from "@components/ui/Typography";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { PlanFormValues } from "@util/interfaces";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useHistory } from "react-router";
import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";

const AddPlanScreen: React.FC = () => {
  const history = useHistory();

  const handleSubmit = async (values: PlanFormValues, actions: FormikHelpers<PlanFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.post(`/plan`, values);
      toastMessage("Plano criado", "success");
      actions.setSubmitting(true);
      history.push("/planos");
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const initialValues: PlanFormValues = {
    name: "",
    allow_cover_image: false,
    allow_logo_image: false,
    allow_product_images: false,
    product_images_limit: 0,
  };

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Cadastrar plano</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values }) => (
            <Form>
              <div className="row m-0 p-0">
                <div className="mb-4 col-md-3">
                  <InputLabel>Nome do plano</InputLabel>
                  <Field name="name" className="form-control" placeholder="Digite o nome" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Preço mensal</InputLabel>
                  <Field name="price_monthly" className="form-control" placeholder="Digite o preço mensal" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Preço semestral</InputLabel>
                  <Field
                    name="price_semesterly"
                    className="form-control"
                    placeholder="Digite o preço semestral"
                    required
                  />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Preço anual</InputLabel>
                  <Field name="price_yearly" className="form-control" placeholder="Digite o preço anual" />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Permitir logo da empresa?</InputLabel>

                  <div className="form-check">
                    <Field type="checkbox" className="form-check-input" name="allow_logo_image" id="allow_logo_image" />
                    <label className="form-check-label" htmlFor="allow_logo_image">
                      {`${values.allow_logo_image ? "Sim" : "Não"}`}{" "}
                    </label>
                  </div>
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Permitir imagem de capa?</InputLabel>

                  <div className="form-check">
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      name="allow_cover_image"
                      id="allow_cover_image"
                    />
                    <label className="form-check-label" htmlFor="allow_cover_image">
                      {`${values.allow_cover_image ? "Sim" : "Não"}`}{" "}
                    </label>
                  </div>
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Permitir imagens de produtos?</InputLabel>

                  <div className="form-check">
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      name="allow_product_images"
                      id="allow_product_images"
                    />
                    <label className="form-check-label" htmlFor="allow_product_images">
                      {`${values.allow_product_images ? "Sim" : "Não"}`}{" "}
                    </label>
                  </div>
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Qual o limite de imagens de produtos?</InputLabel>
                  <Field
                    name="product-images-limit"
                    className="form-control"
                    placeholder="Digite o limite de imagens"
                  />
                </div>

                <div className="col-12">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "enviando" : "enviar"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default AddPlanScreen;
