import * as React from "react";
import styled from "styled-components";
import { components, ControlProps, Props } from "react-select";
import Select from "react-select";
import { reactSelectTheme } from "../Select/configs";

const Container = styled.div`
  background-color: #f3f3f3;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const FilterItemContainer: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => {
  return <Container className={"p-1"}>{props.children}</Container>;
};

interface FilterSelectProps extends Props {
  label?: string;
}

export const FilterSelect: React.FC<FilterSelectProps> = (props) => {
  return (
    <Select
      classNamePrefix={"filter-select"}
      components={{
        Control: (controlProps) => <Control {...controlProps} label={props.label} />,
      }}
      styles={{
        control: (base) => ({ ...base, backgroundColor: "#f3f3f3", border: "none" }),
      }}
      theme={reactSelectTheme}
      isClearable={true}
      menuPortalTarget={document.body}
      placeholder="Todos"
      {...props}
    />
  );
};

interface FilterSelectControlProps extends ControlProps<any, false> {
  label?: string;
}

export const Control = ({ children, ...props }: FilterSelectControlProps) => {
  return (
    <components.Control {...props}>
      <span className={"ms-2"}>{props.label ? `${props.label}:` : ""} </span>
      {children}
    </components.Control>
  );
};
