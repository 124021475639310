import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { LocationCategory } from "@util/interfaces";
import { useEffect, useState } from "react";

export const useLocationCategories = () => {
  const [locationCategories, setLocationCategories] = useState<LocationCategory[]>([]);
  const [loading, setLoading] = useState(false);
  const [creatingLocationCategory, setCreatingLocationCategory] = useState(false);

  const fetchLocationCategories = async () => {
    setLoading(true);
    try {
      const { data } = await Api.get("/location_category");
      setLocationCategories(data.locationCategories);
    } catch (error) {
      console.log(error, "error fetching location categories");
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  const createLocationCategory = async (locationCategory: Partial<LocationCategory>) => {
    setCreatingLocationCategory(true);
    try {
      const { data } = await Api.post("/location_category", locationCategory);
      toastMessage("Categoria criada.", "success");
      fetchLocationCategories();
    } catch (error) {
      console.log(error, "error creating location category");
      toastError(error);
    } finally {
      setCreatingLocationCategory(false);
    }
  };

  useEffect(() => {
    fetchLocationCategories();
  }, []);

  return { locationCategories, loading, fetchLocationCategories, createLocationCategory, creatingLocationCategory };
};
