import { InputLabel } from "@components/ui/Typography";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { Excursion, ExcursionFormValues } from "@util/interfaces";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useHistory, useParams } from "react-router";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import Select from "@components/ui/Select";

type EditExcursionProps = {
  excursion_id?: string;
};

const EditExcursionScreen: React.FC = () => {
  const history = useHistory();
  const { excursion_id } = useParams<EditExcursionProps>();
  const [loading, setLoading] = React.useState(true);
  const [excursion, setExcursion] = React.useState<Excursion>();

  React.useEffect(() => {
    fetchExcursionDetails();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (excursion) setLoading(false);
  }, [excursion]);

  const fetchExcursionDetails = async () => {
    try {
      const { data } = await Api.get(`/excursion/id/${excursion_id}`);
      setExcursion(data.excursion);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  const handleSubmit = async (values: ExcursionFormValues, actions: FormikHelpers<ExcursionFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.patch(`/excursion/${excursion_id}`, values);
      toastMessage("Excursão atualizada", "success");
      actions.setSubmitting(true);
      history.push("/excursoes");
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const handleStoreDelete = async () => {
    confirmAlert({
      title: "Atenção",
      message: "Deseja excluir esta excursão?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/excursion/${excursion_id}`);
              toastMessage("Excursão excluída", "success");
              history.push("/hoteis");
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  const handleTextAreaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = "inherit";
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
  };

  const selectOptions = [
    { value: true, label: "Sim" },
    { value: false, label: "Não" },
  ];

  const initialValues: ExcursionFormValues = {
    name: excursion?.name || "",
    state: excursion?.state || "",
    city: excursion?.city || "",
    address: excursion?.address || "",
    phone: excursion?.phone || "",
    is_phone_whatsapp: excursion?.is_phone_whatsapp || false,
    phone_whatsapp_url: excursion?.phone_whatsapp_url || "",
    phone_2: excursion?.phone_2 || "",
    is_phone_2_whatsapp: excursion?.is_phone_2_whatsapp || false,
    phone_2_whatsapp_url: excursion?.phone_2_whatsapp_url || "",
    route: excursion?.route || "",
  };

  if (loading || !excursion)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Editar excursão</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Editar excursão</PageTitle>
        <button className="btn btn-outline-danger btn-sm" onClick={handleStoreDelete}>
          excluir excursão
        </button>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="row m-0 p-0">
                <div className="mb-4 col-md-3">
                  <InputLabel>Nome</InputLabel>
                  <Field name="name" className="form-control" placeholder="Digite o nome" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Estado</InputLabel>
                  <Field name="state" className="form-control" placeholder="Digite o estado" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Cidade</InputLabel>
                  <Field name="city" className="form-control" placeholder="Digite a cidade" required />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Endereço (no moda center)</InputLabel>
                  <Field name="address" className="form-control" placeholder="Digite informações do endereço" />
                </div>
                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (1)</InputLabel>
                  <Field name="phone" className="form-control" placeholder="Digite o telefone (1)" required />
                </div>
                <div className="mb-4 col-md-3">
                  {values.is_phone_whatsapp}
                  <InputLabel>Telefone (1) é whatsapp?</InputLabel>
                  <Select
                    placeholder={"Selecione"}
                    noOptionsMessage={() => "Sem resultados"}
                    value={selectOptions.find((option) => option.value === values.is_phone_whatsapp)}
                    options={selectOptions}
                    onChange={(option: any) => {
                      setFieldValue("is_phone_whatsapp", option.value);
                    }}
                  />
                </div>
                {values.is_phone_whatsapp && (
                  <div className="mb-4 col-md-3">
                    <InputLabel>Link personalizado whatsapp (1)</InputLabel>
                    <Field name="phone_whatsapp_url" className="form-control" placeholder="Digite a url do whatsapp" />
                  </div>
                )}

                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (2)</InputLabel>
                  <Field name="phone_2" className="form-control" placeholder="Digite o telefone (2)" />
                </div>

                <div className="mb-4 col-md-3">
                  <InputLabel>Telefone (2) whatsapp?</InputLabel>
                  <Select
                    placeholder={"Selecione"}
                    noOptionsMessage={() => "Sem resultados"}
                    value={selectOptions.find((option) => option.value === values.is_phone_2_whatsapp)}
                    options={selectOptions}
                    onChange={(option: any) => {
                      setFieldValue("is_phone_2_whatsapp", option.value);
                    }}
                  />
                </div>

                {values.is_phone_2_whatsapp && (
                  <div className="mb-4 col-md-3">
                    <InputLabel>Link personalizado whatsapp (2)</InputLabel>
                    <Field
                      name="phone_2_whatsapp_url"
                      className="form-control"
                      placeholder="Digite a url do whatsapp"
                    />
                  </div>
                )}

                <div className="mb-4 col-md-6">
                  <InputLabel>Rotas</InputLabel>
                  <Field
                    onKeyPress={handleTextAreaKeyDown}
                    as="textarea"
                    name="route"
                    className="form-control"
                    placeholder="Forneça as rotas"
                  />
                </div>

                <div className="col-12">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "enviando" : "enviar"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default EditExcursionScreen;
