import { InputLabel } from "@components/ui/Typography";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { SellerFormValues } from "@util/interfaces";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useHistory } from "react-router";
import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";

const AddSellerScreen: React.FC = () => {
  const history = useHistory();

  const handleSubmit = async (values: SellerFormValues, actions: FormikHelpers<SellerFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.post(`/user/register_seller`, values);
      toastMessage("Usuário criado", "success");
      actions.setSubmitting(true);
      history.push("/vendedores");
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const initialValues: SellerFormValues = {
    name: "",
    password: "",
    email: "",
  };

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Cadastrar vendedor</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="row m-0 p-0">
                <div className="mb-4 col-md-4">
                  <InputLabel>Nome</InputLabel>
                  <Field name="name" className="form-control" placeholder="Digite o nome do vendedor" required />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Email</InputLabel>
                  <Field
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Digite o email do vendedor"
                    required
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Senha</InputLabel>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Digite o nome do vendedor"
                    required
                  />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "enviando" : "enviar"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default AddSellerScreen;
