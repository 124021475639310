import styled from "styled-components";

interface NewPaymentScreenSelectionButtonProps {
  selected: boolean;
}

export const NewPaymentScreenSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: auto;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const NewPaymentScreenSelectionButton = styled.button<NewPaymentScreenSelectionButtonProps>`
  width: auto;
  height: auto;
  padding: 20px 50px;
  color: #9841e2;
  border: 1px solid #9841e2;
  background-color: white;
  ${(props) => props.selected && "background-color: #9841e2;border: none; ; color: white;"};
`;

export const ContentContainer = styled.div`
  height: calc(100vh - 85px);
  max-height: calc(100vh - 85px);
`;
