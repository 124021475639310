import * as React from "react";
import {
  ContentContainer,
  LoadingIndicator,
  PageTitle,
  RootContainer,
  TitleContainer,
} from "@components/ui/PageBaseComponents";
import DataTable, { TableColumn } from "react-data-table-component";
import { toastError, toastMessage } from "@util/functions";
import Api from "@services/Api";
import { Location } from "@util/interfaces";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { LocationFilters } from "./filter";
import { RootState } from "@redux/index";
import { useSelector } from "react-redux";

const PlansListScreen: React.FC = () => {
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [loading, setLoading] = React.useState(true);
  const { locationsQuery } = useSelector((state: RootState) => state.appSettings);

  const handleDelete = async (id: number) => {
    try {
      await Api.delete(`/location/${id}`);
      toastMessage("Ponto excluído.");
      fetchLocations();
    } catch (error) {
      toastError(error);
    }
  };
  const askToDelete = (id: number) => {
    confirmAlert({
      title: "Atenção!",
      message: "Esta loja ficará disponível no app para todos os usuários. Deseja continuar?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            handleDelete(id);
          },
        },
        {
          label: "Não",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };
  const columns: TableColumn<Location>[] = [
    {
      name: "Id",
      selector: (row: Location) => row.id,
    },
    {
      name: "Nome",
      sortable: true,
      selector: (row: Location) => row.name,
    },
    {
      name: "Categoria",
      sortable: true,
      selector: (row: Location) => row.locationCategory?.name ?? "N/D",
    },
    {
      name: "Ações",
      cell: (row: Location) => (
        <div
          className="d-flex"
          onClick={() => {
            askToDelete(row.id);
          }}
        >
          <button className={"btn btn-outline-primary my-2"}>Excluir</button>
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    fetchLocations();
    console.log("called");
    // eslint-disable-next-line
  }, [locationsQuery]);

  const fetchLocations = async () => {
    try {
      const { data } = await Api.get(`/location`, {
        params: { ...locationsQuery },
      });
      setLocations(data.locations);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  if (loading)
    return (
      <RootContainer>
        <TitleContainer>
          <PageTitle>Localizações</PageTitle>
        </TitleContainer>
        <ContentContainer>
          <LoadingIndicator />
        </ContentContainer>
      </RootContainer>
    );

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Pontos avulsos</PageTitle>
        <Link to="/add-location" className="btn btn-primary">
          cadastrar ponto
        </Link>
      </TitleContainer>
      <ContentContainer>
        <div className="p-2 flex-row align-items-center justify-content-between border-bottom d-flex text-muted">
          <h5>Filtros</h5>
          <LocationFilters />
        </div>
        <DataTable
          noDataComponent="Nenhum resultado encontrado"
          progressComponent={<LoadingIndicator />}
          progressPending={loading}
          columns={columns}
          data={locations}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="80vh"
        />
      </ContentContainer>
    </RootContainer>
  );
};

export default PlansListScreen;
